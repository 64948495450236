import { PadBox } from '@bedrock-layout/padbox';
import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import { Control, UseFormSetValue, useFieldArray } from 'react-hook-form';
import { FiPlusCircle } from 'react-icons/fi';
import {
  CheckboxField,
  Image,
  TextButton,
  TextField,
  TooltipReact,
  Typography,
} from 'ui';

import { siteConstantsAtom } from '../../../../../atom';
import { CaseSensitiveCheckbox } from '../../../../../components/rules/forms/CustomAttributeSheet/CaseSensitiveCheckbox';
import { FormHeader } from '../../../../../components/rules/forms/CustomAttributeSheet/CustomAttributeSheet';
import {
  FormPanel,
  Table,
  TableData,
  TableHeader,
  TableRow,
} from '../../../../../components/rules/forms/CustomAttributeSheet/CustomAttributeSheet.styled';
import { DataTypeSelection } from '../../../../../components/rules/forms/CustomAttributeSheet/DataTypeSelection';
import { SampleValueField } from '../../../../../components/rules/forms/CustomAttributeSheet/SampleValueField';
import { getTooltipText } from '../../../../../utils/common';
import { envMap } from '../../../../../utils/constant';
import { isWorkflowReadOnlyAtom } from '../../../atoms/atoms';

type ApiInputFormProps = {
  control?: Control<any>;
  triggerId: string;
  setValue: UseFormSetValue<any>;
};

export function ApiInputForm({
  control,
  triggerId,
  setValue,
}: ApiInputFormProps) {
  const [siteConstants] = useAtom(siteConstantsAtom);
  const [isWorkflowReadOnly] = useAtom(isWorkflowReadOnlyAtom);

  const customAttributeDefaultValues = {
    name: '',
    dataType: null,
    isNullable: false,
    isOptional: false,
    isCaseSensitive: false,
    selectedType: null,
  };

  const { fields, append, remove } = useFieldArray({
    name: 'attributes',
    control,
  });

  const handleAddConfig = () => {
    append({ ...customAttributeDefaultValues });
  };

  const formHeaders: FormHeader[] = [
    {
      label: 'Name',
    },
    {
      label: 'Type',
    },
    {
      label: 'Test Value',
    },
    {
      label: 'Can be Null',
      tooltipContent: (
        <Typography>
          {getTooltipText(siteConstants, 'rules', 'ciCanBeNull')}
        </Typography>
      ),
    },
    {
      label: 'Case Sensitive',
      tooltipContent: (
        <Typography>
          {getTooltipText(siteConstants, 'rules', 'ciIsCaseSensitive')}
        </Typography>
      ),
    },
    {
      label: 'Is Optional',
      tooltipContent: (
        <Typography>
          {getTooltipText(siteConstants, 'rules', 'ciIsMandatory')}
        </Typography>
      ),
    },
  ];

  return (
    <FormPanel padding={[0, '2.4rem']}>
      <Stack gutter="1rem">
        <Table>
          <TableRow>
            {formHeaders.map((header) => (
              <TableHeader key={header.label}>
                <PadBox padding="1.2rem">
                  <Inline align="center" gutter={8}>
                    <Typography>{header.label}</Typography>

                    {!_isNil(header.tooltipContent) && (
                      <TooltipReact id={header.label} placement="top-start">
                        {header.tooltipContent}
                      </TooltipReact>
                    )}
                  </Inline>
                </PadBox>
              </TableHeader>
            ))}
          </TableRow>

          {fields.map((field, index) => {
            return (
              <TableRow key={field.id}>
                <TableData>
                  <PadBox padding={'1.2rem'}>
                    <TextField
                      control={control}
                      rules={{ required: 'Required' }}
                      name={`attributes.${index}.name`}
                      placeholder="Property Name"
                      showErrorIcon={false}
                      disabled={isWorkflowReadOnly}
                    />
                  </PadBox>
                </TableData>

                <TableData>
                  <PadBox padding={'1.2rem'}>
                    <DataTypeSelection
                      index={index}
                      setValue={setValue}
                      control={control}
                      name={`attributes.${index}.selectedType`}
                      isDisabled={isWorkflowReadOnly}
                      from="workflow"
                      hideRestApi
                    />
                  </PadBox>
                </TableData>

                <TableData>
                  <PadBox padding={'1.2rem'}>
                    <SampleValueField
                      index={index}
                      setValue={setValue}
                      control={control}
                      name={`attributes.${index}.executedValue`}
                      isDisabled={isWorkflowReadOnly}
                      from="workflow"
                    />
                  </PadBox>
                </TableData>

                <TableData>
                  <PadBox padding="2.3rem">
                    <CheckboxField
                      control={control}
                      name={`attributes.${index}.isNullable`}
                      useId={`${index}_isNullable`}
                      disabled={isWorkflowReadOnly}
                    />
                  </PadBox>
                </TableData>

                <TableData>
                  <PadBox padding="2.3rem">
                    <CaseSensitiveCheckbox
                      control={control}
                      name={`attributes.${index}.isCaseSensitive`}
                      field={`attributes.${index}.dataType`}
                      setValue={setValue}
                      index={index}
                      disabled={isWorkflowReadOnly}
                    />
                  </PadBox>
                </TableData>

                <TableData>
                  <PadBox padding="2.3rem">
                    <CheckboxField
                      control={control}
                      name={`attributes.${index}.isOptional`}
                      useId={`${index}_isOptional`}
                      disabled={isWorkflowReadOnly}
                    />
                  </PadBox>
                </TableData>

                <TableData>
                  <PadBox padding="2.3rem">
                    <TextButton
                      disabled={isWorkflowReadOnly}
                      onClick={() => remove(index)}
                    >
                      Delete
                    </TextButton>
                  </PadBox>
                </TableData>
              </TableRow>
            );
          })}
        </Table>

        <Inline align="center">
          {isWorkflowReadOnly ? (
            <FiPlusCircle color="var(--color-darkGray)" />
          ) : (
            <Image
              src={`${envMap.VITE_ASSETS_URL}website/icons/plus.svg`}
              alt="plus"
              size="small"
            />
          )}

          <TextButton disabled={isWorkflowReadOnly} onClick={handleAddConfig}>
            Add Field
          </TextButton>
        </Inline>
      </Stack>
    </FormPanel>
  );
}
