import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _isUndefined from 'lodash/isUndefined';
import _reduce from 'lodash/reduce';
import { useMemo, useRef, useState } from 'react';
import { PopoverMethods, PopoverPanel, RulePopover } from 'ui';

import { isArrayNotPresent } from '../../../../../utils/common';
import { EXTRACT_TOKEN_REGEX } from '../../../../../utils/regex';
import {
  getRhsNodeTitleV2,
  getTypesToAllowForConditionNodes,
  removeCustomFunction,
} from '../../../utils/common';
import { dataSetParamsAtom } from '../../CreateRuleSheet/CreateRuleSheet';
import { ResultHeader } from '../../DecisionTable/components/ResultHeader';
import { sendEventToGTMType } from '../RuleBlock/RuleBlock';
import { simpleNodeErrors, simpleRuleNodesAtom } from '../index';
import type { ErrorByNodeId } from '../models';
import { RhsLauncher } from './RhsLauncher';
import { RhsInputContainer } from './RuleParamPopover.styled';

type RhsParamsPopoverProps = {
  ruleId: string;
  nodeId: string;
  handleSendEventToGTM?: (obj: sendEventToGTMType) => void;
};

export const RhsParamPopover = ({
  ruleId,
  nodeId,
  handleSendEventToGTM,
}: RhsParamsPopoverProps) => {
  const [rules, setRules] = useAtom(simpleRuleNodesAtom);
  const [panelVisible, setPanelVisible] = useState(false);
  const [dataset] = useAtom(dataSetParamsAtom);
  const [errorByRuleId, setErrorByRuleId] = useAtom(simpleNodeErrors);

  const dataType =
    !_isUndefined(rules[ruleId]) && !_isUndefined(rules[ruleId].dataType)
      ? rules[ruleId].dataType
      : '';

  const selectedOperator =
    !_isUndefined(rules[ruleId]) && !_isUndefined(rules[ruleId].operator)
      ? rules[ruleId].operator
      : '';

  const title = getRhsNodeTitleV2(rules, nodeId);
  const ref = useRef<PopoverMethods>(null);

  const typesToAllow = useMemo(
    () => getTypesToAllowForConditionNodes(dataType, selectedOperator),
    [dataType, selectedOperator]
  );

  const onChangeSpecial = (val: any) => {
    let newVal = val;

    try {
      const tokens = (typeof val === 'string' ? val : '').match(
        EXTRACT_TOKEN_REGEX
      );

      if (_isNil(tokens) || _isEmpty(tokens)) {
        newVal = JSON.parse(val);
      }
    } catch {}

    setRules((prev) => ({
      ...prev,
      [nodeId]: {
        ...prev[nodeId],
        nodeType: 'constant',
        value: newVal,
        dataType: 'list',
        sourceType: '',
        attribute: '',
      },
    }));
  };

  return (
    <PopoverPanel
      trigger="click"
      placement="bottom-start"
      launcher={
        <span>
          <RhsLauncher
            panelVisible={panelVisible}
            text={title ?? ''}
            nodeId={nodeId}
            parentId={ruleId}
            dataType={dataType ?? ''}
            handleSendEventToGTM={handleSendEventToGTM}
            selectedOperator={selectedOperator ?? ''}
            isOpen={ref.current?.getTippyState()}
            handleHidePanel={ref.current?.hide}
          />
        </span>
      }
      ref={ref}
      padding="8px"
    >
      <RhsInputContainer
        onMouseEnter={() => setPanelVisible(true)}
        onMouseLeave={() => setPanelVisible(false)}
      >
        <RulePopover
          dataset={removeCustomFunction(dataset)}
          allowList={true}
          version="v2"
          typesToAllow={typesToAllow}
          onClick={({ value: id, key, dataType: selectedDataType }) => {
            if (typeof handleSendEventToGTM === 'function') {
              handleSendEventToGTM({
                action: 'selection',
                element: 'rhs_value',
                actionName: dataType ?? '',
              });
            }

            if (key === 'custom') {
              // TODO: do something here
            } else {
              if (!_isNil(errorByRuleId[nodeId])) {
                setErrorByRuleId((prev) =>
                  _reduce(
                    prev,
                    (result: ErrorByNodeId, value, key) => {
                      if (key === nodeId) {
                        return result;
                      }

                      return {
                        ...result,
                        [key]: prev[key],
                      };
                    },
                    {}
                  )
                );
              }

              setRules((prev) => ({
                ...prev,
                [nodeId]: {
                  nodeType: 'params',
                  sourceType: key,
                  attribute: id,
                  parent: ruleId,
                  siblingIndex: rules[ruleId].siblingIndex,
                  dataType: selectedDataType,
                },
              }));
            }

            ref.current?.hide();
          }}
          header={
            typesToAllow.includes('list') ? (
              <ResultHeader
                dataSet={removeCustomFunction(dataset)}
                resIndex={0}
                onChangeSpecial={onChangeSpecial}
                dataType={'list'}
                overrideValue={rules[nodeId]?.value}
                version="v2"
                isAdd={
                  // eslint-disable-next-line
                  !!rules[nodeId].attribute ||
                  isArrayNotPresent(rules[nodeId]?.value)
                }
              />
            ) : undefined
          }
        />
      </RhsInputContainer>
    </PopoverPanel>
  );
};
