import { Inline } from '@bedrock-layout/primitives';
import styled from 'styled-components';

export const SectionContainer = styled(Inline)`
  inline-size: 20rem;
  background: var(--color-white);
  border: 1px solid var(--color-lightGray);
  border-radius: 4px;
`;

export const SectionButton = styled.button<{ $active: boolean }>`
  inline-size: 50%;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 1.2rem;
  padding: 0.3rem 1rem;
  border-radius: 2px;

  &.btn-disabled-interceptor {
    color: var(--color-black) !important;
  }

  ${({ $active }) =>
    $active &&
    'background: var(--color-dodgerBlue); color: var(--color-white);'}
`;
