import { useAtom } from 'jotai';
import _forEach from 'lodash/forEach';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import { toasts } from 'ui';

import {
  TESTABLE_NODES,
  sourceTypesToIgnoreOnValueReplacement,
} from '../../../utils/constant';
import { KeyValuePairList } from '../../Integrations/types';
import {
  changedNodeIdsAtom,
  workflowEdgesAtom,
  workflowIdAtom,
  workflowNodesAtom,
  workflowStatusAtom,
} from '../atoms/atoms';
import {
  getAllPredecessorsSkippingType,
  getExecutedValueAndStatus,
  getKeyMapppedValueFromDataset,
  sanitizedStringV2,
} from '../utils/common';
import { statusListForDataUpdate } from '../utils/constant';
import { useTestWorkflow } from './restApi/useTestWorkflow';
import { useGenerateDataset } from './useGenerateDataset';
import { useGetDataset } from './useGetDataset';
import { WorkflowNodeType } from './useOpenWorkflow';

type UseTestRestApiNodeProps = {
  localData: any;
  id: string;
};

export function useTestRestApiNode({ localData, id }: UseTestRestApiNodeProps) {
  const [workflowId] = useAtom(workflowIdAtom);
  const [, setChangedNodeIds] = useAtom(changedNodeIdsAtom);
  const [workflowNodes] = useAtom(workflowNodesAtom);
  const [workflowEdges] = useAtom(workflowEdgesAtom);
  const [workflowStatus] = useAtom(workflowStatusAtom);

  const [parentNodes, setParentNodes] = useState<WorkflowNodeType[]>([]);

  const [isTesting, setIsTesting] = useState(false);
  const [currentStatus, setCurrentStatus] = useState<string>(
    localData.status ?? ''
  );

  const workflowNode = workflowNodes.find((wn) => wn.id === id);

  const {
    testWorkflow,
    data: restApiData,
    error: restApiError,
  } = useTestWorkflow();

  const { updatedDataSet } = useGetDataset({
    parentNodes,
  });

  const { tokens } = useGenerateDataset({
    id,
    updatedDataset: updatedDataSet,
  });

  useEffect(() => {
    if (!_isNil(restApiData)) {
      if (
        !_isNil(workflowNode) &&
        statusListForDataUpdate.includes(workflowStatus)
      ) {
        const newWorkflowNode = workflowNode;
        const exec = getExecutedValueAndStatus(restApiData);
        newWorkflowNode.data.status = exec.status;

        newWorkflowNode.data.executedValue = exec.executedValue;

        setChangedNodeIds([]);
        setCurrentStatus(exec.status);

        if (exec.status !== 'error') {
          toasts.success('Node tested successfully', 'node-test-success');
        } else {
          toasts.error('Node test failed', 'node-test-fail');
        }

        setTimeout(() => {
          localData.onWorkflowNodeChange(newWorkflowNode);
        }, 100);
      }
      setIsTesting(false);
    }
  }, [restApiData]);

  const testData = async (
    data: Record<string, any>,
    onSuccess?: () => void
  ) => {
    if (
      parentNodes.find(
        (node) =>
          TESTABLE_NODES.includes(node.type ?? '') &&
          node.data.status !== 'success'
      ) != null
    ) {
      setIsTesting(false);

      return toasts.error(
        'Previous node(s) are  not tested. Please test the previous node(s) before proceeding',
        'parent-node'
      );
    }

    const params: Record<string, any> = {};

    _forEach(data.input, (currInput: Record<string, any>, key: string) => {
      if (
        _isNil(currInput) ||
        _isEmpty(currInput) ||
        _isNil(currInput.value) ||
        _isEmpty(currInput.value)
      ) {
        params[key] = ['headers', 'queryParams'].includes(key) ? [] : '';
      } else {
        if (key === 'body' || key === 'path') {
          const mappedValue = sanitizedStringV2(
            currInput.value,
            updatedDataSet,
            sourceTypesToIgnoreOnValueReplacement
          );
          params[key] = mappedValue;
        } else if (key === 'headers' || key === 'queryParams') {
          const mappedValue = currInput.value.map(
            (currObj: KeyValuePairList) => {
              return {
                ...currObj,
                value: getKeyMapppedValueFromDataset(
                  currObj.value,
                  updatedDataSet,
                  sourceTypesToIgnoreOnValueReplacement
                ),
              };
            }
          );

          params[key] = mappedValue;
        } else {
          params[key] = currInput?.value ?? '';
        }
      }
    });

    try {
      testWorkflow({
        id: workflowId ?? '',
        nodeId: id,
        params,
      });
    } catch (error) {}

    if (typeof onSuccess === 'function') {
      onSuccess();
    }
  };

  useEffect(() => {
    const directParents = getAllPredecessorsSkippingType(
      id,
      workflowNodes,
      workflowEdges,
      'addNode'
    );

    setParentNodes(directParents);
  }, [JSON.stringify(workflowNodes)]);

  return {
    testData,
    restApiData,
    restApiError,
    updatedDataSet,
    tokens,
    parentNodes,
    currentStatus,
    isTesting,
    setCurrentStatus,
    setIsTesting,
  };
}
