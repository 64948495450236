import { Inline } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import type { UseControllerProps, UseFormSetValue } from 'react-hook-form';
import { useFieldArray, useWatch } from 'react-hook-form';
import { BiDuplicate } from 'react-icons/bi';
import { FiPlusCircle } from 'react-icons/fi';
import { CheckboxField, IconButton, Image, Typography } from 'ui';

import { isRuleReadOnlyAtom } from '../../..';
import { siteConstantsAtom } from '../../../../../atom';
import { useSendEventToGTM } from '../../../../../hooks/useSendEventToGTM';
import { generateUid, getTooltipText } from '../../../../../utils/common';
import { envMap } from '../../../../../utils/constant';
import type { sendEventToGTMType } from '../../../types';
import {
  getDefaultValueByDataType,
  getGroupNode,
  getRequiredKey,
} from '../../../utils/common';
import { getIfHasParentGroup } from '../../../utils/decisionTable';
import {
  decisionTableErrorConfig,
  decisionTableNodeIdAtom,
  decisionTableNodesAtom,
} from '../DecisionTable';
import { DecisionTableRowRhsNode, PropertiesNodeStructure } from '../models';
import { DecisionTableRow } from '../types';
import { ConditionFieldArray } from './ConditionFieldArray';
import { RowActions } from './RowActions';
import { RowResultFieldArray } from './RowResultFieldArray';
import {
  AddRow,
  AddRowStyled,
  RowActionsContainer,
  RowNumContainer,
} from './RowsFieldArray.styled';

type RowsFieldArrayProps = Omit<UseControllerProps, 'name'> & {
  setValue: UseFormSetValue<any>;
};

export default function RowsFieldArray({
  control,
  setValue,
}: RowsFieldArrayProps) {
  const properties: PropertiesNodeStructure[] = useWatch({
    control,
    name: 'properties',
  });
  const results = useWatch({ control, name: 'results' });

  const ruleName = useWatch({
    control,
    name: 'ruleName',
  });

  const rows: Array<Record<string, DecisionTableRow>> = useWatch({
    control,
    name: 'rows',
  });

  const [nodes, setNodes] = useAtom(decisionTableNodesAtom);
  const [ruleId] = useAtom(decisionTableNodeIdAtom);

  const [errorConfigData] = useAtom(decisionTableErrorConfig);
  const [siteConstants] = useAtom(siteConstantsAtom);

  const { sendEventToGTM } = useSendEventToGTM();

  const { fields } = useFieldArray({
    name: 'rows',
    control,
  });

  const [isRuleReadOnly] = useAtom(isRuleReadOnlyAtom);

  const handleSendEventToGTM = ({
    action = '',
    element = '',
    actionName = '',
  }: sendEventToGTMType) => {
    sendEventToGTM({
      event: 'rule',
      ruleId,
      ruleName,
      type: 'decisionTable',
      nec_source: '',
      action,
      element,
      action_name: actionName,
    });
  };

  const addRowHandler = (atIndex?: number) => {
    if (isRuleReadOnly) {
      return;
    }

    handleSendEventToGTM({
      action: 'add',
      element: 'row',
      actionName: '',
    });

    const groupId = generateUid('rule_');
    const childNodes: string[] = [];
    const conditionList: any[] = [];
    const resultList: any[] = [];
    let previousGroup: string | null = null;
    properties?.forEach((property, index: number) => {
      /**
       * Here we'd be generating the condition nodes to be fed into the form
       * as well as feed into the record of nodes
       * Also Do the same for it's corresponding RHS
       */
      const conditionID = generateUid('rule_');

      const propertyKey = getRequiredKey(property, ['id']);

      const ruleRow: Record<string, any> = {
        [conditionID]: {
          value: 'any',
          type: 'generic',
          leftOperands: 1,
          rightOperands: 0,
        },
        rhs: [],
      };

      const jsCommentText: string = getTooltipText(
        siteConstants,
        'rules',
        'formulaInCondition',
        'otherText'
      );

      if (property[propertyKey].dataType === 'jsCondition') {
        ruleRow[conditionID].value = `${jsCommentText}\n1===1`;
        ruleRow[conditionID].query = '';
        ruleRow[conditionID].type = 'jsCondition';
        ruleRow[conditionID].name = 'Any';
      } else if (property[propertyKey].dataType === 'excelCondition') {
        ruleRow[conditionID].value = `1===1`;
        ruleRow[conditionID].query = '';
        ruleRow[conditionID].type = 'excelCondition';
        ruleRow[conditionID].name = 'Any';
      }

      conditionList.push(ruleRow);

      const doesHaveParent = getIfHasParentGroup(rows, nodes, index);

      if (!doesHaveParent.hasParent) {
        childNodes.push(conditionID);

        setNodes((prev) => ({
          ...prev,
          [conditionID]: {
            nodeType: ['jsCondition', 'excelCondition'].includes(
              property[propertyKey].dataType
            )
              ? property[propertyKey].dataType
              : 'condition',
            parent: groupId,
            operator: ['jsCondition', 'excelCondition'].includes(
              property[propertyKey].dataType
            )
              ? ''
              : 'any',
            siblingIndex: 1,
            leftNode: [propertyKey],
            rightNode: [],
            dataType: property[propertyKey]?.dataType,
            name: 'Any',
            query: ['jsCondition'].includes(property[propertyKey].dataType)
              ? `${jsCommentText}\n1===1`
              : '1===1',
          },
        }));
      } else {
        const doesHaveParentSib = getIfHasParentGroup(rows, nodes, index - 1);

        if (doesHaveParentSib.group === doesHaveParent.group) {
          setNodes((prev) => ({
            ...prev,
            [conditionID]: {
              nodeType: ['jsCondition', 'excelCondition'].includes(
                property[propertyKey].dataType
              )
                ? property[propertyKey].dataType
                : 'condition',
              parent: previousGroup ?? '',
              operator: ['jsCondition', 'excelCondition'].includes(
                property[propertyKey].dataType
              )
                ? ''
                : 'any',
              siblingIndex: 1,
              leftNode: [propertyKey],
              rightNode: [],
              dataType: property[propertyKey]?.dataType,
              name: 'Any',
              query: ['jsCondition'].includes(property[propertyKey].dataType)
                ? `${jsCommentText}\n1===1`
                : '1===1',
            },
            [previousGroup ?? '']: {
              ...prev[previousGroup ?? ''],
              children: [
                ...(prev[previousGroup ?? ''].children ?? []),
                conditionID,
              ],
            },
          }));
        } else if (doesHaveParentSib.group !== doesHaveParent.group) {
          const newGroupId = generateUid('rule_');
          childNodes.push(newGroupId);
          previousGroup = newGroupId;

          setNodes((prev) => ({
            ...prev,
            [conditionID]: {
              nodeType: ['jsCondition', 'excelCondition'].includes(
                property[propertyKey].dataType
              )
                ? property[propertyKey].dataType
                : 'condition',
              parent: newGroupId,
              operator: ['jsCondition', 'excelCondition'].includes(
                property[propertyKey].dataType
              )
                ? ''
                : 'any',
              siblingIndex: 1,
              leftNode: [propertyKey],
              rightNode: [],
              dataType: property[propertyKey]?.dataType,
              name: 'Any',
              query: ['jsCondition'].includes(property[propertyKey].dataType)
                ? `${jsCommentText}\n1===1`
                : '1===1',
            },
            [newGroupId]: getGroupNode(
              groupId,
              [conditionID],
              doesHaveParent.operator
            ),
          }));
        }
      }
    });

    results?.forEach((result: any) => {
      const resultKey = getRequiredKey(result, ['id']);
      const outputKey = generateUid('output_');

      const dataType = result[resultKey].dataType ?? 'string';

      const outputObject = {
        [outputKey]: {
          dataType,
          value:
            dataType === 'boolean'
              ? 'false'
              : getDefaultValueByDataType(result[resultKey].dataType),
        },
      };

      resultList.push(outputObject);
    });

    const firstField = getRequiredKey(fields[0], ['id']);

    setNodes((prev) => ({
      ...prev,
      [groupId]: {
        nodeType: 'group',
        parent: '',
        operator: prev[firstField]?.operator ?? 'and',
        siblingIndex: 1,
        children: childNodes,
      },
    }));
    const rowItem = {
      [groupId]: {
        isEnabled: true,
        condition: conditionList,
        ruleResult: resultList,
      },
    };

    if (typeof atIndex !== 'number') {
      setValue('rows', [...rows, rowItem]);
    } else {
      const newRows = [...rows];

      newRows.splice(atIndex, 0, rowItem);

      setValue('rows', [...newRows]);
    }
  };

  const duplicateRowHandler = (rowIndex: number) => {
    const groupId = generateUid('rule_');
    const childNodes: string[] = [];
    const conditionList: any[] = [];
    const resultList: any[] = [];

    const newRows = [...rows];
    const rowKey = getRequiredKey(rows[rowIndex], ['id']);

    let newGroupId: string | null = null;

    rows[rowIndex][rowKey].condition.forEach((condition, cIndex) => {
      const conditionKey = getRequiredKey(condition, ['id', 'rhs']);

      const doesHaveParent = getIfHasParentGroup(rows, nodes, cIndex);
      const doesHaveParentSib = getIfHasParentGroup(rows, nodes, cIndex - 1);

      const newConditionKey = generateUid('rule_');

      if (doesHaveParent.hasParent) {
        if (doesHaveParent.group !== doesHaveParentSib.group) {
          newGroupId = generateUid('rule_');
          setNodes((prev) => ({
            ...prev,
            [newGroupId ?? '']: getGroupNode(
              groupId,
              [...(prev[newGroupId ?? '']?.children ?? []), newConditionKey],
              doesHaveParent.operator
            ),
          }));

          childNodes.push(newGroupId);
        } else if (doesHaveParent.group === doesHaveParentSib.group) {
          setNodes((prev) => ({
            ...prev,
            [newGroupId ?? '']: {
              ...prev[newGroupId ?? ''],
              children: [
                ...(prev[newGroupId ?? '']?.children ?? []),
                newConditionKey,
              ],
            },
          }));
        }
      } else {
        childNodes.push(newConditionKey);
      }

      const conditionNode = nodes[conditionKey];

      const rhsIdList: string[] = [];
      const rhsNodeList: DecisionTableRowRhsNode = [];

      condition.rhs.forEach((rhsNode) => {
        const rhsKey = getRequiredKey(rhsNode, ['id']);
        const newRhsNodeKey = generateUid('rule_');

        rhsIdList.push(newRhsNodeKey);

        setNodes((prev) => ({
          ...prev,
          [newRhsNodeKey]: { ...prev[rhsKey], parent: newConditionKey },
        }));

        rhsNodeList.push({
          [newRhsNodeKey]: rhsNode[rhsKey],
        });
      });

      setNodes((prev) => ({
        ...prev,
        [newConditionKey]: {
          ...conditionNode,
          parent: doesHaveParent.hasParent ? newGroupId ?? '' : groupId,
          rightNode: rhsIdList,
        },
      }));

      const newConditionNode = {
        [newConditionKey]: {
          ...condition[conditionKey],
        },
        rhs: rhsNodeList,
      };

      conditionList.push(newConditionNode);
    });

    rows[rowIndex][rowKey].ruleResult.forEach((result) => {
      const resultKey = getRequiredKey(result, ['id']);
      const newResultKey = generateUid('output_');

      resultList.push({
        [newResultKey]: result[resultKey],
      });
    });

    const rowToBeCopied = {
      [groupId]: {
        condition: conditionList,
        isEnabled: rows[rowIndex][rowKey].isEnabled,
        ruleResult: resultList,
      },
    };

    setNodes((prev) => ({
      ...prev,
      [groupId]: {
        ...prev[rowKey],
        children: childNodes,
      },
    }));
    newRows.splice(rowIndex + 1, 0, rowToBeCopied);

    setValue('rows', [...newRows]);
  };

  return (
    <div>
      {fields.map((field, index) => {
        const rowKey = getRequiredKey(field, ['id']);

        const isErrorInRow = errorConfigData[index]?.length > 0 ?? false;

        return (
          <Inline gutter={0} align="stretch" key={field.id}>
            <RowActionsContainer align="center" gutter={12}>
              <CheckboxField
                name={`rows.${index}.${rowKey}.isEnabled`}
                useId={`rows.${index}.${rowKey}.isEnabled`}
                control={control}
                appearance="switch"
                disabled={isRuleReadOnly}
              />
              <IconButton
                disabled={isRuleReadOnly}
                onClick={() => duplicateRowHandler(index)}
              >
                <BiDuplicate />
              </IconButton>
              <RowActions
                control={control}
                setValue={setValue}
                index={index}
                rowKey={rowKey}
                addRowAtIndex={addRowHandler}
                duplicateRowHandler={duplicateRowHandler}
                isLast={fields.length === 1}
                handleSendEventToGTM={handleSendEventToGTM}
              />
            </RowActionsContainer>

            <RowNumContainer
              justify="center"
              align="center"
              isError={isErrorInRow}
            >
              <Typography>{index + 1}</Typography>
            </RowNumContainer>

            <ConditionFieldArray
              index={index}
              setValue={setValue}
              control={control}
              rowKey={rowKey}
              handleSendEventToGTM={handleSendEventToGTM}
              isError={isErrorInRow}
            />

            <RowResultFieldArray
              index={index}
              setValue={setValue}
              control={control}
              rowKey={rowKey}
              handleSendEventToGTM={handleSendEventToGTM}
              isError={isErrorInRow}
            />
          </Inline>
        );
      })}

      <Inline gutter={0}>
        <RowActionsContainer />
        <AddRow padding={8}>
          <IconButton onClick={addRowHandler}>
            <AddRowStyled $disabled={isRuleReadOnly} align="center" gutter={5}>
              {isRuleReadOnly ? (
                <FiPlusCircle color="var(--color-darkGray)" />
              ) : (
                <Image
                  src={`${envMap.VITE_ASSETS_URL}website/icons/plus.svg`}
                  alt="plus"
                  size="xs"
                />
              )}
              <Typography>Add Row</Typography>
            </AddRowStyled>
          </IconButton>
        </AddRow>
      </Inline>
    </div>
  );
}
