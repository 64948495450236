import { Inline } from '@bedrock-layout/primitives';
import _isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';
import { Typography } from 'ui';

type WrapperProps = {
  bgcolor?: string;
  $showBorder: boolean;
};

export const Wrapper = styled(Inline)<WrapperProps>`
  cursor: pointer;
  border-radius: 4px;
  justify-content: space-between;
  background-color: ${({ bgcolor }) =>
    !_isEmpty(bgcolor) ? bgcolor : 'var(--color-lavender)'};

  border: ${({ $showBorder }) =>
    $showBorder ? '1px solid var(--color-irisBlue)' : 'none'};

  &:hover {
    background: var(--color-solitude);
  }
`;

export const NameIconContainer = styled(Inline)`
  min-inline-size: 3.6rem;
  min-block-size: 3.6rem;
  background-color: var(--color-navyBlue);
  color: var(--color-white);
  border-radius: 5px;
  cursor: pointer;
`;

export const WorkspaceName = styled(Typography)`
  word-break: break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
`;

export const UserCount = styled(Typography)`
  color: var(--color-suvaGray);
`;

export const ArrowIconContainer = styled(Inline)`
  min-inline-size: 2.4rem;
  min-block-size: 2.4rem;
`;
