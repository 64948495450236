import { Inline, PadBox } from '@bedrock-layout/primitives';
import styled from 'styled-components';

export const AuthKeyContainer = styled(Inline)`
  width: 50%;
  border-radius: 4px;
  flex-grow: 1;
  max-width: 65rem;
  align-items: stretch;
`;

export const InlineStyled = styled(Inline)`
  justify-content: space-between;
  background-color: var(--color-solitudeSecondary);
  flex-grow: 1;
  padding: 0rem 1.2rem;
  border: 1px solid var(--color-lightGray);
  border-radius: 5px;
`;

export const KeyValueStyled = styled.p`
  word-break: break-all;
`;

export const CopyIconContainer = styled(PadBox)`
  border-left: 2px solid var(--color-lightGray);
`;
