import { PadBox } from '@bedrock-layout/padbox';
import { Inline } from '@bedrock-layout/primitives';
import { zodResolver } from '@hookform/resolvers/zod';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Button, CheckboxField, Typography, toasts } from 'ui';

import { enableGetAllWorkspaceApiAtom } from '../../../../atom';
import { getValueFromObject } from '../../../../components/Listing/utils';
import { PermissionWrapper } from '../../../../components/PermissionComponent';
import { permissionObj } from '../../../../components/PermissionComponent/constant';
import { useCheckPermissions } from '../../../../components/PermissionComponent/hooks/useCheckPermissions';
import { ENTITY_ID } from '../../../../utils/constant';
import { handleKeyDown } from '../../../../utils/form';
import { currentWorkspaceDetailAtom } from '../../atom';
import { useUpdateCurrentWorkspace } from '../../hooks/restApi/useUpdateCurrentWorkspace';
import { WorkspaceContainer } from '../common/Workspace.styled';
import type { WorkSpaceDetailsType } from '../types';
import {
  ButtonContainer,
  WSTextField,
  WorkSpaceFormFieldContainer,
} from './WorkspaceSettings.styled';
import { workspaceSettingsObject } from './constant';
import { settingsFormSchema } from './schema';

export function WorkspaceSettings() {
  const {
    WORKSPACE_ID,
    WORKSPACE_NAME,
    WORKSPACE_DOMAIN,
    APPROVAL_FLOW_ENABLED,
  } = workspaceSettingsObject;
  const [currentWorkspace] = useAtom(currentWorkspaceDetailAtom);
  const [, setEnableAllWorkspaceApi] = useAtom(enableGetAllWorkspaceApiAtom);

  const { uuid, name, subDomain, approvalFlowEnabled } = currentWorkspace ?? {
    uuid: '',
    name: '',
    subDomain: '',
    approvalFlowEnabled: false,
  };

  const plan = JSON.parse(window.sessionStorage.getItem('userPlan') ?? '{}');

  const { control, setValue, handleSubmit } = useForm<any>({
    resolver: zodResolver(settingsFormSchema),
    defaultValues: {
      [WORKSPACE_ID]: uuid,
      [WORKSPACE_NAME]: name,
      [WORKSPACE_DOMAIN]: subDomain,
      [APPROVAL_FLOW_ENABLED]: approvalFlowEnabled,
      // Please Do Not remove, will uncomment when BE will support these fields
      // [TIMEZONE]: timezone,
      // [DATE_FORMAT]: datetimeFormat,
      // [USER_COUNT]: userCount,
      // [IS_DEFAULT]: isDefault,
    },
    mode: 'onSubmit',
  });

  const entityList = [ENTITY_ID.workspace];
  const { isHide: disableNameField } = useCheckPermissions({
    allowedPermission: [permissionObj.create, permissionObj.edit],
    entityList,
  });

  const { workspaceData, updateCurrentWorkspace, isLoading } =
    useUpdateCurrentWorkspace();

  useEffect(() => {
    if (!isLoading && workspaceData?.status === 200) {
      setEnableAllWorkspaceApi(true);
    }
  }, [isLoading]);

  useEffect(() => {
    if (!_isNil(currentWorkspace) && !_isEmpty(currentWorkspace)) {
      setValue(WORKSPACE_ID, uuid);
      setValue(WORKSPACE_NAME, name);
      setValue(WORKSPACE_DOMAIN, subDomain);
      setValue(APPROVAL_FLOW_ENABLED, approvalFlowEnabled);
    }
  }, [JSON.stringify(currentWorkspace)]);

  const onSubmit = async (data: WorkSpaceDetailsType) => {
    updateCurrentWorkspace({
      params: data,
    });
    toasts.success('Workspace updated successfully', 'updated-success');
  };

  const handleSaveWorkspaceSettings = handleSubmit(
    async (data) => await onSubmit(data)
  );

  return (
    <form onKeyDown={handleKeyDown}>
      <PadBox padding={'2.4rem'} gutter={'2.4rem'} as={WorkspaceContainer}>
        <WorkSpaceFormFieldContainer>
          <WSTextField
            control={control}
            name={WORKSPACE_NAME}
            label="Workspace Name"
            placeholder="Enter Workspace Name"
            size="medium"
            disabled={disableNameField}
            labelFontWeight={700}
            heightFull
          />
        </WorkSpaceFormFieldContainer>
        <WorkSpaceFormFieldContainer>
          <WSTextField
            control={control}
            name={WORKSPACE_DOMAIN}
            label="Workspace Domain"
            placeholder="Enter Workspace Domain"
            size="medium"
            disabled
            labelFontWeight={700}
            heightFull
          />
        </WorkSpaceFormFieldContainer>

        <WorkSpaceFormFieldContainer>
          <Inline
            id={getValueFromObject(plan, 'plan.approvalFlow.componentId')}
            data-premium-component-id={getValueFromObject(
              plan,
              'plan.approvalFlow.componentId'
            )}
            data-premium-component-trigger={getValueFromObject(
              plan,
              'plan.approvalFlow.trigger'
            )}
            align="center"
          >
            <Typography fontWeight={700}>Approval Flow</Typography>
            <CheckboxField
              control={control}
              name={APPROVAL_FLOW_ENABLED}
              showError
              appearance="switch"
              useId={APPROVAL_FLOW_ENABLED}
              disabled={disableNameField}
            />
          </Inline>
        </WorkSpaceFormFieldContainer>

        <PermissionWrapper
          allowedPermission={[permissionObj.create, permissionObj.edit]}
          entityList={entityList}
        >
          <ButtonContainer onClick={handleSaveWorkspaceSettings}>
            <Button>Save</Button>
          </ButtonContainer>
        </PermissionWrapper>
      </PadBox>
    </form>
  );
}
