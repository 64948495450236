import { useMutation } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import { toasts } from 'ui';

import { useAxiosPrivate } from '../../../../hooks';
import { showErrorToast } from '../../../../utils/common';
import type { CustomAxiosError } from '../../../../utils/response/types';

export function useRemoveMemberFromAWorkspace() {
  const { axiosPrivate } = useAxiosPrivate();

  const { isLoading, mutateAsync: removeAMemberFromAWorkSpace } = useMutation({
    mutationFn: async (memberId: string) => {
      return await axiosPrivate.delete('/workspace/member', {
        data: {
          user_uuid: memberId,
        },
      });
    },
    onSuccess() {
      toasts.success('Member removed successfully', 'deleted-success');
    },
    onError(error: AxiosError<CustomAxiosError>) {
      showErrorToast(error);
    },
  });

  return {
    isLoading,
    removeAMemberFromAWorkSpace,
  };
}
