/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { parse } from 'date-fns';
import _forEach from 'lodash/forEach';
import _includes from 'lodash/includes';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _isUndefined from 'lodash/isUndefined';
import _reduce from 'lodash/reduce';
import { UseFormSetError } from 'react-hook-form';
import {
  Attributes,
  DataTypes,
  Dataset,
  NectedSuggestionModel,
  getDataTypeNected,
} from 'ui';
import { ZodError } from 'zod';

import {
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  convertArrayAsInput,
  convertCaSampleValues,
  formatNectedDate,
  getPropertyIfExists,
  isArrayAsInputValid,
} from '../../../utils/common';
import {
  TokenScores,
  datatypesToIgnoreOnValidation,
} from '../../../utils/constant';
import { EXTRACT_TOKEN_REGEX, KEY_REGEX } from '../../../utils/regex';
import { isValidDate, isValidDateTime } from '../../../utils/validation';
import type {
  AdditionalActionFormData,
  DecisionTableFormStructure,
  DecisionTableNodesModel,
  DecisionTableRow,
  DecisionTableRowResult,
  DecisionTableRowRhsNode,
  PropertiesNodeStructure,
  RowStructure,
} from '../components/DecisionTable/models';
import { DecisionTableResultRow } from '../components/DecisionTable/types';
import type {
  CustomInputModel,
  ProductionConfigModel,
  ResultAddDataModel,
} from '../components/SimpleRule/models';
import type { AttributeModel, HasSameParentType } from '../models';
import { ruleEnviornmentConfigSchema } from '../schema';
import {
  customAttributesToPayloadObject,
  getEvaluatedValueForResult,
  getRequiredKey,
  getTrigger,
  handleSetActionParams,
  isRuleNameValid,
  isValueNumeric,
  validateOutput,
  validateRhsParamType,
  validateScheduleBeforeSaving,
  validateToken,
  validateTokensBasedOnEditor,
  validateTriggerBeforeTest,
} from './common';

export const deleteDecisionTableNodes = (
  ruleId: string,
  rules: Record<string, DecisionTableNodesModel>
) => {
  const scheduledDeletedElementsIds: string[] = [];
  getScheduledDeletedElementsIds(
    rules[ruleId],
    ruleId,
    scheduledDeletedElementsIds,
    rules
  );

  const newRules = { ...rules };
  const updatedRules: Record<string, DecisionTableNodesModel> = {};

  const ruleChildren = newRules[rules[ruleId]?.parent]?.children;

  if (!_isNil(rules[ruleId]?.parent) && !_isNil(ruleChildren)) {
    const children = ruleChildren.filter((child) => child !== ruleId);

    newRules[rules[ruleId].parent].children = children;

    if (!_isNil(children)) {
      let isIndex = false;

      const siblingIndexById: Record<string, number> = {};

      rules[ruleId]?.children?.forEach((child: string) => {
        const siblingIndex = rules[child].siblingIndex;
        const value = !_isNil(siblingIndex) ? siblingIndex : -1;

        if (!_isNil(value) && value !== -1) {
          siblingIndexById[child] = value;
        }
      });

      const sortedSiblingIndexById = Object.entries(siblingIndexById)
        .sort(
          ([, siblingOneIndex], [, siblingTwoIndex]) =>
            siblingOneIndex - siblingTwoIndex
        )
        .map(([siblingRuleId]) => siblingRuleId);

      if (sortedSiblingIndexById.length > 0) {
        sortedSiblingIndexById.forEach((key) => {
          let sibIndex = 1;
          const siblingIndex = newRules[key].siblingIndex;

          if (!_isNil(siblingIndex)) {
            sibIndex = siblingIndex;
          }

          if (isIndex && !_isNil(siblingIndex)) {
            newRules[key].siblingIndex = !_isNil(sibIndex) ? sibIndex - 1 : 1;
          }

          if (key === ruleId) {
            isIndex = true;
          }
        });
      }
    }
  } else if (_isNil(rules[ruleId])) {
    return newRules;
  }

  if (scheduledDeletedElementsIds.length > 0) {
    Object.keys(newRules).forEach((id) => {
      if (!scheduledDeletedElementsIds.includes(id)) {
        updatedRules[id] = newRules[id];
      }
    });
  }

  return updatedRules;
};

const getScheduledDeletedElementsIds = (
  rule: DecisionTableNodesModel,
  ruleId: string,
  idList: string[],
  rules: Record<string, DecisionTableNodesModel>
) => {
  if (!idList.includes(ruleId) && !_isNil(rule)) {
    idList.push(ruleId);

    if (!_isNil(rule.rightNode)) {
      idList.push(...rule.rightNode);
    }

    if (
      rule.nodeType === 'group' &&
      !_isNil(rule.parent) &&
      !_isNil(rule.children) &&
      rule.children.length > 0
    ) {
      rule.children.map((child) =>
        getScheduledDeletedElementsIds(rules[child], child, idList, rules)
      );
    }
  }

  return null;
};

export const transformDecisionTableData = (
  data: DecisionTableFormStructure,
  nodes: Record<string, DecisionTableNodesModel>,
  customAttributes: Record<string, AttributeModel>,
  dataSetSelected: string[] = [],
  firstCustomAttribute: string = ''
) => {
  const policy = data.rulePolicy?.value;

  const name = data.ruleName;

  const description = data.ruleDescription;
  const editMode = data.editMode;

  const customInput: Record<string, CustomInputModel> =
    customAttributesToPayloadObject(customAttributes);

  const conditions = {
    startNode: '',
    nodes: parseDecisionTableNodes(nodes),
  };

  const decisionTableData = getDecisionTableData(data);

  const action = getOutputData(data);

  const { settings } = getTrigger(data.productionConfig);

  return {
    type: 'decisionTable',
    name,
    description,
    editMode,
    policy,
    decisionTable: decisionTableData,
    settings,
    conditions,
    action,
    customInput,
    dataSetId: dataSetSelected[0] ?? '',
    firstCustomInput: firstCustomAttribute,
  };
};

export const getDecisionTableData = (data: DecisionTableFormStructure) => {
  const firstRow = Object.keys(data.rows[0]).filter((key) => key !== 'id')[0];

  const firstProperty = Object.keys(data.properties[0]).filter(
    (key) => key !== 'id'
  )[0];

  const firstResult = _isNil(data.results[0])
    ? ''
    : Object.keys(data.results[0]).filter((key) => key !== 'id')[0];

  const firstAggOutputData = _isNil(data.additionalData[0]) ? '' : `aggData_1`;

  const properties: Record<string, any> = {};

  data.properties.forEach((property, index: number) => {
    const propertyKey = getRequiredKey(property, ['id']);

    const nextId = !_isNil(data.properties[index + 1])
      ? getRequiredKey(data.properties[index + 1], ['id'])
      : '';
    const prevId = !_isNil(data.properties[index - 1])
      ? getRequiredKey(data.properties[index - 1], ['id'])
      : '';

    properties[propertyKey] = {
      nextId,
      prevId,
      type: 'simpleCondition',
    };
  });

  const results: Record<string, any> = {};

  data.results?.forEach((result, index: number) => {
    const resultKey = getRequiredKey(result, ['id']);

    const nextId = !_isNil(data.results[index + 1])
      ? getRequiredKey(data.results[index + 1], ['id'])
      : '';
    const prevId = !_isNil(data.results[index - 1])
      ? getRequiredKey(data.results[index - 1], ['id'])
      : '';

    results[resultKey] = {
      nextId,
      prevId,
      keyName: result[resultKey].keyName,
      dataType: result[resultKey].dataType,
    };
  });

  const rows: Record<string, any> = {};

  data.rows.forEach((row, index: number) => {
    const rowKey = getRequiredKey(row, ['id']);

    const nextId = !_isNil(data.rows[index + 1])
      ? Object.keys(data.rows[index + 1]).filter((key) => key !== 'id')[0]
      : '';
    const prevId = !_isNil(data.rows[index - 1])
      ? Object.keys(data.rows[index - 1]).filter((key) => key !== 'id')[0]
      : '';

    const isEnabled = row[rowKey].isEnabled;

    const firstOutputData = !_isNil(row[rowKey].ruleResult[0])
      ? Object.keys(row[rowKey].ruleResult[0]).filter((key) => key !== 'id')[0]
      : '';

    rows[rowKey] = {
      firstOutputData,
      isEnabled,
      nextId,
      prevId,
    };
  });

  return {
    firstRow,
    firstProperty,
    firstResult,
    firstAggOutputData,
    rows,
    properties,
    results,
  };
};

export const getOutputData = (data: DecisionTableFormStructure) => {
  const outputData: Record<string, any> = {};
  let actionNode: Record<string, any> = {};

  data.rows.forEach((row) => {
    const rowKey = getRequiredKey(row, ['id']);
    const results = row[rowKey].ruleResult;
    const resultList = data.results;

    results.forEach((result, resultIndex: number) => {
      const resultKey = getRequiredKey(result, ['id']);

      const currentResult = resultList[resultIndex];
      const currentResultKey = Object.keys(currentResult).filter(
        (key) => key !== 'id'
      )[0];
      const keyName = currentResult[currentResultKey].keyName;

      const next = !_isNil(results[resultIndex + 1])
        ? getRequiredKey(results[resultIndex + 1], ['id'])
        : '';

      const value = getEvaluatedValueForResult(
        result[resultKey].value,
        result[resultKey].dataType,
        !_isNil(result[resultKey].source) && !_isEmpty(result[resultKey].source)
      );

      const source = result[resultKey].source ?? null;
      const attribute = result[resultKey].attribute ?? null;
      let executedValue = result[resultKey].executedValue;

      if (
        _isNil(source) &&
        _isNil(attribute) &&
        !['jsFormula', 'json', 'excelFormula', 'list'].includes(
          result[resultKey].dataType ?? ''
        )
      ) {
        executedValue = value;
      }

      outputData[resultKey] = {
        name: keyName,
        dataType: result[resultKey].dataType,
        returnType: result[resultKey].returnType,
        executedValue,
        source,
        attribute,
        value,
        next,
      };
    });
  });

  actionNode = handleSetActionParams(data.thenActionParams);

  data.additionalData.forEach((additionalData, index) => {
    const currentId = `aggData_${index + 1}`;
    const nextId = `aggData_${index + 2}`;
    const next = !_isNil(data.additionalData[index + 1]) ? nextId : '';

    outputData[currentId] = {
      name: additionalData.name,
      dataType: additionalData.dataType,
      value: additionalData.value,
      returnType: additionalData.returnType ?? '',
      executedValue: additionalData.executedValue ?? '',
      next,
    };
  });

  return {
    then: {
      firstOutputData: '',
      firstActionNode: data.thenActionParams.length > 0 ? 'actionData_1' : '',
      outputData,
      actionNode,
    },
    else: {},
  };
};

export const parseDecisionTableNodes = (
  nodes: Record<string, DecisionTableNodesModel>
) => {
  return _reduce(
    nodes,
    (result, node, key) => {
      return {
        ...result,
        [key]: parseConstantNodeData(node),
      };
    },
    {}
  );
};

export const parseConstantNodeData = (node: DecisionTableNodesModel) => {
  if (
    node.nodeType === 'constant' &&
    !_isNil(node.dataType) &&
    !_isEmpty(node.dataType) &&
    node.dataType === 'numeric' &&
    typeof node.value === 'string'
  ) {
    return {
      ...node,
      value: parseFloat(node.value),
    };
  }

  if (
    node.nodeType === 'constant' &&
    !_isNil(node.dataType) &&
    !_isEmpty(node.dataType) &&
    node.dataType === 'list' &&
    !Array.isArray(node.value)
  ) {
    const tokens = (typeof node.value === 'string' ? node.value : '').match(
      EXTRACT_TOKEN_REGEX
    );

    // If there is token present in case of list then data will be saved in string format
    if (!_isNil(tokens) && !_isEmpty(tokens)) {
      return {
        ...node,
      };
    }

    return {
      ...node,
      value: convertArrayAsInput((node.value as unknown as string) ?? ''),
    };
  }

  return node;
};

export const handleTestBeforeSubmit = async (
  data: DecisionTableFormStructure,
  nodes: Record<string, DecisionTableNodesModel>,
  dataset: Record<string, Dataset>,
  customAttributes: Record<string, AttributeModel>,
  unfilteredDataSet: Record<string, Dataset>,
  tokens: string[] = [],
  setError?: UseFormSetError<any>,
  setErrorConfig?: (config: Record<number, number[]>) => void
) => {
  let isTestingValid = true;
  let isCacheValid = true;

  const errorInRule = {
    action: false,
    condition: false,
    outputData: false,
    additionalData: false,
  };

  const isErrorConfig = typeof setErrorConfig === 'function';

  const isSetError = typeof setError === 'function';

  const isValidProperties = validateProperties(
    data,
    customAttributes,
    nodes,
    dataset,
    setError
  );

  if (setError) {
    const cacheTesting = validateTriggerBeforeTest(
      data.productionConfig.cache ?? {},
      setError,
      'productionConfig',
      dataset
    );

    isCacheValid = cacheTesting;
  }

  const isNameValid = isRuleNameValid(data.ruleName, setError);

  if (!isNameValid) {
    isTestingValid = false;
  }

  if (_isNil(data.rulePolicy)) {
    if (isSetError) {
      setError(`rulePolicy`, {
        message: 'Must select a policy',
        type: 'validate',
      });
    }

    isTestingValid = false;
  }

  const isValidResults = validateResults(data, setError);

  const {
    isTestingValid: isValidRows,
    isValidConditions,
    isValidOutput,
    updatedErrorConfig,
  } = await validateRows(
    data,
    nodes,
    dataset,
    unfilteredDataSet,
    tokens,
    setError
  );

  const isTriggerValid = validateScheduleBeforeSaving(
    data.productionConfig,
    'productionConfig',
    customAttributes,
    setError
  );

  if (isErrorConfig) {
    setErrorConfig(updatedErrorConfig);
  }

  const isAdditionalDataValid = await validateAdditionalData(
    data,
    dataset,
    tokens,
    setError
  );

  if (!isValidConditions) {
    errorInRule.condition = true;
  }

  if (!isValidOutput || !isValidResults) {
    errorInRule.outputData = true;
  }

  if (!isAdditionalDataValid) {
    errorInRule.additionalData = true;
  }

  return {
    isTestValid:
      isTestingValid &&
      isValidProperties &&
      isValidRows &&
      isValidResults &&
      isAdditionalDataValid &&
      isTriggerValid &&
      isCacheValid,
    errorInRule,
  };
};

const validateAdditionalData = async (
  data: DecisionTableFormStructure,
  dataset: Record<string, Dataset>,
  tokens: string[],
  setError?: UseFormSetError<any>
) => {
  let isValid = true;
  const isSetError = typeof setError === 'function';

  const rowKey = getRequiredKey(data.rows[0], ['id']);

  const rowResults = data.rows[0][rowKey].ruleResult;

  const duplicateRuleNames: Record<string, number[]> = {};

  const resultAddDataModels: ResultAddDataModel[] = rowResults.map(
    (result, resultIndex: number) => {
      const resultKey = getRequiredKey(result, ['id']);
      const resultHeaderKey = getRequiredKey(data.results[resultIndex], ['id']);

      return {
        keyName: data.results[resultIndex][resultHeaderKey].keyName,
        dataType: result[resultKey].dataType,
        value: result[resultKey].value as string,
        executedValue: result[resultKey].executedValue,
      };
    }
  );

  const additionalData: ResultAddDataModel[] = data.additionalData.map(
    (item) => {
      return {
        keyName: item.name,
        dataType: item.dataType,
        value: item.value,
        returnType: item.returnType,
        executedValue: item.executedValue,
      };
    }
  );

  const outputDataListAttributes: Record<string, Attributes> = {};
  const additionalDataAttributes: Record<string, Attributes> = {};

  resultAddDataModels.forEach((field) => {
    outputDataListAttributes[field.keyName] = {
      name: field.keyName,
      dataType: field.dataType as DataTypes,
      executedValue: [
        // eslint-disable-next-line no-extra-boolean-cast
        !!field.executedValue
          ? convertCaSampleValues(
              field.returnType ? field.returnType : field.dataType,
              field.executedValue
            )
          : convertCaSampleValues(
              field.returnType ? field.returnType : field.dataType,
              field.value
            ),
      ],
    };
  });

  additionalData.forEach((data) => {
    additionalDataAttributes[data.keyName] = {
      name: data.keyName,
      dataType: data.returnType,
      executedValue: data.executedValue ?? undefined,
    };
  });

  const finalRows = getDtOutputRows(data.results, data.rows, dataset);

  const updatedDataset: Record<string, any> = {
    ...dataset,
    outputData: {
      attributes: {
        output: {
          dataType: 'list',
          name: 'output',
          executedValue: finalRows,
        },
      },
      name: 'Output Data Attributes',
      id: 'output_data_list_attributes',
    },
    outputDataList: {
      attributes: outputDataListAttributes,
      name: 'Output Data Attributes',
      id: 'output_data_attributes',
    },
    additionalData: {
      attributes: additionalDataAttributes,
      name: 'Additional Data Attributes',
      id: 'additional_data_attributes',
    },
  };

  for (let index = 0; index < data.additionalData.length; index++) {
    const dt = data.additionalData[index];

    if (_isNil(duplicateRuleNames[dt.name])) {
      duplicateRuleNames[dt.name] = [index];
    } else {
      duplicateRuleNames[dt.name] = [...duplicateRuleNames[dt.name], index];
    }

    const resultValue = dt.value;

    if (dt.dataType === 'jsFormula') {
      if (
        typeof dt.executedValue !== 'number' &&
        dt.executedValue === 'undefined' &&
        dt.executedValue !== ''
      ) {
        if (isSetError) {
          setError(`additionalData.${index}.value`, {
            message: 'Incorrect executed value',
            type: 'validate',
          });
        }

        isValid = false;
      }

      const { isValid: isAdditionalDataValid, message } =
        await validateTokensBasedOnEditor({
          dataset: updatedDataset,
          query: resultValue,
          editorType: 'jsFormula',
        });

      if (!isAdditionalDataValid || _isEmpty(resultValue)) {
        if (isSetError) {
          setError(`additionalData.${index}.value`, {
            message:
              message && message !== '' ? message : 'Incorrect executed value',
            type: 'validate',
          });
        }

        isValid = false;
      }
    }
  }

  const updatedTokens = [
    ...data.results.map((result) => {
      const id = getRequiredKey(result, ['id']);

      return `<<outputDataList.${result[id].keyName}>>`;
    }),
    ...data.additionalData.map((aData) => {
      return `<<additionalData.${aData.name}>>`;
    }),
    ...tokens,
  ];

  const errors = await validateToken(
    additionalData,
    updatedDataset,
    updatedTokens
  );

  errors.forEach(({ index: errorIndex, message }) => {
    if (isSetError) {
      setError(`additionalData.${errorIndex as number}.value`, {
        message,
        type: 'validate',
      });
    }

    isValid = false;
  });

  additionalData.forEach((token, idx) => {
    if (_isUndefined(token.executedValue)) {
      if (isSetError) {
        setError(`additionalData.${idx}.value`, {
          message: 'Executed Value cannot be undefined',
          type: 'validate',
        });
      }

      isValid = false;
    }
  });

  _forEach(duplicateRuleNames, (value, key) => {
    if (value.length > 1) {
      isValid = false;
      value.forEach((val) => {
        if (isSetError) {
          setError(`additionalData.${val}.name`, {
            message: 'Key name must be unique',
            type: 'validate',
          });
        }
      });
    }
  });

  return isValid;
};

const validateProperties = (
  data: DecisionTableFormStructure,
  customAttributes: Record<string, AttributeModel>,
  nodes: Record<string, DecisionTableNodesModel>,
  dataset: Record<string, Dataset>,
  setError?: UseFormSetError<any>
) => {
  let isTestingValid = true;

  const isSetError = typeof setError === 'function';

  data.properties.forEach((property, index: number) => {
    const propertyKey = getRequiredKey(property, ['id']);
    const node = nodes[propertyKey];

    if (
      _isNil(property[propertyKey].value) ||
      _isEmpty(property[propertyKey].value)
    ) {
      if (isSetError) {
        setError(`properties.${index}.${propertyKey}`, {
          message: 'Property must be filled',
          type: 'validate',
        });
      }

      isTestingValid = false;
    }

    let mappedValue =
      !_isUndefined(node.sourceType) && !_isNil(dataset[node.sourceType])
        ? getPropertyIfExists(
            JSON.parse(
              JSON.stringify(
                Object.keys(dataset[node.sourceType].attributes).reduce(
                  (acc, curr) => {
                    return {
                      ...acc,
                      [curr]:
                        dataset[node.sourceType ?? ''].attributes[`${curr}`]
                          .executedValue,
                    };
                  },
                  {}
                )
              )
            ) ?? {},
            node.attribute ?? ''
          )
        : undefined;

    // Assuming that if the sourceType is systemVar then attribute is available always.
    const attributeFound =
      node.sourceType === 'systemVar' || node.sourceType === 'custom'
        ? true
        : !_isNil(node.attribute) &&
          !_isEmpty(node.attribute) &&
          !_isNil(node.sourceType) &&
          !_isEmpty(node.sourceType) &&
          !_isNil(dataset[node.sourceType]) &&
          !_isUndefined(mappedValue);

    if (!attributeFound) {
      if (isSetError) {
        setError(`properties.${index}.${propertyKey}`, {
          message: 'Unable to find the attribute',
          type: 'validate',
        });
      }

      isTestingValid = false;
    }

    // Formatting values in case of Date and DateTime
    mappedValue = !_isNil(mappedValue)
      ? node.dataType === 'date'
        ? formatNectedDate(mappedValue, 'date')
        : node.dataType === 'dateTime'
        ? formatNectedDate(mappedValue, 'dateTime')
        : mappedValue
      : mappedValue;

    const dataTypeMatched =
      !_isNil(node.attribute) &&
      !_isEmpty(node.attribute) &&
      !_isNil(node.sourceType) &&
      !_isEmpty(node.sourceType) &&
      !_isNil(dataset[node.sourceType]) &&
      property[propertyKey]?.dataType ===
        (!_isNil(mappedValue) ? getDataTypeNected(mappedValue) : node.dataType);

    const hasIgnoredDatatype = datatypesToIgnoreOnValidation.includes(
      node.dataType ?? ''
    );

    if (attributeFound) {
      if (!dataTypeMatched && !hasIgnoredDatatype && isSetError) {
        setError(`properties.${index}.${propertyKey}`, {
          message: 'DataType of the field has changed',
          type: 'validate',
        });
      }
    }

    if (!attributeFound) {
      if (isSetError) {
        setError(`properties.${index}.${propertyKey}`, {
          message: 'Unable to find the attribute',
          type: 'validate',
        });
      }

      isTestingValid = false;
    }
  });

  return isTestingValid;
};

const validateResults = (
  data: DecisionTableFormStructure,
  setError?: UseFormSetError<any>
) => {
  let isTestingValid = true;

  const isSetError = typeof setError === 'function';

  const indexesByKeyNames: Record<string, number[]> = {};

  data.results.forEach((result, index: number) => {
    const resultKey = getRequiredKey(result, ['id']);

    if (
      _isNil(result[resultKey].keyName) ||
      _isEmpty(result[resultKey].keyName)
    ) {
      if (isSetError) {
        setError(`results.${index}.${resultKey}.keyName`, {
          message: 'Key name cannot be empty',
          type: 'validate',
        });
      }

      isTestingValid = false;
    } else if (!KEY_REGEX.test(result[resultKey].keyName)) {
      if (isSetError) {
        setError(`results.${index}.${resultKey}.keyName`, {
          message: 'Invalid key name',
          type: 'validate',
        });
      }

      isTestingValid = false;
    } else if (
      !_isNil(indexesByKeyNames[result[resultKey].keyName]) &&
      indexesByKeyNames[result[resultKey].keyName].length > 0
    ) {
      const updatedKeyIndexes = [
        ...indexesByKeyNames[result[resultKey].keyName],
        index,
      ];

      indexesByKeyNames[result[resultKey].keyName] = updatedKeyIndexes;

      updatedKeyIndexes.forEach((keyIndex) => {
        if (isSetError) {
          setError(`results.${keyIndex}.${resultKey}.keyName`, {
            message: 'Duplicate keyName',
            type: 'validate',
          });
        }
      });

      isTestingValid = false;
    }

    if (_isNil(indexesByKeyNames[result[resultKey].keyName])) {
      indexesByKeyNames[result[resultKey].keyName] = [index];
    } else {
      indexesByKeyNames[result[resultKey].keyName] = [
        ...indexesByKeyNames[result[resultKey].keyName],
        index,
      ];
    }
  });

  return isTestingValid;
};

const validateRows = async (
  data: DecisionTableFormStructure,
  nodes: Record<string, DecisionTableNodesModel>,
  dataset: Record<string, Dataset>,
  unfilteredDataSet: Record<string, Dataset>,
  tokens: string[],
  setError?: UseFormSetError<any>
) => {
  let isTestingValid = true;
  let updatedErrorConfig: Record<number, number[]> = {};
  const isSetError = typeof setError === 'function';

  let isValidConditions = true;
  let isValidOutput = true;

  for (let index = 0; index < data.rows.length; index++) {
    const row = data.rows[index];
    const rowKey = getRequiredKey(row, ['id']);

    const rowResults = row[rowKey].ruleResult;
    const rowConditions = row[rowKey].condition;

    // 0th index row
    const rowZero = data.rows[0];
    const rowKeyZero = getRequiredKey(rowZero, ['id']);

    const rowResultsZero = rowZero[rowKeyZero].ruleResult;

    const resultAddDataModels: ResultAddDataModel[] = rowResults.map(
      (result, resultIndex: number) => {
        const resultKey = getRequiredKey(result, ['id']);
        const resultHeaderKey = getRequiredKey(data.results[resultIndex], [
          'id',
        ]);

        return {
          keyName: data.results[resultIndex][resultHeaderKey].keyName,
          dataType: result[resultKey].dataType,
          value: result[resultKey].value as string,
          returnType: result[resultKey].returnType,
          executedValue: result[resultKey].executedValue,
          attribute: result[resultKey].attribute ?? undefined,
          source: result[resultKey].source ?? undefined,
        };
      }
    );

    const resultAddDataModelsZero: ResultAddDataModel[] = rowResultsZero.map(
      (result, resultIndex: number) => {
        const resultKey = getRequiredKey(result, ['id']);
        const resultHeaderKey = getRequiredKey(data.results[resultIndex], [
          'id',
        ]);

        return {
          keyName: data.results[resultIndex][resultHeaderKey].keyName,
          dataType: result[resultKey].dataType,
          value: result[resultKey].value as string,
          returnType: result[resultKey].returnType,
          executedValue: result[resultKey].executedValue,
        };
      }
    );

    const outputDataAttributes: Record<string, Attributes> = {};

    resultAddDataModelsZero.forEach((field) => {
      outputDataAttributes[field.keyName] = {
        name: field.keyName,
        dataType: field.dataType as DataTypes,
        // eslint-disable-next-line no-extra-boolean-cast
        executedValue: !!field.executedValue
          ? convertCaSampleValues(
              field.returnType ? field.returnType : field.dataType,
              field.executedValue
            )
          : convertCaSampleValues(
              field.returnType ? field.returnType : field.dataType,
              field.value
            ),
      };
    });

    const updatedDataset = {
      ...dataset,
      outputData: {
        attributes: outputDataAttributes,
        name: 'Output Data Attributes',
        id: 'output_data_attributes',
      },
    };

    if (rowResults.length > 0) {
      for (
        let resultIndex = 0;
        resultIndex < rowResults.length;
        resultIndex++
      ) {
        const result = rowResults[resultIndex];
        const resultKey = getRequiredKey(result, ['id']);

        const resultValue = result[resultKey].value;

        if (result[resultKey].dataType === 'numeric' && resultValue === '') {
          if (isSetError) {
            setError(
              `rows.${index}.${rowKey}.ruleResult.${resultIndex}.${resultKey}.value`,
              {
                message: 'Value cannot be empty',
                type: 'validate',
              }
            );
          }

          updatedErrorConfig = {
            ...updatedErrorConfig,
            [index]: !_isUndefined(updatedErrorConfig[index])
              ? [
                  ...updatedErrorConfig[index],
                  resultIndex + rowConditions.length,
                ]
              : [resultIndex + rowConditions.length],
          };

          isTestingValid = false;
          isValidOutput = false;
        } else if (
          result[resultKey].dataType === 'json' &&
          typeof resultValue === 'string'
        ) {
          const { isValid: isJsonValid, message: jsonValidationMsg } =
            await validateTokensBasedOnEditor({
              dataset: updatedDataset,
              query: resultValue,
              editorType: 'json',
            });

          if (!isJsonValid) {
            if (isSetError) {
              setError(
                `rows.${index}.${rowKey}.ruleResult.${resultIndex}.${resultKey}.value`,
                {
                  message: jsonValidationMsg,
                  type: 'validate',
                }
              );
            }

            updatedErrorConfig = {
              ...updatedErrorConfig,
              [index]: !_isUndefined(updatedErrorConfig[index])
                ? [
                    ...updatedErrorConfig[index],
                    resultIndex + rowConditions.length,
                  ]
                : [resultIndex + rowConditions.length],
            };

            isTestingValid = false;
            isValidOutput = false;
          }
        } else if (
          result[resultKey].dataType === 'jsFormula' &&
          typeof resultValue === 'string'
        ) {
          const { isValid: isValidJS, message: jsValidationMsg } =
            await validateTokensBasedOnEditor({
              dataset: updatedDataset,
              query: resultValue,
              editorType: 'jsFormula',
            });

          if (!isValidJS || _isEmpty(resultValue)) {
            if (isSetError) {
              setError(
                `rows.${index}.${rowKey}.ruleResult.${resultIndex}.${resultKey}.value`,
                {
                  message: jsValidationMsg,
                  type: 'validate',
                }
              );
            }

            updatedErrorConfig = {
              ...updatedErrorConfig,
              [index]: !_isUndefined(updatedErrorConfig[index])
                ? [
                    ...updatedErrorConfig[index],
                    resultIndex + rowConditions.length,
                  ]
                : [resultIndex + rowConditions.length],
            };
            isTestingValid = false;
            isValidOutput = false;
          } else if (
            (_isEmpty(result[resultKey].returnType) &&
              result[resultKey].returnType !== null) ||
            result[resultKey].returnType === 'undefined'
          ) {
            if (isSetError) {
              setError(
                `rows.${index}.${rowKey}.ruleResult.${resultIndex}.${resultKey}.value`,
                {
                  message: 'Node returns an improper value',
                  type: 'validate',
                }
              );
            }

            updatedErrorConfig = {
              ...updatedErrorConfig,
              [index]: !_isUndefined(updatedErrorConfig[index])
                ? [
                    ...updatedErrorConfig[index],
                    resultIndex + rowConditions.length,
                  ]
                : [resultIndex + rowConditions.length],
            };

            isTestingValid = false;
            isValidOutput = false;
          }
        }

        const validated = validateOutput(
          `rows.${index}.${rowKey}.ruleResult.${resultIndex}.${resultKey}.value`,
          result[resultKey].value,
          result[resultKey].dataType,
          result[resultKey].source,
          result[resultKey].attribute,
          tokens,
          setError,
          updatedDataset
        );

        if (!validated) {
          updatedErrorConfig = {
            ...updatedErrorConfig,
            [index]: !_isUndefined(updatedErrorConfig[index])
              ? [
                  ...updatedErrorConfig[index],
                  resultIndex + rowConditions.length,
                ]
              : [resultIndex + rowConditions.length],
          };

          isTestingValid = false;
          isValidOutput = false;
        }
      }
    }

    const tokenErrors = await validateToken(
      resultAddDataModels,
      updatedDataset,
      tokens
    );

    tokenErrors.forEach(({ index: errorIndex, message }) => {
      const resultKey = getRequiredKey(rowResults[errorIndex], ['id']);

      if (isSetError) {
        setError(
          `rows.${index}.${rowKey}.ruleResult.${
            errorIndex as number
          }.${resultKey}.value`,
          {
            message,
            type: 'validate',
          }
        );
      }

      updatedErrorConfig = {
        ...updatedErrorConfig,
        [index]: !_isUndefined(updatedErrorConfig[index])
          ? [
              ...updatedErrorConfig[index],
              (errorIndex as number) + rowConditions.length,
            ]
          : [(errorIndex as number) + rowConditions.length],
      };

      isTestingValid = false;
      isValidOutput = false;
    });

    resultAddDataModels.forEach((result, resIndex) => {
      if (
        result.dataType === 'jsFormula' &&
        _isUndefined(result.executedValue)
      ) {
        const resultKey = getRequiredKey(rowResults[resIndex], ['id']);

        if (isSetError) {
          setError(
            `rows.${index}.${rowKey}.ruleResult.${resIndex}.${resultKey}.value`,
            {
              message: 'Incorrect executed value',
              type: 'validate',
            }
          );
        }

        updatedErrorConfig = {
          ...updatedErrorConfig,
          [index]: !_isUndefined(updatedErrorConfig[index])
            ? [...updatedErrorConfig[index], resIndex + rowConditions.length]
            : [resIndex + rowConditions.length],
        };

        isTestingValid = false;
        isValidOutput = false;
      }
    });

    if (rowConditions.length > 0) {
      for (
        let conditionIndex = 0;
        conditionIndex < rowConditions.length;
        conditionIndex++
      ) {
        const condition = rowConditions[conditionIndex];
        const conditionKey = getRequiredKey(condition, ['id', 'rhs']);
        const operator = nodes[conditionKey].operator;

        if (nodes[conditionKey].nodeType === 'condition') {
          if (_isEmpty(operator)) {
            if (isSetError) {
              setError(
                `rows.${index}.${rowKey}.condition.${conditionIndex}.${conditionKey}`,
                {
                  message: 'Operator cannot be empty',
                  type: 'validate',
                }
              );
            }

            updatedErrorConfig = {
              ...updatedErrorConfig,
              [index]: !_isUndefined(updatedErrorConfig[index])
                ? [...updatedErrorConfig[index], conditionIndex]
                : [conditionIndex],
            };

            isValidConditions = false;

            isTestingValid = false;
          }

          const conditionValidationResult = validateDecesionRuleCondition(
            condition?.rhs,
            operator
          );

          condition?.rhs?.forEach(
            (rhsItem: PropertiesNodeStructure, rhsIndex: number) => {
              const rhsKey = getRequiredKey(rhsItem, ['id']);

              const node = nodes[rhsKey];

              // Following condition checks that the value of second input
              // of RHS should be greater than the first input of RHS.
              // This condition works only for numeric, date and dateTime.
              if (nodes[rhsKey]?.nodeType !== 'constant') {
                const parentNode = nodes[nodes[rhsKey].parent];

                let mappedValue =
                  !_isUndefined(node.sourceType) &&
                  !_isNil(dataset[node.sourceType])
                    ? getPropertyIfExists(
                        JSON.parse(
                          JSON.stringify(
                            Object.keys(
                              dataset[node.sourceType].attributes
                            ).reduce((acc, curr) => {
                              return {
                                ...acc,
                                [curr]:
                                  dataset[node.sourceType ?? ''].attributes[
                                    `${curr}`
                                  ].executedValue,
                              };
                            }, {})
                          )
                        ) ?? {},
                        node.attribute ?? ''
                      )
                    : undefined;

                const attributeFound =
                  node.sourceType === 'systemVar' ||
                  node.sourceType === 'custom'
                    ? true
                    : !_isNil(node.attribute) &&
                      !_isEmpty(node.attribute) &&
                      !_isNil(node.sourceType) &&
                      !_isEmpty(node.sourceType) &&
                      !_isNil(dataset[node.sourceType]) &&
                      !_isUndefined(mappedValue);

                // Formatting values in case of Date and DateTime
                mappedValue = !_isNil(mappedValue)
                  ? node.dataType === 'date'
                    ? formatNectedDate(mappedValue, 'date')
                    : node.dataType === 'dateTime'
                    ? formatNectedDate(mappedValue, 'dateTime')
                    : mappedValue
                  : mappedValue;

                const dataTypeMatched =
                  !_isNil(node.attribute) &&
                  !_isEmpty(node.attribute) &&
                  !_isNil(node.sourceType) &&
                  !_isEmpty(node.sourceType) &&
                  !_isNil(dataset[node.sourceType]) &&
                  parentNode.dataType ===
                    (!_isNil(mappedValue)
                      ? getDataTypeNected(mappedValue)
                      : node.dataType);

                const hasIgnoredDatatype =
                  datatypesToIgnoreOnValidation.includes(node.dataType ?? '');

                if (!attributeFound && isSetError) {
                  setError(
                    `rows.${index}.${rowKey}.condition.${conditionIndex}.rhs.${
                      condition?.rhs?.length - 1
                    }.${rhsKey}`,
                    {
                      message: 'Unable to find attribute',
                    }
                  );
                }

                if (
                  !hasIgnoredDatatype &&
                  !validateRhsParamType(
                    parentNode.dataType ?? '',
                    parentNode.operator ?? '',
                    nodes[rhsKey].dataType ?? '',
                    dataTypeMatched
                  ) &&
                  isSetError
                ) {
                  setError(
                    `rows.${index}.${rowKey}.condition.${conditionIndex}.rhs.${
                      condition?.rhs?.length - 1
                    }.${rhsKey}`,
                    {
                      message: 'DataType of the field has changed',
                    }
                  );

                  isTestingValid = false;
                  isValidConditions = false;
                }
              }

              if (
                !_isNil(conditionValidationResult) &&
                condition?.rhs?.length - 1 === rhsIndex
              ) {
                if (isSetError) {
                  setError(
                    `rows.${index}.${rowKey}.condition.${conditionIndex}.rhs.${
                      condition?.rhs?.length - 1
                    }.${rhsKey}`,
                    conditionValidationResult
                  );
                }

                isTestingValid = false;
                isValidConditions = false;
              }

              if (
                nodes[rhsKey].dataType === 'numeric' &&
                nodes[rhsKey].nodeType === 'constant'
              ) {
                if (
                  !isValueNumeric(nodes[rhsKey].value as any) &&
                  !['in', 'nin'].includes(operator ?? '')
                ) {
                  if (isSetError) {
                    setError(
                      `rows.${index}.${rowKey}.condition.${conditionIndex}.rhs.${rhsIndex}.${rhsKey}`,
                      {
                        message: 'Value must be a number',
                        type: 'validate',
                      }
                    );
                  }

                  isTestingValid = false;
                  isValidConditions = false;
                } else if (
                  ['in', 'nin'].includes(operator ?? '') &&
                  !Array.isArray(nodes[rhsKey].value)
                ) {
                  if (isSetError) {
                    setError(
                      `rows.${index}.${rowKey}.condition.${conditionIndex}.rhs.${rhsIndex}.${rhsKey}`,
                      {
                        message: 'Value must be a list',
                        type: 'validate',
                      }
                    );
                  }

                  isTestingValid = false;
                  isValidConditions = false;
                }
              }

              if (
                nodes[rhsKey].dataType === 'dateTime' &&
                nodes[rhsKey].nodeType === 'constant'
              ) {
                if (!isValidDateTime(rhsItem[rhsKey].value)) {
                  if (isSetError) {
                    setError(
                      `rows.${index}.${rowKey}.condition.${conditionIndex}.rhs.${rhsIndex}.${rhsKey}`,
                      {
                        message: `Value must be of format ${DATE_TIME_FORMAT} format`,
                        type: 'validate',
                      }
                    );
                  }

                  isTestingValid = false;
                  isValidConditions = false;
                } else if (
                  ['in', 'nin'].includes(operator ?? '') &&
                  !Array.isArray(nodes[rhsKey].value)
                ) {
                  if (isSetError) {
                    setError(
                      `rows.${index}.${rowKey}.condition.${conditionIndex}.rhs.${rhsIndex}.${rhsKey}`,
                      {
                        message: 'Value must be a list',
                        type: 'validate',
                      }
                    );
                  }

                  isTestingValid = false;
                  isValidConditions = false;
                }
              }

              if (
                nodes[rhsKey].dataType === 'date' &&
                nodes[rhsKey].nodeType === 'constant'
              ) {
                if (!isValidDate(rhsItem[rhsKey].value)) {
                  if (isSetError) {
                    setError(
                      `rows.${index}.${rowKey}.condition.${conditionIndex}.rhs.${rhsIndex}.${rhsKey}`,
                      {
                        message: `Value must be of format ${DATE_FORMAT}`,
                        type: 'validate',
                      }
                    );
                  }

                  isTestingValid = false;
                  isValidConditions = false;
                }
              }
            }
          );
        } else if (nodes[conditionKey].nodeType === 'jsCondition') {
          const { isValid, message } = await validateTokensBasedOnEditor({
            dataset: updatedDataset,
            query: nodes[conditionKey].query ?? '',
            editorType: 'jsFormula',
          });

          if (
            _isNil(nodes[conditionKey].query) ||
            _isEmpty(nodes[conditionKey].query) ||
            !isValid
          ) {
            if (isSetError && !isValid) {
              setError(
                `rows.${index}.${rowKey}.condition.${conditionIndex}.${conditionKey}`,
                {
                  message: message ?? 'Incorrect Formula syntax',
                  type: 'validate',
                }
              );
            }

            isTestingValid = false;
            isValidConditions = false;
          }
        } else if (nodes[conditionKey].nodeType === 'excelCondition') {
          const { isValid, message } = await validateTokensBasedOnEditor({
            dataset: updatedDataset,
            query: nodes[conditionKey].query ?? '',
            editorType: 'jsFormula',
          });

          if (
            _isNil(nodes[conditionKey].query) ||
            _isEmpty(nodes[conditionKey].query) ||
            !isValid
          ) {
            if (isSetError && !isValid) {
              setError(
                `rows.${index}.${rowKey}.condition.${conditionIndex}.${conditionKey}`,
                {
                  message: message ?? 'Incorrect Formula syntax',
                  type: 'validate',
                }
              );
            }

            isTestingValid = false;
            isValidConditions = false;
          }
        }
      }
    }
  }

  return {
    isTestingValid,
    updatedErrorConfig,
    isValidConditions,
    isValidOutput,
  };
};

function validateDecesionRuleCondition(
  rhs: DecisionTableRowRhsNode,
  operator?: string
) {
  const extactedPayload = rhs.map((item) => {
    const { dataType, value, key } = Object.values(item)[0];

    return { dataType, value, key };
  });

  if (
    !_isNil(extactedPayload) &&
    !_isEmpty(extactedPayload) &&
    extactedPayload[0].dataType === 'list' &&
    _isEmpty(extactedPayload[0].key) &&
    !isArrayAsInputValid(extactedPayload[0].value)
  ) {
    return {
      message: 'Error in parsing input value',
      type: 'validate',
    };
  }

  if (_isNil(rhs) || _isNil(operator) || !['bet', 'nbet'].includes(operator)) {
    return null;
  }
  const conditionsInputData = rhs.reduce((acc, rhs) => {
    const [key, value] = Object.entries(rhs)[0];
    acc[key] = value;

    return acc;
  }, {});

  const [firstInputData, secondInputData] = Object.values(conditionsInputData);

  const { value: firstInputValue, dataType: firstInputDataType } =
    firstInputData;
  const { value: secondInputValue, dataType: secondInputDataType } =
    secondInputData;

  if (firstInputDataType === 'numeric' && secondInputDataType === 'numeric') {
    if (parseFloat(firstInputValue) >= parseFloat(secondInputValue)) {
      return {
        message: 'Second input must be greater than first',
        type: 'validate',
      };
    }
  } else if (
    _includes(['date', 'dateType'], firstInputDataType) &&
    _includes(['date', 'dateType'], secondInputDataType)
  ) {
    const [firstInputTime, secondInputTime] = [
      firstInputValue,
      secondInputValue,
    ].map((date) => parse(date, DATE_FORMAT, new Date()).getTime());

    if (firstInputTime >= secondInputTime) {
      return {
        message: 'Second input must be greater than first.',
        type: 'validate',
      };
    }
  }

  return null;
}

export function validateDecisionTableData(
  data: DecisionTableFormStructure,
  setError?: UseFormSetError<any>
) {
  const isSetError = typeof setError === 'function';

  try {
    ruleEnviornmentConfigSchema.parse(data.productionConfig);
  } catch (error: unknown) {
    if (error instanceof ZodError) {
      error.issues.forEach((validationError) => {
        const errorPath = validationError
          .path[0] as keyof ProductionConfigModel;

        if (isSetError) {
          setError(`productionConfig.${errorPath}`, {
            message: validationError.message,
            type: 'validate',
          });
        }
      });
    }

    return false;
  }

  return true;
}

export const getDecisionTableOutputsWithoutExecutedValue = (
  decisionTableRows: Array<Record<string, DecisionTableRow>>
) => {
  return decisionTableRows.reduce((acc: DecisionTableRowResult[], row) => {
    const ruleId = getRequiredKey(row, ['id']);

    const firstRow = row[ruleId];
    const result = firstRow.ruleResult.reduce(
      (a: DecisionTableRowResult[], r) => {
        const resultId = getRequiredKey(r, ['id']);

        if (
          (r[resultId].dataType === 'jsFormula' ||
            r[resultId].dataType === 'json') &&
          (r[resultId].executedValue === '' ||
            r[resultId].executedValue === 'undefined' ||
            _isNil(r[resultId].executedValue))
        ) {
          return [...a, r];
        }

        return a;
      },
      []
    );

    return [...acc, ...result];
  }, []);
};

export const getSuggestionsOnRuleLoad = (
  data: NectedSuggestionModel[],
  decisionTableRows: Array<Record<string, DecisionTableRow>> = [],
  dtResults: any[] = [],
  additionalData: AdditionalActionFormData[] = [],
  dataParams: ResultAddDataModel[] = []
) => {
  const suggestions: NectedSuggestionModel[] = [...data];

  if (!_isNil(decisionTableRows[0])) {
    const ruleId = getRequiredKey(decisionTableRows[0], ['id']);

    const firstRow = decisionTableRows[0][ruleId];

    firstRow.ruleResult.forEach((r, i) => {
      const resultId = getRequiredKey(r, ['id']);
      const resultKeyId = getRequiredKey(dtResults[i], ['id']);

      if (
        (r[resultId].dataType === 'jsFormula' ||
          r[resultId].dataType === 'json') &&
        r[resultId].executedValue === ''
      ) {
        suggestions.push({
          name: `<<outputData.${dtResults[i][resultKeyId].keyName as string}>>`,
          value: `<<outputData.${
            dtResults[i][resultKeyId].keyName as string
          }>>`,
          // eslint-disable-next-line
          meta: r[resultId].returnType || r[resultId].dataType,
          score: TokenScores.outputData,
          // eslint-disable-next-line
          executedValue: dtResults[i][resultKeyId].executedValue || null,
        });

        suggestions.push({
          name: `<<outputDataList.${
            dtResults[i][resultKeyId].keyName as string
          }>>`,
          value: `<<outputDataList.${
            dtResults[i][resultKeyId].keyName as string
          }>>`,
          // eslint-disable-next-line
          meta: `array[${r[resultId].returnType || r[resultId].dataType}]`,
          score: TokenScores.outputDataList,
          // eslint-disable-next-line
          executedValue: [dtResults[i][resultKeyId].executedValue || null],
        });
      } else if (
        r[resultId].dataType === 'string' ||
        r[resultId].dataType === 'date'
      ) {
        suggestions.push({
          name: `"<<outputData.${
            dtResults[i][resultKeyId].keyName as string
          }>>"`,
          value: `"<<outputData.${
            dtResults[i][resultKeyId].keyName as string
          }>>"`,
          meta: r[resultId].dataType,
          score: TokenScores.outputData,
        });

        suggestions.push({
          name: `"<<outputDataList.${
            dtResults[i][resultKeyId].keyName as string
          }>>"`,
          value: `"<<outputDataList.${
            dtResults[i][resultKeyId].keyName as string
          }>>"`,
          meta: `array[${r[resultId].dataType}]`,
          score: TokenScores.outputDataList,
        });
      } else {
        suggestions.push({
          name: `<<outputData.${dtResults[i][resultKeyId].keyName as string}>>`,
          value: `<<outputData.${
            dtResults[i][resultKeyId].keyName as string
          }>>`,
          meta: r[resultId].dataType,
          score: TokenScores.outputData,
        });

        suggestions.push({
          name: `<<outputDataList.${
            dtResults[i][resultKeyId].keyName as string
          }>>`,
          value: `<<outputDataList.${
            dtResults[i][resultKeyId].keyName as string
          }>>`,
          meta: `array[${r[resultId].dataType}]`,
          score: TokenScores.outputDataList,
        });
      }
    });
  }

  additionalData.forEach((aData) => {
    suggestions.push({
      name: `<<additionalData.${aData.name}>>`,
      value: `<<additionalData.${aData.name}>>`,
      // eslint-disable-next-line
      meta: !!aData.returnType ? aData.returnType : 'unknown',
      score: TokenScores.additionalData,
      // eslint-disable-next-line
      executedValue: !!aData.executedValue ? aData.executedValue : null,
    });
  });

  dataParams.forEach((data) => {
    if (data.dataType === 'string' || data.dataType === 'date') {
      suggestions.push({
        name: `"<<outputData.${data.keyName}>>"`,
        value: `"<<outputData.${data.keyName}>>"`,
        meta: data.dataType,
        score: TokenScores.outputData,
      });
    } else if (data.dataType === 'jsFormula' || data.dataType === 'json') {
      suggestions.push({
        name: `<<outputData.${data.keyName}>>`,
        value: `<<outputData.${data.keyName}>>`,
        // eslint-disable-next-line
        meta: !!data.returnType ? data.returnType : 'unknown',
        score: TokenScores.outputData,
        // eslint-disable-next-line
        executedValue:
          !_isNil(data.executedValue) &&
          !_isEmpty(data.executedValue) &&
          data.executedValue !== 'undefined'
            ? data.executedValue
            : null,
      });
    } else {
      suggestions.push({
        name: `<<outputData.${data.keyName}>>`,
        value: `<<outputData.${data.keyName}>>`,
        meta: data.dataType,
        score: TokenScores.outputData,
      });
    }
  });

  return suggestions;
};

export const getAdditionalDataElementsWithoutExecutedValue = (
  additional: AdditionalActionFormData[]
) => {
  const elements: number[] = [];

  additional.forEach((aData, index) => {
    // eslint-disable-next-line
    if (!(aData.executedValue && aData.executedValue !== 'undefined')) {
      elements.push(index);
    }
  });

  return elements;
};

export const getDtOutputRows = (
  results: DecisionTableResultRow[],
  rows: RowStructure[],
  dataSetVariables: Record<string, Dataset>
) => {
  const headers = results.map((r) => {
    const keyName = getRequiredKey(r, ['id']);

    return {
      name: r[keyName].keyName,
      dataType: r[keyName].dataType,
    };
  });

  const finalRows = rows.map((row) => {
    const keyName = getRequiredKey(row, ['id']);
    const val: Record<string, any> = {};

    row[keyName].ruleResult?.forEach((ruleResult, i) => {
      const resKey = getRequiredKey(ruleResult, ['id']);
      const source = ruleResult[resKey].source;
      const attribute = ruleResult[resKey].attribute;

      const mappedValue =
        !_isNil(source) &&
        !_isNil(attribute) &&
        !_isNil(dataSetVariables[source])
          ? getPropertyIfExists(
              JSON.parse(
                JSON.stringify(
                  Object.keys(dataSetVariables[source].attributes).reduce(
                    (acc, curr) => {
                      return {
                        ...acc,
                        [curr]:
                          dataSetVariables[source].attributes[`${curr}`]
                            .executedValue,
                      };
                    },
                    {}
                  )
                )
              ) ?? {},
              attribute
            )
          : convertCaSampleValues(
              ruleResult[resKey].dataType,
              ruleResult[resKey].value
            );

      val[headers[i].name] = [
        'json',
        'jsFormula',
        'excelFormula',
        'list',
      ].includes(ruleResult[resKey].dataType)
        ? ruleResult[resKey].executedValue
        : mappedValue;
    });

    return val;
  });

  return finalRows;
};

export const getOperatorByRow = (
  rows: Array<Record<string, any>>,
  nodes: Record<string, DecisionTableNodesModel>,
  currentKey: string,
  condIndex: number,
  rowIndex: number = 0
) => {
  const requiredKey = getRequiredKey(rows[rowIndex], ['id']);

  const condKey = getRequiredKey(
    rows[rowIndex][requiredKey].condition[condIndex],
    ['id', 'rhs']
  );

  return {
    operator: nodes[nodes[condKey]?.parent]?.operator === 'or' ? 'Or' : 'And',
    groupId: nodes[condKey]?.parent,
    rowKey: requiredKey,
    rowOperator: nodes[requiredKey]?.operator === 'or' ? 'Or' : 'And',
  };
};

export const getIfHasParentGroup = (
  rows: Array<Record<string, any>>,
  nodes: Record<string, DecisionTableNodesModel>,
  condIndex: number,
  rowIndex: number = 0
) => {
  const requiredKey = getRequiredKey(rows[rowIndex] ?? {}, ['id']);

  const condKey = getRequiredKey(
    rows[rowIndex][requiredKey].condition[condIndex] ?? {},
    ['id', 'rhs']
  );

  return {
    parent: nodes[nodes[condKey]?.parent]?.parent,
    hasParent:
      !_isNil(nodes[nodes[condKey]?.parent]?.parent) &&
      !_isEmpty(nodes[nodes[condKey]?.parent]?.parent),
    group: nodes[condKey]?.parent,
    operator: nodes[nodes[condKey]?.parent]?.operator ?? 'and',
  };
};

export const checkIfSiblingsHaveSameParent = (
  rows: Array<Record<string, any>>,
  condIndex: number,
  nodes: Record<string, DecisionTableNodesModel>
): HasSameParentType => {
  const requiredKey = getRequiredKey(rows[0], ['id']);

  const cond = rows[0][requiredKey].condition;

  const condKey = getRequiredKey(cond[condIndex], ['id', 'rhs']);

  let count = 0;
  let countLeft = 0;
  let siblingIndex = 0;
  const indexes: number[] = [];

  for (let i = condIndex; i < cond.length; i++) {
    const condKeyRight = getRequiredKey(cond[i + 1] ?? {}, ['id', 'rhs']);

    if (
      nodes[condKeyRight]?.parent === nodes[condKey]?.parent &&
      !!nodes[nodes[condKey]?.parent]?.parent
    ) {
      count = count + 1;
      indexes.push(i + 1);
    }
  }

  for (let i = condIndex; i > 0; i--) {
    const condKeyLeft = getRequiredKey(cond[i - 1] ?? {}, ['id', 'rhs']);

    if (
      nodes[condKeyLeft]?.parent === nodes[condKey]?.parent &&
      !!nodes[nodes[condKey]?.parent]?.parent
    ) {
      countLeft = countLeft + 1;
      indexes.push(i - 1);
    } else if (siblingIndex !== 0) {
      siblingIndex = i;
    }
  }

  const condKeyLeft = getRequiredKey(cond[condIndex - 1] ?? {}, ['id', 'rhs']);
  const condKeyRight = getRequiredKey(cond[condIndex + 1] ?? {}, ['id', 'rhs']);

  indexes.push(condIndex);

  return {
    hasSame:
      !!nodes[nodes[condKey]?.parent]?.parent &&
      nodes[condKeyLeft]?.parent === nodes[condKey]?.parent,
    isFirst:
      !!nodes[nodes[condKey]?.parent]?.parent &&
      nodes[condKeyLeft]?.parent !== nodes[condKey]?.parent,
    count,
    total: count + countLeft + 1,
    indexes: Array.from(new Set(indexes)),
    isLast:
      !!nodes[nodes[condKey]?.parent]?.parent &&
      nodes[condKeyRight]?.parent !== nodes[condKey]?.parent,
    siblingIndex,
  };
};
