import dagre from 'dagre';
import { useAtom } from 'jotai';
import _ from 'lodash';
import _isNil from 'lodash/isNil';
import { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import ReactFlow, {
  Background,
  BackgroundVariant,
  Controls,
  Edge,
  EdgeChange,
  Node,
  NodeChange,
  Position,
} from 'reactflow';
import 'reactflow/dist/style.css';

import {
  oldWorkflowNodesAtom,
  workflowEdgesAtom,
  workflowNodesAtom,
} from '../../atoms/atoms';
import {
  WorkflowEdgeType,
  WorkflowNodeType,
} from '../../hooks/useOpenWorkflow';
import { filterWorkflowEdges } from '../../utils/common';
import { SmoothEdge } from '../Edges/SmoothEdge/SmoothEdge';
import { AddNode } from '../Nodes/AddNode/AddNode';
import { CodeNode } from '../Nodes/CodeNode';
import { ConnectorNode } from '../Nodes/ConnectorNode';
import { DelayNode } from '../Nodes/DelayNode';
import { ResponseNode } from '../Nodes/ResponseNode';
import { RuleNode } from '../Nodes/RuleNode';
import { SetVariableNode } from '../Nodes/SetVariableNode';
import { SwitchNode } from '../Nodes/SwitchNode';
import { TriggerNode } from '../Nodes/TriggerNode/TriggerNode';
import { WorkflowNode } from '../Nodes/WorkflowNode';

type WorkflowEditorProps = {
  edges: Array<Edge<any>>;
  nodes: Array<Node<any, string | undefined>>;
  onEdgesChange: (changes: EdgeChange[]) => void;
  onNodesChange: (changes: NodeChange[]) => void;
  onConnect: (params: any) => void;
  setReactFlowInstance: Dispatch<SetStateAction<any>>;
  onNodeDragStop: (event: any, node: any) => void;
  setEdges: (edges: any) => void;
  setNodes: (nodes: any) => void;
  updateNodesWithMethods: (
    localNodes: WorkflowNodeType[]
  ) => WorkflowNodeType[];
  showEditor: boolean;
};

const nodeTypes = {
  addNode: AddNode,
  trigger: TriggerNode,
  rule: RuleNode,
  dbNode: ConnectorNode,
  codeNode: CodeNode,
  delayNode: DelayNode,
  setVarNode: SetVariableNode,
  workflowNode: WorkflowNode,
  responseNode: ResponseNode,
  restApiNode: ConnectorNode,
  gSheetNode: ConnectorNode,
  switchNode: SwitchNode,
};

export function WorkflowEditor({
  edges,
  nodes,
  onEdgesChange,
  onNodesChange,
  onConnect,
  setReactFlowInstance,
  onNodeDragStop,
  setEdges,
  setNodes,
  updateNodesWithMethods,
}: WorkflowEditorProps) {
  const snapGrid: [number, number] = [20, 20];
  const reactFlowContainer = useRef<HTMLDivElement>(null);
  const [oldNodes, setOldWorkflowNodes] = useAtom(oldWorkflowNodesAtom);
  const defaultViewport = { y: 0, x: 0, zoom: 1 };
  const connectionLineStyle = { stroke: 'blueviolet' };
  const [, setWorkflowNodes] = useAtom(workflowNodesAtom);
  const [, setWorkflowEdges] = useAtom(workflowEdgesAtom);

  const nodeWidth = 180;

  const getNodeHeightByType = (type: string) => {
    if (type === 'addNode') {
      return 25;
    }

    return 80;
  };

  const getLayoutedElements = (
    updatedNodes: WorkflowNodeType[],
    edges: WorkflowEdgeType[],
    direction = 'TB'
  ) => {
    let viewportWidth = window.innerWidth - 84;

    if (!_isNil(reactFlowContainer.current)) {
      const { width } = reactFlowContainer.current.getBoundingClientRect();
      viewportWidth = width;
    }

    const dagreGraph = new dagre.graphlib.Graph();
    dagreGraph.setDefaultEdgeLabel(() => ({}));

    const isHorizontal = direction === 'LR';
    dagreGraph.setGraph({ rankdir: direction });

    updatedNodes.forEach((node) => {
      dagreGraph.setNode(node.id, { width: nodeWidth, height: node.height });
    });

    edges.forEach((edge) => {
      dagreGraph.setEdge(edge.source, edge.target, {
        edgeType: edge.data.edgeType,
      });
    });

    dagre.layout(dagreGraph);

    // Calculate the graph width and height
    let graphWidth = 0;
    let graphHeight = 0;
    updatedNodes.forEach((node) => {
      const nodeWithPosition = dagreGraph.node(node.id);
      graphWidth = Math.max(graphWidth, nodeWithPosition.x + nodeWidth / 2);
      graphHeight = Math.max(
        graphHeight,
        nodeWithPosition.y + nodeWithPosition.height / 2
      );
    });

    // Calculate offsets to center the graph
    const xOffset = (viewportWidth - graphWidth) / 2;

    updatedNodes = updatedNodes.map((node) => {
      const nodeWithPosition = dagreGraph.node(node.id);
      node.targetPosition = isHorizontal
        ? ('left' as Position)
        : ('top' as Position);
      node.sourcePosition = isHorizontal
        ? ('right' as Position)
        : ('bottom' as Position);

      // Adjust node positions to center the graph
      node.position = {
        x: nodeWithPosition.x - nodeWidth / 2 + xOffset,
        y: nodeWithPosition.y - nodeWithPosition.height / 2,
      };

      return node;
    });

    return { nodes: updatedNodes, edges };
  };

  const onLayout = (
    updatedNodes: WorkflowNodeType[],
    updatedEdges: WorkflowEdgeType[]
  ) => {
    const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
      updatedNodes,
      updatedEdges.filter((e) => e.target !== '')
    );

    // only render nodes when the layout has been done by dagre.
    const filteredNodes = layoutedNodes;

    setOldWorkflowNodes([...filteredNodes]);
    setWorkflowEdges([...layoutedEdges]);
    setWorkflowNodes([...filteredNodes]);

    setNodes([...filteredNodes]);
    setEdges([...layoutedEdges]);
  };

  useEffect(() => {
    const updatedNodes = nodes.map((node) => {
      const updatedNode = { ...node };
      updatedNode.width = 80;
      updatedNode.height = getNodeHeightByType(updatedNode.type as string);
      delete updatedNode.selected;

      return updatedNode;
    });

    if (!_.isEqual([...oldNodes], [...updatedNodes]) && nodes.length > 0) {
      onLayout(updatedNodes, filterWorkflowEdges(edges, updatedNodes));
    }
  }, [JSON.stringify(nodes), JSON.stringify(edges)]);

  const onError = (msgId: string, msg: any) => {
    if (msgId === '002') {
      return;
    }
    // eslint-disable-next-line no-console
    console.warn(msg);
  };

  return (
    <div ref={reactFlowContainer} style={{ width: '100%', height: '100%' }}>
      <ReactFlow
        nodes={updateNodesWithMethods(nodes)}
        edges={filterWorkflowEdges(edges, nodes)}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onError={onError}
        onConnect={onConnect}
        style={{ background: '#f8f8f8' }}
        nodeTypes={nodeTypes}
        connectionLineStyle={connectionLineStyle}
        snapToGrid
        onInit={setReactFlowInstance}
        snapGrid={snapGrid}
        defaultViewport={defaultViewport}
        attributionPosition="bottom-left"
        onNodeDragStart={onNodeDragStop}
        edgeTypes={{
          smoothEdge: (props) => <SmoothEdge {...props} />,
        }}
        deleteKeyCode={null}
      >
        <Background
          variant={BackgroundVariant.Dots}
          gap={12}
          size={1}
          color="#ccc"
          style={{
            backgroundColor: 'var(--color-lightGray7)',
          }}
        />
        <Controls />
      </ReactFlow>
    </div>
  );
}
