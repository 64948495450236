import { PadBox } from '@bedrock-layout/padbox';
import { Inline } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import { memo, useEffect, useState } from 'react';
import { GoGitBranch } from 'react-icons/go';
import { IoPlayOutline } from 'react-icons/io5';
import { Handle, NodeProps, Position } from 'reactflow';
import { IconButton, TooltipReact, Typography, toasts, useLayer } from 'ui';

import { EntityVersionSelection } from '../../../../../../components/EntityVersionSelection/EntityVersionSelection';
import { useSendEventToGTM } from '../../../../../../hooks/useSendEventToGTM';
import { ENTITY_ID } from '../../../../../../utils/constant';
import {
  changedNodeIdsAtom,
  isWorkflowReadOnlyAtom,
  isWorkflowTestOnlyAtom,
  versionMappingWfInfoAtom,
  workflowEdgesAtom,
  workflowErrorByNodeAtom,
  workflowNodesAtom,
  workflowStatusAtom,
} from '../../../../atoms/atoms';
import { useTestWorkflowNode } from '../../../../hooks/useTestWorkflowNode';
import {
  checkNodeDisabled,
  getExecutedValueAndStatus,
} from '../../../../utils/common';
import { statusListForDataUpdate } from '../../../../utils/constant';
import { WorkflowNodeSheet } from '../../../Sheets/WorkflowNodeSheet/WorkflowNodeSheet';
import { NodeStatus } from '../../NodeStatus/NodeStatus';
import { WorkflowAction } from '../WorkflowAction/WorkflowAction';
import {
  DtContainer,
  IconContainer,
  RuleActionsContainer,
  RuleInfoContainer,
} from './NectedWorkflowNode.styled';

type ComponentProps = {
  data: any;
  id: string;
  type: string;
  isNodeDisabled: boolean;
  testWorkflowData: (
    attr?: any[],
    isLoop?: Record<string, any>
  ) => Promise<void>;
  nodeAccessRole?: string;
};

export const NectedWorkflowNode = memo(
  ({ data, isConnectable, id, type }: NodeProps) => {
    const [, setChangedNodeIds] = useAtom(changedNodeIdsAtom);

    const [workflowNodes] = useAtom(workflowNodesAtom);
    const [workflowEdges] = useAtom(workflowEdgesAtom);
    const [workflowStatus] = useAtom(workflowStatusAtom);

    // eslint-disable-next-line
    const [isNodeDisabled, setIsNodeDisabled] = useState(false);

    useEffect(() => {
      const nodeDisabled = checkNodeDisabled(
        data.rootId ?? '',
        id,
        workflowNodes,
        workflowEdges
      );

      setIsNodeDisabled(nodeDisabled.isDisabled);
    }, [JSON.stringify(workflowNodes)]);

    const {
      setIsTesting,
      workflowNode,
      setCurrentTab,
      workflowData,
      workflowError,
      setCurrentStatus,
      testWorkflowData,
      nodeAccessRole,
    } = useTestWorkflowNode({ localData: data, id, type: 'workflowNode' });

    useEffect(() => {
      if (!_isNil(workflowData)) {
        if (
          !_isNil(workflowNode) &&
          statusListForDataUpdate.includes(workflowStatus)
        ) {
          const newWorkflowNode = workflowNode;
          const entity = getExecutedValueAndStatus(workflowData);
          setCurrentStatus('success');

          newWorkflowNode.data.status = entity.status;

          newWorkflowNode.data.executedValue = entity.executedValue;

          setChangedNodeIds([]);

          setTimeout(() => {
            data.onWorkflowNodeChange(workflowNode);
          }, 100);
        }

        setCurrentStatus('success');
        setCurrentTab(0);
        setCurrentTab(1);
        setIsTesting(false);
        toasts.success('Node tested successfully', 'node-test-success');
      }
    }, [workflowData]);

    useEffect(() => {
      if (!_isNil(workflowError)) {
        setCurrentStatus('error');

        if (!_isNil(workflowNode)) {
          const newWorkflowNode = workflowNode;
          newWorkflowNode.data.status = 'error';
          newWorkflowNode.data.executedValue = null;

          setChangedNodeIds([]);

          setTimeout(() => {
            data.onWorkflowNodeChange(workflowNode);
          }, 100);
        }

        setIsTesting(false);

        toasts.error(workflowError.message, 'node-test-fail');
      }
    }, [workflowError]);

    return (
      <>
        <Handle
          type="source"
          position={Position.Bottom}
          style={{
            background: 'var(--color-dodgerBlue)',
            height: 8,
            width: 8,
            border: '1px solid var(--color-dodgerBlue)',
          }}
          isConnectable={isConnectable}
        />
        <Handle
          type="target"
          position={Position.Top}
          style={{
            background: 'var(--color-dodgerBlue)',
            height: 8,
            width: 8,
            border: '1px solid var(--color-dodgerBlue)',
          }}
          isConnectable={isConnectable}
        />

        {isNodeDisabled ? (
          <TooltipReact
            id={id}
            placement="right"
            launcher={
              <span>
                <Component
                  data={data}
                  id={id}
                  isNodeDisabled={isNodeDisabled}
                  type={type}
                  testWorkflowData={testWorkflowData}
                  nodeAccessRole={nodeAccessRole}
                />
              </span>
            }
          >
            <Typography>
              This branch is disabled because the workflow is running in a loop.
            </Typography>
          </TooltipReact>
        ) : (
          <Component
            data={data}
            id={id}
            isNodeDisabled={isNodeDisabled}
            type={type}
            testWorkflowData={testWorkflowData}
            nodeAccessRole={nodeAccessRole}
          />
        )}
      </>
    );
  }
);

const Component = ({
  data,
  id,
  type,
  isNodeDisabled,
  testWorkflowData,
  nodeAccessRole,
}: ComponentProps) => {
  const [workflowErrorByNode] = useAtom(workflowErrorByNodeAtom);
  const [isWorkflowTestOnly] = useAtom(isWorkflowTestOnlyAtom);
  const [isWorkflowReadOnly] = useAtom(isWorkflowReadOnlyAtom);

  const [versionMappingInfo, setVersionMappingInfo] = useAtom(
    versionMappingWfInfoAtom
  );

  const currNodeVersionMapping = versionMappingInfo?.find(
    (currMapping) => currMapping.nodeId === id
  );

  const { sendEventToGTM } = useSendEventToGTM();

  const { openWithProps } = useLayer(<WorkflowNodeSheet />);

  const handleDataUpdate = (data: Record<string, any>) => {
    if ('selectedVersion' in data) {
      const versionInfo = data.selectedVersion;

      setVersionMappingInfo(
        (versionMappingList) =>
          versionMappingList?.map((currMapping) => {
            if (currMapping.nodeId === id) {
              return {
                ...currMapping,
                version: versionInfo.version,
              };
            }

            return currMapping;
          }) ?? []
      );
    }
  };

  return (
    <DtContainer padding="0.5rem" $isError={!(workflowErrorByNode[id] == null)}>
      <NodeStatus status={data?.status} />

      <IconContainer
        onClick={() => {
          sendEventToGTM({
            event: 'workflow',
            source: 'listing',
            element: type,
            action: 'node_click',
            type: data?.nodeType,
          });

          if (!isNodeDisabled) {
            openWithProps({
              id,
              data,
            });
          }
        }}
      >
        <GoGitBranch size={32} color={isNodeDisabled ? '#dcdcdc' : '#111'} />
      </IconContainer>

      <PadBox
        padding={{
          top: '6px',
          bottom: '6px',
        }}
      >
        <RuleInfoContainer>
          <Typography name="paragraphXs" fontWeight={700}>
            {data?.name ?? 'Step 1'}
          </Typography>

          <Typography name="paragraphXs">Workflow</Typography>

          {!(workflowErrorByNode[id] == null) && (
            <Typography name="errorXs">
              {workflowErrorByNode[id]?.message}
            </Typography>
          )}

          <EntityVersionSelection
            entityInfo={{
              type: ENTITY_ID.workflow,
              id: data?.entityId ?? '',
            }}
            onLoadGetData={false}
            isReadOnly={isWorkflowReadOnly}
            selectedVersion={currNodeVersionMapping?.version}
            updateDataOnParent={handleDataUpdate}
            showRefreshBtn={true}
            entityAccessRole={nodeAccessRole}
          />
        </RuleInfoContainer>

        {!isNodeDisabled && (
          <RuleActionsContainer>
            <Inline
              align="center"
              style={{
                background: 'var(--color-lightGray7)',
                width: '4rem',
              }}
            >
              <IconButton
                disabled={!isWorkflowTestOnly}
                onClick={async () => await testWorkflowData()}
              >
                <IoPlayOutline
                  color={
                    isWorkflowTestOnly
                      ? 'var(--color-black)'
                      : 'var(--color-darkGray)'
                  }
                />
              </IconButton>

              <WorkflowAction data={data} id={id} />
            </Inline>
          </RuleActionsContainer>
        )}
      </PadBox>
    </DtContainer>
  );
};

NectedWorkflowNode.displayName = 'NectedWorkflowNode';
