import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import { toasts } from 'ui';

import {
  TESTABLE_NODES,
  sourceTypesToIgnoreOnValueReplacement,
} from '../../../utils/constant';
import {
  changedNodeIdsAtom,
  workflowEdgesAtom,
  workflowIdAtom,
  workflowNodesAtom,
  workflowStatusAtom,
} from '../atoms/atoms';
import {
  getAllPredecessorsSkippingType,
  getExecutedValueAndStatus,
  sanitizedStringV2,
} from '../utils/common';
import { statusListForDataUpdate } from '../utils/constant';
import { useTestWorkflow } from './restApi/useTestWorkflow';
import { useGenerateDataset } from './useGenerateDataset';
import { useGetDataset } from './useGetDataset';
import { WorkflowNodeType } from './useOpenWorkflow';

type UseTestCodeNodeProps = {
  localData: any;
  id: string;
};

export function useTestCodeNode({ localData, id }: UseTestCodeNodeProps) {
  const [workflowId] = useAtom(workflowIdAtom);
  const [workflowNodes] = useAtom(workflowNodesAtom);
  const [workflowEdges] = useAtom(workflowEdgesAtom);
  const [workflowStatus] = useAtom(workflowStatusAtom);
  const [, setChangedNodeIds] = useAtom(changedNodeIdsAtom);

  const [parentNodes, setParentNodes] = useState<WorkflowNodeType[]>([]);

  // eslint-disable-next-line
  const { testWorkflow, data: codeData, error: codeError } = useTestWorkflow();

  const [isTesting, setIsTesting] = useState(false);
  const [currentStatus, setCurrentStatus] = useState<string>(
    localData.status ?? ''
  );

  const workflowNode = workflowNodes.find((wn) => wn.id === id);

  const { updatedDataSet } = useGetDataset({
    parentNodes,
  });

  const { tokens } = useGenerateDataset({
    id,
    updatedDataset: updatedDataSet,
  });

  useEffect(() => {
    if (!_isNil(codeData)) {
      if (
        !_isNil(workflowNode) &&
        statusListForDataUpdate.includes(workflowStatus)
      ) {
        const newWorkflowNode = workflowNode;
        const exec = getExecutedValueAndStatus(codeData);
        setCurrentStatus(exec.status);

        newWorkflowNode.data.status = exec.status;
        newWorkflowNode.data.executedValue = exec.executedValue;

        setChangedNodeIds([]);

        setCurrentStatus(exec.status);
        setTimeout(() => {
          localData.onWorkflowNodeChange(newWorkflowNode);
        }, 100);
      }
      setIsTesting(false);
    }
  }, [codeData]);

  useEffect(() => {
    if (!_isNil(codeError)) {
      setIsTesting(false);
    }
  }, [codeError]);

  const testData = async (
    query: string,
    language: string,
    onSuccess?: () => void
  ) => {
    if (
      parentNodes.find(
        (node) =>
          TESTABLE_NODES.includes(node.type ?? '') &&
          node.data.status !== 'success'
      ) != null
    ) {
      setIsTesting(false);

      return toasts.error(
        'Previous node(s) are  not tested. Please test the previous node(s) before proceeding',
        'parent-node'
      );
    }

    try {
      testWorkflow({
        id: workflowId ?? '',
        nodeId: id,
        params: {
          snippet: sanitizedStringV2(
            query,
            updatedDataSet,
            sourceTypesToIgnoreOnValueReplacement
          ),
          language,
        },
      });

      if (typeof onSuccess === 'function') {
        onSuccess();
      }
    } catch (error) {}
  };

  useEffect(() => {
    const directParents = getAllPredecessorsSkippingType(
      id,
      workflowNodes,
      workflowEdges,
      'addNode'
    );

    setParentNodes(directParents);
  }, [JSON.stringify(workflowNodes)]);

  return {
    testData,
    codeData,
    codeError,
    updatedDataSet,
    tokens,
    parentNodes,
    currentStatus,
    isTesting,
    setIsTesting,
  };
}
