import { PadBox } from '@bedrock-layout/padbox';
import { Inline } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { memo, useEffect, useState } from 'react';
import { IoPlayOutline } from 'react-icons/io5';
import { Handle, NodeProps, Position } from 'reactflow';
import { IconButton, Image, Typography, useLayer } from 'ui';

import {
  siteConstantsAtom,
  usedConnectorMappingAtom,
} from '../../../../../../atom';
import { useSendEventToGTM } from '../../../../../../hooks/useSendEventToGTM';
import {
  getTooltipText,
  isValidImageURL,
} from '../../../../../../utils/common';
import {
  isWorkflowTestOnlyAtom,
  workflowErrorByNodeAtom,
} from '../../../../atoms/atoms';
import { useGetConnectorById } from '../../../../hooks/graphql/useGetConnectorById';
import { useGetPluginByName } from '../../../../hooks/graphql/useGetPluginByName';
import { useTestGSheetNode } from '../../../../hooks/useTestGSheetNode';
import { GSheetNodeSheet } from '../../../Sheets/GSheetNodeSheet/GSheetNodeSheet';
import { NodeStatus } from '../../NodeStatus/NodeStatus';
import { ConnectorAction } from '../ConnectorAction/ConnectorAction';
import {
  DbContainer,
  GSheetActionsContainer,
  GSheetInfoContainer,
  IconContainer,
} from './GSheetNode.styled';

export const GSheetNode = memo(
  ({ data, isConnectable, id, type }: NodeProps) => {
    const { openWithProps } = useLayer(<GSheetNodeSheet />);
    const [imageurl, setImageUrl] = useState('');

    const [workflowErrorByNode] = useAtom(workflowErrorByNodeAtom);

    const [isWorkflowTestOnly] = useAtom(isWorkflowTestOnlyAtom);
    const [usedConnectorMapping] = useAtom(usedConnectorMappingAtom);
    const [siteConstants] = useAtom(siteConstantsAtom);

    const [getConnectorById] = useGetConnectorById();
    const [getPluginByName, { data: pluginData }] = useGetPluginByName();

    const { testData } = useTestGSheetNode({
      id,
      localData: data,
    });

    useEffect(() => {
      // eslint-disable-next-line
      if (!data?.entityId && !!data?.blockName) {
        void getPluginByName({
          variables: {
            filters: {
              eq: {
                name: data?.blockName,
              },
            },
          },
        });
      }
    }, []);

    useEffect(() => {
      if (!_isNil(pluginData)) {
        setImageUrl(pluginData?.getPlugin.data?.[0].imageUrl);
      }
    }, [pluginData]);

    const hasConnectorError =
      usedConnectorMapping?.[data?.entityId]?.status === false;

    useEffect(() => {
      if (!_isEmpty(data.entityId)) {
        void handleGetConnectorProps(data.entityId);
      }
    }, [data.entityId]);

    const handleGetConnectorProps = async (id: string) => {
      try {
        const data = await getConnectorById({
          variables: {
            id,
          },
        });

        const connectorData = data.data.getConnector?.data[0];

        setImageUrl(connectorData.plugin.imageUrl);
      } catch (error) {
        // eslint-disable-next-line
        console.log('error', error);
      }
    };

    const { sendEventToGTM } = useSendEventToGTM();

    return (
      <>
        <Handle
          type="source"
          position={Position.Bottom}
          style={{
            background: 'var(--color-dodgerBlue)',
            height: 8,
            width: 8,
            border: '1px solid var(--color-dodgerBlue)',
          }}
          isConnectable={isConnectable}
        />
        <Handle
          type="target"
          position={Position.Top}
          style={{
            background: 'var(--color-dodgerBlue)',
            height: 8,
            width: 8,
            border: '1px solid var(--color-dodgerBlue)',
          }}
          isConnectable={isConnectable}
        />
        <DbContainer
          padding="0.5rem"
          $isError={!(workflowErrorByNode[id] == null) || hasConnectorError}
        >
          <NodeStatus
            status={data.status}
            showError={hasConnectorError}
            tooltipId="restApiNode-error"
            errorTooltipText={getTooltipText(
              siteConstants,
              'integrations',
              'integrationNotConnected'
            )}
          />

          <IconContainer
            onClick={() => {
              sendEventToGTM({
                event: 'workflow',
                source: 'listing',
                element: type,
                action: 'node_click',
                type: data.nodeType,
              });

              openWithProps({
                id,
                data,
              });
            }}
          >
            <Image
              src={isValidImageURL(imageurl)}
              alt="G-Sheet"
              size="medium"
            />
          </IconContainer>

          <PadBox
            padding={{
              top: '6px',
              bottom: '6px',
            }}
          >
            <GSheetInfoContainer>
              <Typography name="paragraphXs" fontWeight={700}>
                {data.name ?? 'Step 1'}
              </Typography>

              <Typography name="paragraphXs">Action</Typography>

              {!(workflowErrorByNode[id] == null) && (
                <Typography name="errorXs">
                  {workflowErrorByNode[id]?.message}
                </Typography>
              )}
            </GSheetInfoContainer>

            <GSheetActionsContainer>
              <Inline
                align="center"
                style={{
                  background: 'var(--color-lightGray7)',
                  width: '4rem',
                }}
              >
                <IconButton
                  disabled={!isWorkflowTestOnly}
                  onClick={async () => await testData(data)}
                >
                  <IoPlayOutline
                    color={
                      isWorkflowTestOnly
                        ? 'var(--color-black)'
                        : 'var(--color-darkGray)'
                    }
                  />
                </IconButton>

                <ConnectorAction data={data} id={id} />
              </Inline>
            </GSheetActionsContainer>
          </PadBox>
        </DbContainer>
      </>
    );
  }
);

GSheetNode.displayName = 'GSheetNode';
