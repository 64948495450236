import { PadBox } from '@bedrock-layout/padbox';
import { Inline } from '@bedrock-layout/primitives';
import styled from 'styled-components';

export const HowToStyled = styled(PadBox)`
  border: 1px solid var(--color-whiteSmoke);
  background-color: var(--color-white);
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: var(--color-whiteSmoke);
  }
`;

export const HowToLinkText = styled(Inline)`
  color: var(--color-dodgerBlue);
  cursor: pointer;
  display: inline-flex;
`;
