import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _isUndefined from 'lodash/isUndefined';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  UseControllerProps,
  UseFormSetValue,
  useController,
} from 'react-hook-form';
import { PopoverMethods, PopoverPanel, RuleField } from 'ui';

import { customAttributesAtom } from '../../../../../components/rules/forms/CustomAttributeSheet/CustomAttributeSheet';
import { isArrayNotPresent } from '../../../../../utils/common';
import { EXTRACT_TOKEN_REGEX } from '../../../../../utils/regex';
import { isRuleReadOnlyAtom, selectedDataSetAtom } from '../../../index';
import { sendEventToGTMType } from '../../../types';
import {
  getDataSetByType,
  getRhsNodeTitle,
  getTypesToAllowForConditionNodes,
  removeCustomFunction,
  updateDataSetOnChange,
} from '../../../utils/common';
import { dataSetParamsAtom } from '../../CreateRuleSheet/CreateRuleSheet';
import { decisionTableNodesAtom } from '../DecisionTable';
import { ResultHeader } from '../components/ResultHeader';
import { RhsLauncher } from './RhsLauncher';
import { RhsInputContainer } from './RhsNode.styled';

type RhsParamsPopoverProps = Omit<UseControllerProps<any>, 'name'> & {
  groupIndex: number;
  conditionIndex: number;
  rhsIndex: number;
  nodeKey: string;
  groupKey: string;
  conditionKey: string;
  setValue: UseFormSetValue<any>;
  onFocus?: () => void;
  onBlur?: () => void;
  handleSendEventToGTM?: (obj: sendEventToGTMType) => void;
};

export const RhsParamPopover = ({
  groupIndex,
  conditionIndex,
  rhsIndex,
  nodeKey,
  groupKey,
  conditionKey,
  control,
  setValue,
  onFocus,
  onBlur,
  handleSendEventToGTM,
}: RhsParamsPopoverProps) => {
  const [nodes, setNodes] = useAtom(decisionTableNodesAtom);
  const [dataset] = useAtom(dataSetParamsAtom);
  const [dataSetSelected] = useAtom(selectedDataSetAtom);
  const [customAttributes] = useAtom(customAttributesAtom);

  const [isRuleReadOnly] = useAtom(isRuleReadOnlyAtom);

  const [panelVisible, setPanelVisible] = useState(false);
  const [, setFilteredDataSet] = useState(dataset);

  const nodeName = `rows.${groupIndex}.${groupKey}.condition.${conditionIndex}.rhs.${rhsIndex}.${nodeKey}`;

  const dataType = !_isUndefined(nodes[conditionKey]?.dataType)
    ? nodes[conditionKey].dataType
    : '';

  const selectedOperator = !_isUndefined(nodes[conditionKey]?.operator)
    ? nodes[conditionKey].operator
    : '';

  const { fieldState } = useController({
    name: nodeName,
    control,
  });

  useEffect(() => {
    if (!_isUndefined(dataType) && !_isEmpty(dataType)) {
      const dataSetWithSameDataType = getDataSetByType(
        dataset,
        dataType,
        dataSetSelected,
        selectedOperator
      );

      setFilteredDataSet(
        updateDataSetOnChange(
          customAttributes,
          dataSetWithSameDataType,
          dataSetSelected,
          true
        )
      );
    }
  }, [nodes[conditionKey], dataset, dataSetSelected]);

  const title = (getRhsNodeTitle(nodes, nodeKey, dataset) ?? '') as string;
  const ref = useRef<PopoverMethods>(null);

  const typesToAllow = useMemo(
    () => getTypesToAllowForConditionNodes(dataType, selectedOperator),
    [dataType, selectedOperator]
  );

  const onChangeSpecial = (val: any) => {
    const localNodeType = 'constant';
    let newVal = val;
    try {
      const tokens = (typeof val === 'string' ? val : '').match(
        EXTRACT_TOKEN_REGEX
      );

      if (_isNil(tokens) || _isEmpty(tokens)) {
        newVal = JSON.parse(val);
      }
    } catch {}

    setValue(`${nodeName}.value`, newVal);

    setValue(`${nodeName}.key`, '');
    setValue(`${nodeName}.dataType`, 'list');

    setNodes((prev) => ({
      ...prev,
      [nodeKey]: {
        ...prev[nodeKey],
        nodeType: localNodeType,
        value: newVal,
        dataType: 'list',
        sourceType: '',
        attribute: '',
      },
    }));
  };

  return (
    <PopoverPanel
      trigger="click"
      placement="bottom-start"
      launcher={
        <RhsLauncher
          panelVisible={panelVisible}
          text={dataType === 'list' ? title ?? '' : title?.toString() ?? ''}
          nodeId={nodeKey}
          nodeName={nodeName}
          control={control}
          setValue={setValue}
          conditionKey={conditionKey}
          error={fieldState.error?.message}
          onFocus={onFocus}
          onBlur={onBlur}
          disabled={isRuleReadOnly}
          dataType={dataType}
          handleSendEventToGTM={handleSendEventToGTM}
          selectedOperator={selectedOperator}
          handleHidePanel={ref.current?.hide}
        />
      }
      ref={ref}
      padding="8px"
      disabled={isRuleReadOnly}
    >
      <RhsInputContainer
        onMouseEnter={() => setPanelVisible(true)}
        onMouseLeave={() => setPanelVisible(false)}
      >
        <RuleField
          name={nodeName}
          control={control}
          dataset={removeCustomFunction(dataset)}
          disabled={isRuleReadOnly}
          allowList
          version="v2"
          typesToAllow={typesToAllow}
          onClick={({ value, key, dataType }) => {
            ref.current?.hide();

            if (typeof handleSendEventToGTM === 'function') {
              handleSendEventToGTM({
                action: 'edit',
                element: 'rhs_value',
                actionName: dataType,
              });
            }

            setNodes((prev) => ({
              ...prev,
              [nodeKey]: {
                ...prev[nodeKey],
                dataType,
                attribute: value,
                nodeType: 'params',
                sourceType: key,
                value: undefined,
              },
            }));

            setValue(`${nodeName}.value`, undefined);
          }}
          header={
            typesToAllow.includes('list') ? (
              <ResultHeader
                dataSet={removeCustomFunction(dataset)}
                isAdd={
                  // eslint-disable-next-line
                  !!nodes[nodeKey].attribute ||
                  isArrayNotPresent(nodes[nodeKey]?.value)
                }
                nodeName={`${nodeName}`}
                resIndex={0}
                control={control}
                onChangeSpecial={onChangeSpecial}
                disabled={isRuleReadOnly}
                dataType={'list'}
                overrideValue={nodes[nodeKey]?.value}
              />
            ) : undefined
          }
        />
      </RhsInputContainer>
    </PopoverPanel>
  );
};
