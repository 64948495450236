import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import { useLayer } from 'ui';

import { buttonOptionIdsObj } from '../components/ButtonWithOptions/constant';
import type { ButtonOptionsIdsType } from '../components/ButtonWithOptions/type';
import { ApprovalFlowEnableWarningModal } from '../components/Modals/ApprovalFlowEnableWarningModal/ApprovalFlowEnableWarningModal';
import { ArchiveEntityModal } from '../components/Modals/ArchiveEntityModal/ArchiveEntityModal';
import { PublishModal } from '../components/Modals/PublishModal/PublishModal';
import { RejectChangesModal } from '../components/Modals/RejectChangesModal/RejectChangesModal';
import { RequestReviewModal } from '../components/Modals/RequestReviewModal/RequestReviewModal';
import { RestoreToDraftWarningModal } from '../components/Modals/RestoreToDraftWarningModal/RestoreToDraftWarningModal';
import { RollbackEntityModal } from '../components/Modals/RollbackEntityModal/RollbackEntityModal';
import { currentWorkspaceDetailAtom } from '../pages/Workspace/atom';

type UseUpdateModalStateProps = {
  entityId: string;
  entityType: string;
  entityName: string;
  entityStatus: string;
  entitySubType?: string;
  handleParentStateUpdate?: (data: Record<string, any>) => void;
  formData?: Record<string, any>;
  handleBtnOperationValid?: (data: Record<string, any>) => boolean;
};

export function useUpdateModalStateOnButtonOptionClick({
  entityId,
  entityType,
  entityName,
  entityStatus,
  entitySubType,
  handleParentStateUpdate,
  formData,
  handleBtnOperationValid,
}: UseUpdateModalStateProps) {
  const workspaceUUID =
    window.sessionStorage.getItem('workspaceUUID') ??
    window.localStorage.getItem('workspaceUUID');

  const [currentWorkspace] = useAtom(currentWorkspaceDetailAtom);

  const approvalFlowEnabled = currentWorkspace?.approvalFlowEnabled ?? false;

  const [selectedOptionId, setSelectedOptionId] =
    useState<ButtonOptionsIdsType>();

  useEffect(() => {
    if (approvalFlowEnabled) {
      switch (entityStatus) {
        case 'draft':
        case 'tested':
          setSelectedOptionId(buttonOptionIdsObj.requestReview);
          break;
        case 'inreview':
        case 'published':
          setSelectedOptionId(buttonOptionIdsObj.publish);
          break;
        default:
          setSelectedOptionId(buttonOptionIdsObj.requestReview);
      }
    } else {
      setSelectedOptionId(buttonOptionIdsObj.publish);
    }
  }, [approvalFlowEnabled, entityStatus]);

  const { openWithProps: openRejectChangesModal } = useLayer(
    <RejectChangesModal
      entityId={entityId}
      entityType={entityType}
      entityName={entityName}
      entitySubType={entitySubType}
    />
  );

  const { openWithProps: openPublishModal } = useLayer(
    <PublishModal
      entityId={entityId}
      entityType={entityType}
      entityName={entityName}
      entitySubType={entitySubType}
    />
  );

  const { openWithProps: openRequestReviewModal } = useLayer(
    <RequestReviewModal
      entityId={entityId}
      entityType={entityType}
      entityName={entityName}
      entitySubType={entitySubType}
    />
  );

  const { openWithProps: openApprovalEnableWarningModal } = useLayer(
    <ApprovalFlowEnableWarningModal />
  );

  const { openWithProps: openMoveDraftWarningModal } = useLayer(
    <RestoreToDraftWarningModal entityId={entityId} entityType={entityType} />
  );

  const { openWithProps: openRollbackEntityModal } = useLayer(
    <RollbackEntityModal entityId={entityId} entityType={entityType} />
  );

  const { openWithProps: openArchiveEntityModal } = useLayer(
    <ArchiveEntityModal entityId={entityId} entityType={entityType} />
  );

  const handleOpenRequestReviewModal = () => {
    openRequestReviewModal({
      handleEntityUpdate,
      defaultFormValues: formData?.requestReivew,
    });
  };

  const handleEntityUpdate = (data: Record<string, any>) => {
    if (!_isNil(data)) {
      const { modalType, ...updatedData } = data;

      if (modalType === 'approvalFlowEnabled') {
        openApprovalEnableWarningModal({ handleOpenRequestReviewModal });
      }

      if (typeof handleParentStateUpdate === 'function') {
        handleParentStateUpdate(updatedData);
      }
    }
  };

  const handleOptionButtonClick = (
    btnId: string,
    metaData: Record<string, any> = {}
  ) => {
    if (btnId === buttonOptionIdsObj.publish) {
      let isValid = true;

      if (typeof handleBtnOperationValid === 'function') {
        isValid = handleBtnOperationValid({ operation: btnId });
      }

      if (isValid) {
        openPublishModal({
          entityStatus,
          entityId,
          handleEntityUpdate,
          defaultFormValues: formData?.publishModal,
        });
      }
    } else if (btnId === buttonOptionIdsObj.requestReview) {
      openRequestReviewModal({
        entityId,
        handleEntityUpdate,
        defaultFormValues: formData?.requestReview,
      });
    } else if (btnId === buttonOptionIdsObj.reject) {
      openRejectChangesModal({
        entityId,
        handleEntityUpdate,
      });
    } else if (btnId === buttonOptionIdsObj.rollback) {
      openRollbackEntityModal({
        entityId,
        entityType,
        commitId: metaData?.commitId,
        handleEntityUpdate,
      });
    } else if (btnId === buttonOptionIdsObj.preview) {
      const commitId = metaData?.commitId;

      let url = `/${entityType}/${entityId}?type=view&stage=staging&wsid=${
        workspaceUUID as string
      }&isLive=true`;

      if (!_isNil(entitySubType)) {
        if (entityType === 'rules') {
          url += `&ruleType=${entitySubType}`;
        } else if (entityType === 'datasets') {
          url += `&editor=${entitySubType}`;
        }
      }

      if (!_isNil(commitId)) {
        url += `&commitId=${commitId as string}`;
      }

      window.open(url, '_blank');
    } else if (btnId === buttonOptionIdsObj.resetEditor) {
      handleEntityUpdate({ discardEntity: true });
    } else if (btnId === 'archived') {
      openArchiveEntityModal({
        entityId,
        entityType,
        entityStatus,
        commitId: metaData?.commitId,
        handleEntityUpdate,
        defaultFormValues: {
          title: formData?.requestReview?.title,
        },
      });
    } else if (btnId === buttonOptionIdsObj.restoreToDraft) {
      openMoveDraftWarningModal({
        commitId: metaData?.commitId,
      });
    }
  };

  return {
    selectedOptionId,
    handleOptionButtonClick,
  };
}
