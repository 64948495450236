import _isUndefined from 'lodash/isUndefined';
import { useEffect, useState } from 'react';
import { Image } from 'ui';

import type { PanelType } from '../../types';
import { isValidImageURL } from '../../utils/common';
import { PanelContainerStyled, PanelItem } from './Panel.styled';
import { panelList } from './constant';

type PanelProps = {
  defaultActivePanel?: PanelType;
  onPanelItemClick?: (id?: PanelType) => void;
};

export function Panel({ defaultActivePanel, onPanelItemClick }: PanelProps) {
  const defaultItemIndex = panelList.findIndex(
    (panelObj) => panelObj.id === defaultActivePanel
  );

  const [activeItemIndex, setActiveItemIndex] = useState(defaultItemIndex);

  useEffect(() => {
    if (typeof onPanelItemClick === 'function') {
      onPanelItemClick(panelList[activeItemIndex]?.id as PanelType);
    }
  }, [activeItemIndex]);

  useEffect(() => {
    if (!_isUndefined(defaultActivePanel)) {
      const index = panelList.findIndex(
        (panelObj) => panelObj.id === defaultActivePanel
      );
      setActiveItemIndex(index);
    }
  }, [defaultActivePanel]);

  const updateActivePanel = (index: number) => {
    if (index === activeItemIndex) {
      setActiveItemIndex(-1);

      return;
    }

    setActiveItemIndex(index);
  };

  return (
    <PanelContainerStyled>
      {panelList.map((currentPanel, index) => (
        <PanelItem
          key={currentPanel.id}
          $active={activeItemIndex === index}
          justify="center"
          align="center"
          onClick={() => updateActivePanel(index)}
        >
          {typeof currentPanel.Icon === 'string' ? (
            <Image src={isValidImageURL(currentPanel.Icon)} alt="connector" />
          ) : (
            <currentPanel.Icon
              size={24}
              color={
                activeItemIndex === index
                  ? 'var(--color-dodgerBlue)'
                  : 'var(--color-darkGray)'
              }
            />
          )}
        </PanelItem>
      ))}
    </PanelContainerStyled>
  );
}
