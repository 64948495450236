import { Stack } from '@bedrock-layout/stack';
import styled from 'styled-components';
import { Typography } from 'ui';

export const Container = styled(Stack)`
  border-top: 1px solid var(--color-lightGray);
  margin-top: 0.5rem;
  padding: 1rem 0rem;
  block-size: 45rem;
  overflow-y: auto;
`;

export const TextBlue = styled(Typography)`
  color: var(--color-dodgerBlue);
`;
