import { Stack } from '@bedrock-layout/stack';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useMemo } from 'react';
import { LuArrowRightToLine } from 'react-icons/lu';
import { Button, TooltipReact } from 'ui';

import {
  generateEntityLink,
  getEntityTypeForUI,
} from '../../../../utils/common';
import {
  BlueTextStyled,
  CardStyled,
  EnvironmentText,
  InlineStyled,
  StyledName,
} from '../CommonStyles.styled';
import {
  EnvVersionContainer,
  VersionTextStyled,
} from './EntityUsingCard.styled';

export type EntityUsingCardData = {
  id: string;
  name: string;
  type: string;
  version: string;
  commitTitle: string;
  subType: string;
  nodeId: string;
};

type EntityUsingCardProps = {
  data: EntityUsingCardData;
};

export function EntityUsingCard({ data }: EntityUsingCardProps) {
  const { id, name, version, type, commitTitle, subType } = data;

  const entityTypeUI = useMemo(() => getEntityTypeForUI(type), [type]);

  const handleOpenEntity = () => {
    const link = generateEntityLink({
      entityId: id,
      entityType: type,
      category: subType,
      version,
    });

    window.open(link, '_blank');
  };

  const env =
    _isNil(version) || _isEmpty(version) || version === 'draft'
      ? 'staging'
      : 'production';

  return (
    <CardStyled>
      <InlineStyled $hasBottomBorder={false}>
        <Stack gutter="0.5rem">
          <TooltipReact
            id={id}
            launcher={<StyledName name="paragraphSmall">{name}</StyledName>}
          >
            <StyledName name="paragraphSmall">{name}</StyledName>
          </TooltipReact>
          <BlueTextStyled fontWeight={700}>{entityTypeUI}</BlueTextStyled>
        </Stack>
        {!['variable', 'connector'].includes(type) && (
          <EnvVersionContainer env={env} gutter="0.25rem">
            <EnvironmentText env={env} fontWeight={700}>
              {env}
            </EnvironmentText>
            {!_isNil(version) && !_isEmpty(version) && (
              <>
                <VersionTextStyled fontWeight={700}>|</VersionTextStyled>

                <VersionTextStyled fontWeight={700}>
                  {version}
                </VersionTextStyled>
              </>
            )}
          </EnvVersionContainer>
        )}
      </InlineStyled>
      <InlineStyled $hasTopBorder={false} bgColor="var(--color-aliceBlue2)">
        <BlueTextStyled>{commitTitle}</BlueTextStyled>
        <Button
          size="xs"
          appearance="bordered"
          trailingIcon={<LuArrowRightToLine />}
          onClick={handleOpenEntity}
        >
          View
        </Button>
      </InlineStyled>
    </CardStyled>
  );
}
