import { Inline, PadBox } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import { BiCopy } from 'react-icons/bi';
import { Button, IconButton, TooltipReact, Typography } from 'ui';

import { isRuleReadOnlyAtom } from '../../..';
import { siteConstantsAtom } from '../../../../../atom';
import { copyToClipboard, getTooltipText } from '../../../../../utils/common';
import {
  ApiContainer,
  Curl,
  NavigateToCredentialStyled,
} from './TriggerApi.styled';

export type TriggerApiProps = {
  authType: string;
  triggerUrl: string;
  credentialKey?: string;
};

export function TriggerApi({
  authType,
  credentialKey,
  triggerUrl,
}: TriggerApiProps) {
  const [isRuleReadOnly] = useAtom(isRuleReadOnlyAtom);
  const [siteConstants] = useAtom(siteConstantsAtom);

  return (
    <Stack>
      <Inline gutter={8} align="center">
        <Typography fontWeight={700}>How to Trigger API?</Typography>
        <TooltipReact id="trigger-how-to">
          <Typography>
            {getTooltipText(siteConstants, 'rules', 'triggerHowTo')}
          </Typography>
        </TooltipReact>
      </Inline>

      <ApiContainer padding="2rem">
        {_isEmpty(credentialKey) &&
        authType === 'private' &&
        !isRuleReadOnly ? (
          <PadBox padding={['6rem', 0]}>
            <Stack as={Inline} justify="center" align="center">
              <Typography name="heading3">Generate Credential Key</Typography>
              <PadBox
                padding={4}
                as={NavigateToCredentialStyled}
                href="/credentials"
                target="_blank"
              >
                <Button>Add Key</Button>
              </PadBox>
            </Stack>
          </PadBox>
        ) : (
          <Inline stretch="start">
            <Curl justify="start">
              <Typography name="paragraphSmall">
                <pre>{triggerUrl}</pre>
              </Typography>
            </Curl>

            <Inline justify="end">
              <IconButton
                onClick={async () =>
                  await copyToClipboard(triggerUrl, 'API copied')
                }
              >
                <BiCopy size={20} />
              </IconButton>
            </Inline>
          </Inline>
        )}
      </ApiContainer>
    </Stack>
  );
}
