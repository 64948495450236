import { Helmet } from 'react-helmet';

import { AuthPagesLayout } from '../../components/authentication';
import { SITE_META } from '../../utils/common';
import { ResetPasswordForm } from './components/ResetPassword';

export const ResetPassword = () => {
  return (
    <>
      <Helmet>
        <title>
          {SITE_META[window.location.pathname.substring(1)]?.title ?? ''}
        </title>
        <meta
          name="description"
          content={
            SITE_META[window.location.pathname.substring(1)]?.description ?? ''
          }
        />
      </Helmet>
      <AuthPagesLayout
        isPasswordPage={true}
        form={<ResetPasswordForm />}
        from="resetPassword"
      />
    </>
  );
};
