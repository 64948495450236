import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import {
  Control,
  UseFormSetValue,
  UseFormWatch,
  useWatch,
} from 'react-hook-form';
import {
  DropdownField,
  EditorLanguages,
  Image,
  NectedEditorField,
  NectedSuggestionModel,
  TextField,
  TooltipReact,
  Typography,
} from 'ui';

import {
  siteConstantsAtom,
  usedConnectorMappingAtom,
} from '../../../../../../atom';
import { WhitelistIps } from '../../../../../../components/ConnectorComponents/WhitelistIps/WhitelistIps';
import { IntegrationField } from '../../../../../../components/IntegrationField/IntegrationField';
import {
  getTooltipText,
  isValidImageURL,
} from '../../../../../../utils/common';
import {
  POST_REQUESTS,
  bodyParamTypes,
  editorDomain,
  methods,
} from '../../../../../../utils/constant';
import { ACTION_KEY_REGEX } from '../../../../../../utils/regex';
import { SyntaxErrorContainer } from '../../../../../DataSets/components/DataSetForm.styled';
import { useGetPublishedConnectors } from '../../../../../DataSets/hooks/useGetPublishedConnectors';
import { integrationComponents } from '../../../../../Integrations/components/common/IntegrationForm';
import { useGetWhitelistIps } from '../../../../../Integrations/components/hooks/useGetWhitelistIps';
import { KeyValuePairList } from '../../../../../Integrations/types';
import { PublishedConnectors } from '../../../../../Rules/components/DecisionTable/types';
import { isWorkflowReadOnlyAtom } from '../../../../atoms/atoms';
import type { RestApiNodeForm } from '../../../../models/models';
import { BodyContainer, PluginContainer } from './RestApiMapping.styled';

type RestApiMappingProps = {
  disabledHeaders: KeyValuePairList[];
  disabledQueryParams: KeyValuePairList[];
  tokens: NectedSuggestionModel[];
  control: Control<any>;
  setValue: UseFormSetValue<any>;
  watch: UseFormWatch<any>;
  handleGetExecutionValues?: (value: Record<string, any>) => void;
  execValues?: Record<string, any>;
  connectorData?: any;
  localData: any;
};

export function RestApiMapping({
  disabledHeaders,
  disabledQueryParams,
  tokens,
  control,
  setValue,
  watch,
  handleGetExecutionValues,
  execValues,
  connectorData,
  localData,
}: RestApiMappingProps) {
  const { data, refetch } = useGetPublishedConnectors(false);
  const [connectorList, setConnectorList] = useState<any>();
  const [bodyEditorMode, setBodyEditorMode] = useState<EditorLanguages>('text');
  const [isValidBodyQuery, setIsValidBodyQuery] = useState(true);

  const [publishConnectorbyId, setPublishConnectors] =
    useState<PublishedConnectors>({});

  const [siteConstants] = useAtom(siteConstantsAtom);
  const [isWorkflowReadOnly] = useAtom(isWorkflowReadOnlyAtom);
  const [, setUsedConnectorMapping] = useAtom(usedConnectorMappingAtom);

  const KeyValueArray = integrationComponents.map;

  const whitelistIps = useGetWhitelistIps();

  const plugin = useWatch({
    name: 'plugin',
    control,
  });

  const selectedConnector = useWatch({
    name: 'integration',
    control,
  });

  const editPlugin = () => {
    window.open(
      `${window.location.origin}/integrations/${
        selectedConnector?.value as string
      }?stage=staging&connector=${connectorData.plugin.name as string}&wsid=${
        sessionStorage.getItem('workspaceUUID') as string
      }`
    );
  };

  const refreshPlugins = async () => {
    await refetch();
  };

  const formValues: RestApiNodeForm = watch();

  const method = formValues.input?.method?.value ?? null;
  const bodyQuery = formValues.input?.body?.value ?? '';
  const contentType = formValues.input?.contentType?.value ?? null;

  useEffect(() => {
    if (!_isNil(data)) {
      const publishedConnectors = data.getConnector.data
        .filter((connector) => connector.category === 'api')
        .map((connector) => {
          return {
            label: connector.name,
            value: connector.id,
          };
        });

      setConnectorList(publishedConnectors);
    }
  }, [data]);

  useEffect(() => {
    if (!_isNil(data)) {
      const publishedConnectors = data.getConnector.data ?? [];
      const publishConnectorIdAndPlugin: PublishedConnectors = {};

      if (publishedConnectors.length > 0) {
        publishedConnectors.forEach((connector) => {
          publishConnectorIdAndPlugin[connector.id] = connector;
        });
      }
      setPublishConnectors(publishConnectorIdAndPlugin);
    }
  }, [JSON.stringify(data)]);

  useEffect(() => {
    if (!_isEmpty(publishConnectorbyId)) {
      const id = selectedConnector?.value;

      if (!_isNil(id) && !_isEmpty(id)) {
        const currConnectorConfig = publishConnectorbyId[id];

        const connectorStatus: boolean =
          ((currConnectorConfig?.staging?.isTested as boolean) ?? true) &&
          ((currConnectorConfig?.staging?.isPublish as boolean) ?? true);

        setUsedConnectorMapping((prev) => ({
          ...prev,
          [id]: {
            status: connectorStatus,
            source: [],
          },
        }));
      }
    }
  }, [JSON.stringify(selectedConnector), JSON.stringify(publishConnectorbyId)]);

  useEffect(() => {
    const isJsonMode = ['application/json', 'application/javascript'].includes(
      contentType?.value
    );

    setBodyEditorMode(isJsonMode ? 'json' : 'text');
  }, [contentType]);

  return (
    <Stack gutter="1.5rem">
      <PluginContainer justify="start" align="center" gap="0.8rem">
        <Image
          src={isValidImageURL(plugin?.imageUrl)}
          alt="minus"
          size="small"
          round
        />
        <Typography> {plugin?.displayName ?? ''}</Typography>
      </PluginContainer>

      <IntegrationField
        control={control}
        editPlugin={editPlugin}
        refreshPlugins={refreshPlugins}
        name="integration"
        connectorList={connectorList}
        pluginId={plugin?.id ?? ''}
        disabled={isWorkflowReadOnly}
        connectorId={selectedConnector?.value}
      />

      {
        // eslint-disable-next-line
        (!!selectedConnector?.value as boolean) && (
          <>
            <div>
              <Inline gutter={8}>
                <Typography fontWeight={700}>Select Method</Typography>
              </Inline>
              <DropdownField
                control={control}
                name="input.method.value"
                options={methods}
                disabled={isWorkflowReadOnly}
              />
            </div>

            <div>
              <Inline gutter={8}>
                <Typography fontWeight={700}>Base URL</Typography>
              </Inline>
              <TextField
                control={control}
                name="input.baseUrl.value"
                disabled={true}
              />
            </div>

            <div>
              <Inline gutter={8}>
                <Typography fontWeight={700}>Authentication</Typography>
              </Inline>
              <TextField
                control={control}
                name="input.authentication.value"
                disabled={true}
              />
            </div>

            <div>
              <Inline gutter={8}>
                <Typography fontWeight={700}>URL Path</Typography>
              </Inline>

              <NectedEditorField
                control={control}
                name="input.path.value"
                mode={'singleLineText'}
                customSuggestions={tokens}
                methods="write"
                domain={editorDomain}
                handleGetExecData={handleGetExecutionValues}
                execValues={execValues}
                readOnly={isWorkflowReadOnly}
              />
            </div>

            <div>
              <KeyValueArray
                label="Headers"
                placeholder=""
                name="input.headers.value"
                type="map"
                regex={ACTION_KEY_REGEX}
                control={control}
                disabledKeyValuePairs={disabledHeaders}
                variant="ace"
                disabled={isWorkflowReadOnly}
                setValue={setValue}
                customSuggestions={tokens}
                autoChange
                tooltipText={getTooltipText(
                  siteConstants,
                  'integrations',
                  'presetHeaders'
                )}
                handleGetExecData={handleGetExecutionValues}
                execValues={execValues}
              />
            </div>

            <div>
              <KeyValueArray
                label="Query Parameters"
                placeholder=""
                name="input.queryParams.value"
                type="map"
                regex={ACTION_KEY_REGEX}
                control={control}
                disabledKeyValuePairs={disabledQueryParams}
                variant="ace"
                disabled={isWorkflowReadOnly}
                customSuggestions={tokens}
                tooltipText={getTooltipText(
                  siteConstants,
                  'integrations',
                  'presetQueryParams'
                )}
                handleGetExecData={handleGetExecutionValues}
                execValues={execValues}
              />
            </div>

            {!_isNil(method) && POST_REQUESTS.includes(method.value) && (
              <>
                <div>
                  <Inline gutter={8}>
                    <Typography fontWeight={700}>Content Type</Typography>
                    <TooltipReact id="content-type">
                      <Typography>
                        {getTooltipText(
                          siteConstants,
                          'rules',
                          'restApiContentType'
                        )}
                      </Typography>
                    </TooltipReact>
                  </Inline>

                  <DropdownField
                    control={control}
                    name="input.contentType.value"
                    options={bodyParamTypes}
                    disabled={isWorkflowReadOnly}
                  />
                </div>

                <BodyContainer>
                  <NectedEditorField
                    control={control}
                    name="input.body.value"
                    mode={bodyEditorMode}
                    customSuggestions={tokens}
                    methods="write"
                    onSetEditorValidity={setIsValidBodyQuery}
                    domain={editorDomain}
                    handleGetExecData={handleGetExecutionValues}
                    execValues={execValues}
                    readOnly={isWorkflowReadOnly}
                  />
                </BodyContainer>
                {!isValidBodyQuery && !_isEmpty(bodyQuery) && (
                  <SyntaxErrorContainer padding="1rem">
                    <Typography>
                      The statement written here is syntactically incorrect
                    </Typography>
                  </SyntaxErrorContainer>
                )}
              </>
            )}

            <div>
              <WhitelistIps whitelistIps={whitelistIps} />
            </div>
          </>
        )
      }
    </Stack>
  );
}
