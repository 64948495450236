import { atom, useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _reduce from 'lodash/reduce';
import { useEffect, useRef, useState } from 'react';
import { Dataset, PopoverMethods, PopoverPanel, RulePopover } from 'ui';

import { customAttributesAtom } from '../../../../../components/rules/forms/CustomAttributeSheet/CustomAttributeSheet';
import { generateUid, getPropertyIfExists } from '../../../../../utils/common';
import { isRuleReadOnlyAtom, selectedDataSetAtom } from '../../../index';
import {
  allConditionTypes,
  getDataTypeByParamType,
  getFreshRulesRhs,
  getParamNode,
  handleDatatypeComparison,
  handleDeleteElement,
  updateDataSetOnChange,
} from '../../../utils/common';
import { dataSetParamsAtom } from '../../CreateRuleSheet/CreateRuleSheet';
import { sendEventToGTMType } from '../RuleBlock/RuleBlock';
import { simpleNodeErrors, simpleRuleNodesAtom } from '../index';
import { ErrorByNodeId, SimpleRuleNodesModel } from '../models';
import { RuleLauncher } from './RuleLauncher';
import { PopoverContainerStyled } from './RuleParamPopover.styled';

export const simpleRuleNodeId = atom<string>('');

type RuleParamsPopoverProps = {
  ruleId: string;
  nodeId: string;
  isSqlNode?: boolean;
  isExcelCondition?: boolean;
  handleSendEventToGTM?: (obj: sendEventToGTMType) => void;
};

export function RuleParamPopover({
  ruleId,
  nodeId,
  isSqlNode = false,
  isExcelCondition = false,
  handleSendEventToGTM,
}: RuleParamsPopoverProps) {
  const [rules, setRules] = useAtom(simpleRuleNodesAtom);
  const [dataset] = useAtom(dataSetParamsAtom);
  const [filteredDataSet, setFilteredDataSet] =
    useState<Record<string, Dataset>>(dataset);

  const [errorByRuleId, setErrorByRuleId] = useAtom(simpleNodeErrors);
  const ref = useRef<PopoverMethods>(null);
  const [isRuleReadOnly] = useAtom(isRuleReadOnlyAtom);
  const [customAttributes] = useAtom(customAttributesAtom);
  const [dataSetSelected] = useAtom(selectedDataSetAtom);
  const [, setSelectedRuleId] = useAtom(simpleRuleNodeId);

  useEffect(() => {
    setFilteredDataSet(
      updateDataSetOnChange(customAttributes, dataset, dataSetSelected)
    );
  }, [customAttributes, dataset, dataSetSelected]);

  const error = !_isNil(errorByRuleId[nodeId])
    ? errorByRuleId[nodeId].message
    : null;

  const handleFunctionNodeConversion = (
    id: string,
    type: 'sqlCondition' | 'jsCondition' | 'excelCondition'
  ) => {
    const newNodeSqlNode = {
      nodeType: type,
      name: '',
      query: `1===1`,
      parent: rules[ruleId].parent,
      siblingIndex: rules[ruleId].siblingIndex,
    };
    const newRules = handleDeleteElement(id, rules);

    newRules[id] = newNodeSqlNode;
    newRules[newNodeSqlNode.parent].children?.push(id);

    setRules(newRules);
  };

  const handleConvertToConditionNode = (
    id: string,
    dataType: string,
    sourceType: string,
    attribute: string
  ) => {
    const leftNode = { ...getParamNode(id), attribute, sourceType };
    const rightNode = { ...getParamNode(id) };

    const leftNodeId = generateUid('rule_');
    const rightNodeId = generateUid('rule_');

    const newConditionNode = {
      nodeType: 'condition',
      parent: rules[id].parent,
      siblingIndex: rules[id].siblingIndex,
      dataType: getDataTypeByParamType(dataType),
      leftNode: [leftNodeId],
      rightNode: [rightNodeId],
    };

    const newRules = handleDeleteElement(id, rules);
    newRules[id] = newConditionNode;
    newRules[leftNodeId] = leftNode;
    newRules[rightNodeId] = rightNode;
    newRules[newConditionNode.parent].children?.push(id);
    setRules(newRules);
  };

  const handleRuleParamClick = (
    attribute: string,
    sourceType: string,
    dataType: string
  ) => {
    if (typeof handleSendEventToGTM === 'function') {
      handleSendEventToGTM({
        action: 'selection',
        element: 'property',
        actionName: dataType,
      });
    }

    if ((isSqlNode || isExcelCondition) && sourceType !== 'custom') {
      handleConvertToConditionNode(ruleId, dataType, sourceType, attribute);

      return;
    } else if (
      ['sqlCondition', 'jsCondition', 'excelCondition'].includes(
        rules[nodeId].nodeType
      ) &&
      rules[nodeId].nodeType === dataType
    ) {
      ref.current?.hide();

      return;
    } else if (sourceType === 'custom' && dataType !== rules[nodeId].nodeType) {
      if (dataType === 'sqlCondition') {
        handleFunctionNodeConversion(ruleId, 'sqlCondition');
      } else if (dataType === 'excelCondition') {
        handleFunctionNodeConversion(ruleId, 'excelCondition');
      } else {
        handleFunctionNodeConversion(ruleId, 'jsCondition');
      }

      return;
    } else if (!handleDatatypeComparison(dataType, rules[ruleId].dataType)) {
      const rightNodes: string[] = rules[ruleId].rightNode ?? [];
      const updatedRules: Record<string, SimpleRuleNodesModel> =
        getFreshRulesRhs(rules, ruleId, rightNodes, 1, dataType);

      setRules(updatedRules);

      setRules((prev) => ({
        ...prev,
        [ruleId]: {
          ...updatedRules[ruleId],
          dataType: getDataTypeByParamType(dataType),
          operator: '',
        },
      }));

      ref.current?.hide();
    }

    setErrorByRuleId((prev) =>
      _reduce(
        prev,
        (result: ErrorByNodeId, value, key) => {
          const rightNodes = rules[ruleId].rightNode;

          if (
            key === nodeId ||
            (!_isNil(rightNodes) && rightNodes.includes(key))
          ) {
            return result;
          }

          return {
            ...result,
            [key]: prev[key],
          };
        },
        {}
      )
    );

    setRules((prev) => ({
      ...prev,
      [nodeId]: {
        nodeType: 'params',
        sourceType,
        attribute,
        parent: ruleId,
        siblingIndex: rules[ruleId].siblingIndex,
        dataType: getDataTypeByParamType(dataType),
      },
    }));
  };

  const sourceType = rules[nodeId]?.sourceType;
  const ruleNodeAttribute = rules[nodeId]?.attribute ?? '';

  const dataType = rules[nodeId]?.dataType ?? '';

  const propertyValue =
    !_isNil(sourceType) && !_isEmpty(sourceType)
      ? getPropertyIfExists(
          JSON.parse(
            JSON.stringify(
              Object.keys(dataset[sourceType]?.attributes ?? {}).reduce(
                (acc, curr) => {
                  return {
                    ...acc,
                    [curr]:
                      dataset[sourceType ?? ''].attributes[`${curr}`]
                        .executedValue,
                  };
                },
                {}
              )
            )
          ) ?? {},
          ruleNodeAttribute ?? ''
        )
      : ruleNodeAttribute;

  const getDataTypeAndText = () => {
    if (isSqlNode) {
      return {
        text: 'JS Code',
        dataType: 'jsCondition',
      };
    } else if (isExcelCondition) {
      return {
        text: 'Formula',
        dataType: 'excelCondition',
      };
    }

    return {
      text: ruleNodeAttribute,
      dataType,
    };
  };

  const nodeDetails = getDataTypeAndText();

  return (
    <PopoverPanel
      trigger="click"
      placement="bottom-start"
      launcher={
        <RuleLauncher
          id={nodeId}
          error={error}
          text={nodeDetails.text}
          propertyValue={propertyValue}
          dataType={nodeDetails.dataType}
          handleSendEventToGTM={handleSendEventToGTM}
        />
      }
      padding="8px"
      ref={ref}
      disabled={isRuleReadOnly}
    >
      <PopoverContainerStyled>
        <RulePopover
          dataset={filteredDataSet}
          version="v2"
          allowList
          typesToAllow={allConditionTypes}
          onClick={({ value, key, dataType }) => {
            handleRuleParamClick(value, key, dataType);

            if (dataType === 'jsCondition' || dataType === 'excelCondition') {
              setSelectedRuleId(ruleId);
            }
          }}
        />
      </PopoverContainerStyled>
    </PopoverPanel>
  );
}
