import Cookies from 'js-cookie';
import _isNil from 'lodash/isNil';
import { Outlet } from 'react-router-dom';
import Slider from 'react-slick';

import { envMap } from '../../utils/constant';
import { AuthBanner } from '../banner/AuthBanner';
import { NectedLogo } from '../logo';
import {
  AuthBannerContainer,
  AuthConatinerWrapper,
  AuthenticationContainer,
  LogoContainer,
  PadBoxStyled,
  PageContentContainer,
  PageContentWithLogo,
  SlickContainer,
} from './Authentication.styled';

export function Authentication() {
  const isScrollable =
    _isNil(Cookies.get('cc_cookie')) &&
    envMap.VITE_COOKIE_CONSENT_ENABLED === 'true';

  const assets: string[] = [
    `${envMap.VITE_ASSETS_URL}home/signBackground-1.svg`,
    `${envMap.VITE_ASSETS_URL}home/signBackground-2.svg`,
  ];
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    waitForAnimate: false,
    autoplay: true,
    autoplaySpeed: 2500,
  };

  return (
    <AuthConatinerWrapper isScrollable={isScrollable}>
      <AuthenticationContainer>
        <AuthBannerContainer>
          <AuthBanner />
          <SlickContainer>
            <Slider {...settings}>
              {assets.map((i, index) => {
                return (
                  <div key={index}>
                    <img src={i} alt="slider-image" />
                  </div>
                );
              })}
            </Slider>
          </SlickContainer>
        </AuthBannerContainer>

        <PageContentWithLogo gutter="0rem">
          <LogoContainer justify="start">
            <PadBoxStyled>
              <NectedLogo />
            </PadBoxStyled>
          </LogoContainer>

          <PageContentContainer>
            <Outlet />
          </PageContentContainer>
        </PageContentWithLogo>
      </AuthenticationContainer>
    </AuthConatinerWrapper>
  );
}
