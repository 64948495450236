import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import { Tab, TabList, TabPanel, TabPanels, Tabs, Typography } from 'ui';

import { Header } from '../../components/Header';
import { PermissionType } from '../../components/PermissionComponent/constant';
import { PagesBodyContainer } from '../../components/layouts/PagesBodyContainer';
import { SITE_META } from '../../utils/common';
import { Team } from './component/Team';
import { WorkspaceSettings } from './component/WorkspaceSettings';

type TabProp = {
  enabled?: boolean;
  permissions?: Record<PermissionType, boolean>;
};

type WorkspaceProp = {
  subModules: { settings?: TabProp; invite?: TabProp };
};

export function Workspace(props: WorkspaceProp) {
  const params = useParams();
  const navigate = useNavigate();
  const settingsConfig = props.subModules?.settings ?? {};
  const settingTabEnabled = settingsConfig.enabled ?? false;
  const inviteConfig = props.subModules?.invite ?? {};
  const inviteTabEnabled = inviteConfig?.enabled ?? false;

  const defaultActive = params.tabName === 'team' ? 1 : 0;

  return (
    <PagesBodyContainer gutter={16}>
      <Helmet>
        <title>
          {SITE_META[window.location.pathname.substring(1)]?.title ?? ''}
        </title>
        <meta
          name="description"
          content={
            SITE_META[window.location.pathname.substring(1)]?.description ?? ''
          }
        />
      </Helmet>
      <Header
        content={
          <Typography name="heading1" fontWeight={700}>
            Workspace
          </Typography>
        }
      />
      <Tabs
        defaultOpen={defaultActive}
        onTabChange={(index) => {
          if (index === 1) {
            navigate('/workspace/team');
          } else {
            navigate('/workspace/settings');
          }
        }}
      >
        <TabList>
          {settingTabEnabled ? <Tab>Settings</Tab> : <></>}
          {inviteTabEnabled ? <Tab>Team</Tab> : <></>}
        </TabList>

        <TabPanels>
          <TabPanel>
            <WorkspaceSettings />
          </TabPanel>
          <TabPanel>
            <Team />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </PagesBodyContainer>
  );
}
