import styled, { css } from 'styled-components';
import { Typography } from 'ui';

type TriggerTabStyledProps = {
  $active: boolean;
};

export const TriggerTabStyled = styled.button<TriggerTabStyledProps>`
  padding: 0.4em 2em;
  border: none;
  background: var(--color-whiteSmoke);
  border-radius: 4px;
  cursor: pointer;
  ${({ $active }) =>
    $active &&
    css`
      background-color: var(--color-aliceBlue);
    `}

  &.btn-disabled-interceptor {
    background: var(--color-whiteSmoke) !important;
    color: var(--color-black) !important;
    cursor: progress !important;

    ${({ $active }) =>
      $active &&
      css`
        background-color: var(--color-aliceBlue);
      `}
  }
`;

export const ScheduleCronText = styled(Typography)`
  color: var(--color-dodgerBlue);
  cursor: pointer;
`;

export const Separator = styled.div`
  border: 1px solid var(--color-silver);
`;
