import styled, { css } from 'styled-components';
import theme from 'styled-theming';

type ButtonProps = {
  disabled: boolean;
};

const disabledCssRules = css`
  cursor: not-allowed;
  color: var(--color-darkGray);
`;

const buttonAppearance = theme('appearance', {
  contained: css`
    border-color: var(--color-dodgerBlue);
    background-color: var(--color-dodgerBlue);
    color: var(--color-white);

    &.btn-disabled-interceptor {
      cursor: progress;
    }

    &:not(.btn-disabled-interceptor):disabled {
      ${disabledCssRules};
      background-color: var(--color-solitude);
      border-color: var(--color-solitude);
    }
  `,
  contained2: css`
    border-color: var(--color-dodgerBlue);
    background-color: var(--color-dodgerBlue);
    color: var(--color-white);

    &.btn-disabled-interceptor {
      cursor: progress;
    }

    &:not(.btn-disabled-interceptor):disabled {
      ${disabledCssRules};
      background-color: var(--color-white);
      border-color: var(--color-white);
    }
  `,
  bordered: css`
    border-color: var(--color-dodgerBlue);
    background-color: var(--color-white);
    color: var(--color-dodgerBlue);

    &.btn-disabled-interceptor {
      cursor: progress;
    }

    &:not(.btn-disabled-interceptor):disabled {
      ${disabledCssRules};
      border-color: var(--color-solitude);
    }
  `,
  borderedNeutral: css`
    border-color: var(--color-whiteSmoke);
    background-color: var(--color-white);
    color: var(--color-black);

    &.btn-disabled-interceptor {
      cursor: progress;
    }

    &:not(.btn-disabled-interceptor):disabled {
      ${disabledCssRules};
      border-color: var(--color-solitude);
    }
  `,
  'bordered-black': css`
    border-color: var(--color-solitude);
    background-color: var(--color-white);
    color: var(--color-black);

    &.btn-disabled-interceptor {
      cursor: progress;
    }

    &:not(.btn-disabled-interceptor):disabled {
      ${disabledCssRules};
      background-color: var(--color-whiteSmoke);
    }
  `,
  'bordered-danger': css`
    border-color: var(--color-fireEngineRed);
    background-color: var(--color-white);
    color: var(--color-fireEngineRed);

    &.btn-disabled-interceptor {
      cursor: progress;
    }

    &:not(.btn-disabled-interceptor):disabled {
      ${disabledCssRules};
      background-color: var(--color-whiteSmoke);
    }
  `,
  filled: css`
    border-color: var(--color-aliceBlue);
    background-color: var(--color-aliceBlue);
    color: var(--color-dodgerBlue);

    &.btn-disabled-interceptor {
      cursor: progress;
    }

    &:not(.btn-disabled-interceptor):disabled {
      ${disabledCssRules};
      background-color: var(--color-white);
      border-color: var(--color-solitude);
    }
  `,
  text: css`
    border-color: transparent;
    color: var(--color-dodgerBlue);

    &.btn-disabled-interceptor {
      cursor: progress;
    }

    &:not(.btn-disabled-interceptor):disabled {
      ${disabledCssRules}
    }
  `,
  danger: css`
    border-color: var(--color-fireEngineRed);
    background-color: var(--color-fireEngineRed);
    color: var(--color-white);

    &.btn-disabled-interceptor {
      cursor: progress;
    }

    &:not(.btn-disabled-interceptor):disabled {
      ${disabledCssRules};
      background-color: var(--color-white);
      border-color: var(--color-solitude);
    }
  `,
  neutral: css`
    border-color: var(--color-whiteSmoke);
    background-color: var(--color-whiteSmoke);
    color: var(--color-black);

    &.btn-disabled-interceptor {
      cursor: progress;
    }

    &:not(.btn-disabled-interceptor):disabled {
      ${disabledCssRules};
      background-color: var(--color-white);
      border-color: var(--color-solitude);
    }
  `,
  neutralWhite: css`
    border-color: var(--color-white);
    background-color: var(--color-white);
    color: var(--color-black);

    &.btn-disabled-interceptor {
      cursor: progress;
    }

    &:not(.btn-disabled-interceptor):disabled {
      ${disabledCssRules};
      background-color: var(--color-white);
      border-color: var(--color-solitude);
    }
  `,
  filledProgressRed: css`
    border-color: var(--color-progressRed);
    background-color: var(--color-progressRed);
    color: var(--color-white);

    &.btn-disabled-interceptor {
      cursor: progress;
    }

    &:not(.btn-disabled-interceptor):disabled {
      ${disabledCssRules};
      background-color: var(--color-white);
      border-color: var(--color-solitude);
    }
  `,
});

const buttonSize = theme('size', {
  xs: css`
    font-size: 1rem;
    padding: 0.2rem 1rem;
  `,
  small: css`
    font-size: 1.4rem;
    padding: var(--padding-buttonSmallVertical)
      var(--padding-buttonSmallHorizontal);
  `,
  medium: css`
    font-size: 1.4rem;
    padding: var(--padding-buttonMediumVertical)
      var(--padding-buttonMediumHorizontal);
  `,
  large: css`
    font-size: 1.6rem;
    padding: var(--padding-buttonLargeVertical)
      var(--padding-buttonLargeHorizontal);
  `,
});

export const buttonBorderCss = css`
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
`;

export const ButtonStyled = styled.button<ButtonProps>`
  cursor: pointer;
  ${buttonBorderCss}
  ${buttonAppearance}
  ${buttonSize}
`;
