import { PadBox } from '@bedrock-layout/primitives';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Typography } from 'ui';

const textOverflowCSS = css`
  white-space: nowrap;
  max-width: 25rem;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ProfileNameContainer = styled(PadBox)`
  background-color: var(--color-whiteSmoke);
  border-radius: 4px;
  max-width: 30rem;
  min-width: 20rem;
`;

export const ProfileName = styled(Typography)`
  font-size: 1.5rem;
  color: var(--color-dodgerBlue);
  ${textOverflowCSS};
`;

export const ProfileEmail = styled(Typography)`
  font-size: 1.3rem;
  color: var(--color-charcoal);
  ${textOverflowCSS};
`;

export const LinkStyled = styled(Link)`
  text-decoration: none;
  color: var(--color-dodgerBlue);
`;
