import { Stack } from '@bedrock-layout/stack';
import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import { useEffect } from 'react';
import { BiSolidError } from 'react-icons/bi';
import { Button, Typography } from 'ui';

import { siteConstantsAtom } from '../../../atom';
import { HowToLink } from '../../../components/HowToLink/HowToLink';
import { getTooltipText } from '../../../utils/common';
import { dataSetParamsAtom } from '../components/CreateRuleSheet/CreateRuleSheet';
import { selectedDataSetAtom } from '../index';
import { FieldsByID } from '../types';
import { ErrorMessage } from './styles/InitializeDataSets';

type UseInitializeDataSetsArgs = {
  dataSetSelected: string[];
  dataSetFieldsById: FieldsByID;
};

export function useInitializeDataSets({
  dataSetSelected,
  dataSetFieldsById,
}: UseInitializeDataSetsArgs) {
  const [, setDatasetParams] = useAtom(dataSetParamsAtom);
  const [siteConstants] = useAtom(siteConstantsAtom);
  const [selectedDataSets] = useAtom(selectedDataSetAtom);

  const dataSetParamSetting = () => {
    if (
      !_isNil(dataSetSelected) &&
      !_isNil(dataSetFieldsById) &&
      !_isNil(dataSetFieldsById[dataSetSelected[0]])
    ) {
      const attributes = dataSetFieldsById[dataSetSelected[0]].fields.reduce(
        (acc, curr) => {
          return {
            ...acc,
            [curr.name]: {
              name: curr.name,
              dataType: curr.type,
              executedValue: curr.executedValue,
            },
          };
        },
        {}
      );

      setDatasetParams((prev) => ({
        ...prev,
        dataSet: {
          name: dataSetFieldsById[dataSetSelected[0]].name,
          id: 'dataSet',
          order: 3,
          tooltip: (
            <span>
              {getTooltipText(siteConstants, 'rules', 'condDataset')}
              <HowToLink
                variant="link"
                link={getTooltipText(
                  siteConstants,
                  'rules',
                  'condDatasetHowTo',
                  'howToLinks'
                )}
              />
            </span>
          ),
          attributes,
          footer:
            Object.keys(attributes).length === 0 ? (
              <Stack gutter={8}>
                <ErrorMessage align="center" gutter={8}>
                  <BiSolidError size={20} />
                  <Typography name="error">
                    Execute and Test Data Source first
                  </Typography>
                </ErrorMessage>
                <Button
                  onClick={() => {
                    window.open(
                      `/datasets/${
                        selectedDataSets[0]
                      }?type=edit&stage=staging&editor=${
                        dataSetFieldsById[selectedDataSets[0]].type as string
                      }`,
                      '_blank'
                    );
                  }}
                >
                  Go to Data Source
                </Button>
              </Stack>
            ) : undefined,
        },
      }));
    }
  };

  useEffect(() => {
    dataSetParamSetting();
  }, [dataSetFieldsById, dataSetSelected, siteConstants]);
}
