import { ObservableQuery } from '@apollo/client';
import { Inline, PadBox } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import { MdCable } from 'react-icons/md';
import {
  Image,
  Sheet,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Typography,
  useCurrentLayer,
  useLayer,
} from 'ui';

import { siteConstantsAtom, userProfileAtom } from '../../../../atom';
import { EntityDependencyUsage } from '../../../../components/EntityDependencyUsage/EntityDependencyUsage';
import { HowToLink } from '../../../../components/HowToLink/HowToLink';
import { roleJsonAtom } from '../../../../components/authentication/router/AuthProvider';
import { useGetPermissionsByEntity } from '../../../../hooks/restApi/useGetPermissionsByEntity';
import { getTooltipText, isValidImageURL } from '../../../../utils/common';
import { ENTITY_ID } from '../../../../utils/constant';
import { currentWorkspaceDetailAtom } from '../../../Workspace/atom';
import type {
  IntegrationCommonProps,
  PluginParams,
  SortedPluginParamsFieldKeys,
} from '../../types';
import type { ToggleEnvironmentFunction } from '../hooks/useCurrentEnvironment';
import { useGetWhitelistIps } from '../hooks/useGetWhitelistIps';
import { IntegrationForm } from './IntegrationForm';
import {
  IntegrationSheetContainer,
  IntegrationUsageContainer,
  TabsContainer,
} from './IntegrationSheet.styled';

type IntegrationSheetUIProps = IntegrationCommonProps & {
  displayName: string;
  imageUrl: string;
  stagingSortedFields: SortedPluginParamsFieldKeys[];
  productionSortedFields: SortedPluginParamsFieldKeys[];
  formJson: PluginParams;
  pluginName: string;
  formType: string;
  pluginId: string;
  toggleEnvironment: ToggleEnvironmentFunction;
  shouldFormRender: boolean;
  currentStage?: string;
  from?: string;
  onPublish?: () => void;
  refetchAuthKeys?: ObservableQuery<any>['refetch'];
};

const stageMap: Record<string, number> = {
  staging: 0,
  production: 1,
};

export function IntegrationSheet({
  connectorId,
  connectorName,
  connectorStatus,
  displayName,
  pluginName,
  imageUrl,
  isCreatingOrUpdating,
  isLoading,
  onSubmit,
  productionSortedFields,
  publishConnector,
  shouldFormRender,
  stagingSortedFields,
  formJson,
  formType,
  pluginId,
  toggleEnvironment,
  updateConnectorStatus,
  currentStage,
  from = '',
  onPublish,
  refetchAuthKeys,
}: IntegrationSheetUIProps) {
  const whitelistIps = useGetWhitelistIps();

  const [siteConstants] = useAtom(siteConstantsAtom);
  const [resetInitialData, setResetInitialData] = useState(0);
  const [localConnName, setLocalConnName] = useState(connectorName);

  const { openWithProps: openDependencyUsage } = useLayer(
    <EntityDependencyUsage
      entityInfo={{
        type: ENTITY_ID.integrations,
        status: '',
        name: localConnName,
        id: connectorId ?? '',
        subType: pluginName,
      }}
      openInSheet
    />
  );

  const integrationFormProps = {
    connectorId,
    connectorName,
    connectorStatus,
    isCreatingOrUpdating,
    isLoading,
    onSubmit,
    publishConnector,
    shouldFormRender,
    updateConnectorStatus,
    whitelistIps,
  };
  const { close } = useCurrentLayer();

  const [currentWorkspace] = useAtom(currentWorkspaceDetailAtom);

  const [, setRoleJson] = useAtom(roleJsonAtom);
  const [userProfile] = useAtom(userProfileAtom);

  const { getUserPermissionsByEntity } = useGetPermissionsByEntity();

  const email = !_isEmpty(userProfile) ? userProfile?.email : '';

  useEffect(() => {
    const role = currentWorkspace?.role ?? '';
    const wid = currentWorkspace?.uuid ?? '';

    const user = window.btoa(email ?? '');

    void getUserPermissionsByEntity(
      user,
      role,
      wid,
      'credentials',
      setRoleJson
    );
  }, []);

  const handleSheetClose = () => {
    close();

    if (from !== 'rules') {
      window.history.back();
    }
  };

  const handleGetHowToForConnectors = () => {
    switch (displayName) {
      case 'REST Api':
        return getTooltipText(
          siteConstants,
          'integrations',
          'restApiHowTo',
          'howToLinks'
        );

      case 'MongoDB':
        return getTooltipText(
          siteConstants,
          'integrations',
          'mongoDbHowTo',
          'howToLinks'
        );

      case 'PostgreSQL':
        return getTooltipText(
          siteConstants,
          'integrations',
          'postgresSqlHowTo',
          'howToLinks'
        );

      case 'MySQL':
        return getTooltipText(
          siteConstants,
          'integrations',
          'mySqlHowTo',
          'howToLinks'
        );

      case 'MS SQL Server':
        return getTooltipText(
          siteConstants,
          'integrations',
          'sqlServerHowTo',
          'howToLinks'
        );
    }

    return undefined;
  };

  const openIntegrationUsage = () => {
    openDependencyUsage({
      entityInfo: {
        type: ENTITY_ID.integrations,
        status: '',
        name: localConnName,
        id: connectorId ?? '',
        subType: pluginName,
        iconUrl: imageUrl,
      },
    });
  };

  return (
    <Sheet size="small" onClose={handleSheetClose}>
      <IntegrationSheetContainer>
        <PadBox padding="1rem">
          <Inline stretch="start" align="center">
            <Inline gutter="1.6rem" align="center">
              <Image
                src={isValidImageURL(imageUrl)}
                alt={displayName}
                size="large"
              />

              <Typography name="heading2">{displayName}</Typography>
            </Inline>

            <IntegrationUsageContainer onClick={openIntegrationUsage}>
              <MdCable color="var(--color-dodgerBlue)" />
            </IntegrationUsageContainer>

            <HowToLink link={handleGetHowToForConnectors()} />
          </Inline>
        </PadBox>

        <TabsContainer>
          <Tabs
            onTabChange={toggleEnvironment}
            defaultOpen={!_isNil(currentStage) ? stageMap[currentStage] : 0}
          >
            <TabList>
              <Tab>Staging</Tab>

              <Tab>Production</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <IntegrationForm
                  sortedFields={stagingSortedFields}
                  formJson={formJson}
                  formType={formType}
                  pluginId={pluginId}
                  {...integrationFormProps}
                  currentEnvironment="staging"
                  key="staging"
                  from={from}
                  onPublish={onPublish}
                  refetchAuthKeys={refetchAuthKeys}
                  pluginType={displayName}
                  pluginName={pluginName}
                  resetInitialData={resetInitialData}
                  setResetInitialData={setResetInitialData}
                  setLocalConnName={setLocalConnName}
                />
              </TabPanel>

              <TabPanel>
                <IntegrationForm
                  sortedFields={productionSortedFields}
                  formJson={formJson}
                  formType={formType}
                  pluginId={pluginId}
                  {...integrationFormProps}
                  currentEnvironment="production"
                  key="production"
                  from={from}
                  onPublish={onPublish}
                  refetchAuthKeys={refetchAuthKeys}
                  pluginType={displayName}
                  pluginName={pluginName}
                  resetInitialData={resetInitialData}
                  setResetInitialData={setResetInitialData}
                  setLocalConnName={setLocalConnName}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </TabsContainer>
      </IntegrationSheetContainer>
    </Sheet>
  );
}
