import { Inline } from '@bedrock-layout/primitives';
import styled from 'styled-components';

import { StackAsItem } from '../../../../components/layouts/Stack.styled';

export const RuleExecutionTestStyled = styled(StackAsItem)`
  border: 1px solid var(--color-whiteSmoke);
  border-radius: 4px;
  overflow-y: auto;
`;

export const ActionCount = styled(Inline)`
  inline-size: 2rem;
  block-size: 2rem;
  border-radius: 50%;
  background-color: var(--color-aliceBlue);
  color: var(--color-navyBlue);
`;

export const ErrorIconContainer = styled(Inline)`
  block-size: 2rem;
`;
