import { useAtom } from 'jotai';
import { Control, useWatch } from 'react-hook-form';
import { Image, RulesPopover, TextButton } from 'ui';

import { useSendEventToGTM } from '../../../../../hooks/useSendEventToGTM';
import { envMap } from '../../../../../utils/constant';
import { filteredRuleOptionsAtom, ruleSetNodeId } from '../RuleSet';
import { AddFieldStyled } from './AddRuleMenu.styled';

export type AddRuleMenuProps = {
  onClick: (id: string) => void;
  control?: Control<any>;
};

export function AddRuleMenu({ onClick, control }: AddRuleMenuProps) {
  const [filteredRuleOptions] = useAtom(filteredRuleOptionsAtom);

  const [ruleId] = useAtom(ruleSetNodeId);

  const ruleName = useWatch({
    control,
    name: 'ruleName',
  });

  const { sendEventToGTM } = useSendEventToGTM();

  const handleRuleMenuOpen = () => {
    sendEventToGTM({
      event: 'rule',
      ruleId,
      ruleName,
      type: 'ruleSet',
      action: 'add_rule',
      nec_source: '',
    });
  };

  const launcher = (
    <AddFieldStyled gutter={8} onClick={handleRuleMenuOpen}>
      <Image
        src={`${envMap.VITE_ASSETS_URL}website/icons/plus.svg`}
        alt="plus"
        size="xs"
      />

      <TextButton>Add Rule</TextButton>
    </AddFieldStyled>
  );

  return (
    <RulesPopover
      options={filteredRuleOptions}
      onChange={(item) => {
        onClick(item);
      }}
      launcher={launcher}
    />
  );
}
