import { Column, Columns, Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import {
  Control,
  type UseControllerProps,
  UseFormSetValue,
  useWatch,
} from 'react-hook-form';
import {
  Dataset,
  DropdownField,
  EditorLanguages,
  NectedEditorField,
  TooltipReact,
  Typography,
} from 'ui';

import { siteConstantsAtom } from '../../../../atom';
import { useGetConnectorById } from '../../../../hooks/graphql/useGetConnectorById';
import { integrationComponents } from '../../../../pages/Integrations/components/common/IntegrationForm';
import { useGetWhitelistIps } from '../../../../pages/Integrations/components/hooks/useGetWhitelistIps';
import { KeyValuePairList } from '../../../../pages/Integrations/types';
import { createRuleSheetAtom } from '../../../../pages/Rules';
import { dataSetParamsAtom } from '../../../../pages/Rules/components/CreateRuleSheet/CreateRuleSheet';
import { additionalDatasetAtom } from '../../../../pages/Rules/components/DecisionTable/components/DecisionTableActions';
import { Connector } from '../../../../pages/Rules/components/DecisionTable/types';
import { outputDatasetAtom } from '../../../../pages/Rules/components/SimpleRule/Results/DataActions';
import { useUpdateExecutedValueRules } from '../../../../pages/Rules/hooks/useUpdateExecutedValueRules';
import { useGenerateDataset } from '../../../../pages/Workflow/hooks/useGenerateDataset';
import {
  getTooltipText,
  updateDatasetVariable,
} from '../../../../utils/common';
import {
  POST_REQUESTS,
  bodyParamTypes,
  editorDomain,
  methods,
} from '../../../../utils/constant';
import { ACTION_KEY_REGEX } from '../../../../utils/regex';
import { WhitelistIps } from '../../../ConnectorComponents/WhitelistIps/WhitelistIps';
import { SyntaxErrorContainer } from '../../ActionSheet.styled';
import { BaseUrl, BodyContainer, LinkStyled } from './RestAPI.styled';

type RestAPIProps = Omit<UseControllerProps, 'name'> & {
  sectionName: string;
  index: number;
  connectorId: string;
  disabledHeaders: KeyValuePairList[];
  disabled: boolean;
  disabledQueryParams: KeyValuePairList[];
  connector?: Connector | null;
  mainControl?: Control<any>;
  setValue?: UseFormSetValue<any>;
  updatedLocalCI?: Record<string, any>;
  hideOutput?: boolean;
};

const getBaseUrl = (connector: Connector | null | undefined) => {
  if (!_isNil(connector) && !_isNil(connector.staging?.conf)) {
    return connector.staging?.conf.baseUrl ?? '';
  }

  return '-';
};

const getAuthentication = (connector: Connector | null | undefined) => {
  if (!_isNil(connector) && !_isNil(connector.staging?.conf)) {
    return !_isNil(connector.staging?.conf.credentialId) &&
      !_isEmpty(connector.staging?.conf.credentialId)
      ? 'Private'
      : 'None';
  }

  return '-';
};

export function RestAPI({
  control,
  sectionName,
  index,
  connectorId,
  disabledHeaders,
  disabledQueryParams,
  connector,
  mainControl,
  disabled = false,
  setValue,
  updatedLocalCI,
  hideOutput = false,
}: RestAPIProps) {
  const [ruleType] = useAtom(createRuleSheetAtom);
  const [dataSetVariables] = useAtom(dataSetParamsAtom);

  const [siteConstants] = useAtom(siteConstantsAtom);

  const [updatedDataset, setUpdatedDataset] = useState<Record<string, Dataset>>(
    {}
  );
  const [bodyEditorMode, setBodyEditorMode] = useState<EditorLanguages>('text');
  const [isValidBodyQuery, setIsValidBodyQuery] = useState(true);

  const [outputDataset] = useAtom(outputDatasetAtom);
  const [additionalDataSet] = useAtom(additionalDatasetAtom);

  const method = useWatch({
    name: `action.config.method`,
    control,
  });

  const contentType = useWatch({
    name: 'action.config.contentType',
    control,
  });

  const bodyQuery = useWatch({
    name: 'action.config.body',
    control,
  });

  const KeyValueArray = integrationComponents.map;

  const whitelistIps = useGetWhitelistIps();

  const [getConnectorById] = useGetConnectorById();

  const handleGetConnectorByID = async () => {
    try {
      const data = await getConnectorById({
        variables: {
          connectorId,
        },
      });

      // eslint-disable-next-line
      const datApp = data.data?.getConnector.data[0];
    } catch (error) {}
  };

  const { tokens: dataSetSuggestionsObj } = useGenerateDataset({
    updatedDataset,
  });

  const { executedValue, handleGetExecutionValues } =
    useUpdateExecutedValueRules({
      updatedDataset,
    });

  useEffect(() => {
    if (!_isNil(connectorId) && !_isEmpty(connectorId)) {
      void handleGetConnectorByID();
    }
  }, [connectorId]);

  useEffect(() => {
    if (!_isNil(dataSetVariables)) {
      const updatedDSVariables = !_isNil(updatedLocalCI)
        ? updateDatasetVariable(updatedLocalCI, dataSetVariables)
        : dataSetVariables;

      setUpdatedDataset((prev) => ({
        ...prev,
        ...updatedDSVariables,
      }));
    }
  }, [dataSetVariables, updatedLocalCI]);

  useEffect(() => {
    if (ruleType === 'decisionTable') {
      setUpdatedDataset((prev) => ({
        ...prev,
        ...additionalDataSet,
      }));
    }
  }, [additionalDataSet]);

  useEffect(() => {
    if (ruleType === 'simpleRule' && !hideOutput) {
      setUpdatedDataset((prev) => ({
        ...prev,
        ...outputDataset,
      }));
    }
  }, [outputDataset]);

  useEffect(() => {
    const isJsonMode = ['application/json', 'application/javascript'].includes(
      contentType?.value
    );

    setBodyEditorMode(isJsonMode ? 'json' : 'text');
  }, [contentType]);

  return (
    <Stack>
      <Columns columns={2}>
        <Column span={1}>
          <div>
            <Inline gutter={8}>
              <Typography fontWeight={700}>Base URL</Typography>
              <TooltipReact id="action-rest-base-url" placement="bottom-start">
                <Typography>
                  {getTooltipText(siteConstants, 'integrations', 'baseUrl')}
                </Typography>
              </TooltipReact>
            </Inline>

            <BaseUrl>{getBaseUrl(connector)}</BaseUrl>
          </div>
        </Column>
        <Column span={1}>
          <div>
            <Inline gutter={8}>
              <Typography fontWeight={700}>Authentication</Typography>
              <TooltipReact
                id="action-rest-authentication"
                placement="bottom-start"
              >
                <Typography>
                  {getTooltipText(
                    siteConstants,
                    'integrations',
                    'authentication'
                  )}{' '}
                  <LinkStyled
                    to={`/integrations/${
                      connector?.id ?? ''
                    }?stage=staging&connector=${connector?.plugin.name ?? ''}`}
                    target="_blank"
                  >
                    Edit
                  </LinkStyled>
                </Typography>
              </TooltipReact>
            </Inline>

            <Inline>
              <Typography>{getAuthentication(connector)}</Typography>
            </Inline>
          </div>
        </Column>
      </Columns>

      <div>
        <Inline gutter={8}>
          <Typography fontWeight={700}>URL Path</Typography>
        </Inline>

        <NectedEditorField
          control={control}
          name="action.config.path"
          mode={'singleLineText'}
          customSuggestions={dataSetSuggestionsObj}
          methods="write"
          domain={editorDomain}
          handleGetExecData={handleGetExecutionValues}
          execValues={executedValue}
          readOnly={disabled}
        />
      </div>

      <div>
        <Inline gutter={8}>
          <Typography fontWeight={700}>Method</Typography>
          <TooltipReact id="api-method">
            <Typography>
              {getTooltipText(siteConstants, 'rules', 'restApiMethod')}
            </Typography>
          </TooltipReact>
        </Inline>

        <DropdownField
          control={control}
          name={`action.config.method`}
          options={methods}
          isDisabled={disabled}
        />
      </div>

      <div>
        <KeyValueArray
          label="Headers"
          placeholder=""
          name={`action.config.headers`}
          type="map"
          regex={ACTION_KEY_REGEX}
          control={control}
          disabledKeyValuePairs={disabledHeaders}
          variant="ace"
          disabled={disabled}
          setValue={setValue}
          customSuggestions={dataSetSuggestionsObj}
          autoChange
          tooltipText={getTooltipText(
            siteConstants,
            'integrations',
            'presetHeaders'
          )}
          handleGetExecData={handleGetExecutionValues}
          execValues={executedValue}
        />
      </div>

      <div>
        <KeyValueArray
          label="Query Parameters"
          placeholder=""
          name={`action.config.queryParams`}
          type="map"
          regex={ACTION_KEY_REGEX}
          control={control}
          disabledKeyValuePairs={disabledQueryParams}
          variant="ace"
          disabled={disabled}
          customSuggestions={dataSetSuggestionsObj}
          tooltipText={getTooltipText(
            siteConstants,
            'integrations',
            'presetQueryParams'
          )}
          handleGetExecData={handleGetExecutionValues}
          execValues={executedValue}
        />
      </div>

      {!_isNil(method) && POST_REQUESTS.includes(method.value) && (
        <>
          <div>
            <Inline gutter={8}>
              <Typography fontWeight={700}>Content Type</Typography>
              <TooltipReact id="content-type">
                <Typography>
                  {getTooltipText(siteConstants, 'rules', 'restApiContentType')}
                </Typography>
              </TooltipReact>
            </Inline>

            <DropdownField
              control={control}
              name={`action.config.contentType`}
              options={bodyParamTypes}
              isDisabled={disabled}
            />
          </div>

          <BodyContainer>
            <NectedEditorField
              control={control}
              name="action.config.body"
              mode={bodyEditorMode}
              customSuggestions={dataSetSuggestionsObj}
              methods="write"
              onSetEditorValidity={setIsValidBodyQuery}
              domain={editorDomain}
              handleGetExecData={handleGetExecutionValues}
              execValues={executedValue}
              readOnly={disabled}
            />
          </BodyContainer>
          {!isValidBodyQuery && !_isEmpty(bodyQuery) && (
            <SyntaxErrorContainer padding="1rem">
              <Typography>
                The statement written here is syntactically incorrect
              </Typography>
            </SyntaxErrorContainer>
          )}
        </>
      )}

      <div>
        <WhitelistIps whitelistIps={whitelistIps} />
      </div>
    </Stack>
  );
}
