import { PadBox } from '@bedrock-layout/padbox';

/* eslint-disable-next-line import/no-absolute-path */
import logo from '/assets/konark/images/logo.svg';

export const NectedLogo = () => {
  return <img src={logo} alt="Nected Logo" style={{ height: '3rem' }} />;
};

export const NectedLogoPadded = () => (
  <PadBox padding={['3.2rem', 0, 0, '3.2rem']}>
    <NectedLogo />
  </PadBox>
);
