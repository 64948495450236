import { Inline } from '@bedrock-layout/primitives';
import { Control, UseFormSetValue, useWatch } from 'react-hook-form';
import { CheckboxField, Dataset, TooltipReact, Typography, toasts } from 'ui';

import { useAxiosPrivate } from '../../hooks';
import ClearCacheButton from './ClearCacheButton/ClearCacheButton';
import { Duration } from './Duration/Duration';
import { ParameterPopover } from './ParameterPopover/ParameterPopover';
import { ResponseCacheItems, ResponseHeader } from './ResponseCaching.styled';

type ResponseCachingProps = {
  control?: Control<any>;
  setValue?: UseFormSetValue<any>;
  name: string;
  dataset?: Record<string, Dataset>;
  responseParams?: { type: string; entityId?: any };
  maxWidthOnTop?: string;
  disabled?: boolean;
};

export function ResponseCaching({
  control,
  name,
  setValue,
  dataset = {},
  responseParams,
  maxWidthOnTop,
  disabled = false,
}: ResponseCachingProps) {
  const isEnabled = useWatch({
    name: `${name}.enabled`,
    control,
  });

  const { axiosPrivate } = useAxiosPrivate();

  const handleClearClick = async (id: string) => {
    try {
      await axiosPrivate.patch('/api/v1/execution/cache', {
        ...(responseParams ?? {}),
        env: id === 'cache-staging' ? 'staging' : 'production',
      });

      toasts.success('Cache cleared successfully', 'success-clear-cache');
    } catch (error) {
      toasts.error('Unable to clear cache', 'fail-clear-cache');
    }
  };

  return (
    <div>
      <ResponseHeader
        $isActive={!!(isEnabled as boolean)}
        $maxWidthOnTop={maxWidthOnTop}
      >
        <Inline stretch="start" align="center">
          <Inline align="center" gutter={'1rem'}>
            <Typography>Response Caching</Typography>
            <TooltipReact id="baseurl-rule-id">
              What is Response Caching?
            </TooltipReact>
          </Inline>
          <CheckboxField
            name={`${name}.enabled`}
            appearance="switch"
            control={control}
            useId={`${name}.enabled`}
            disabled={disabled}
          />
        </Inline>
      </ResponseHeader>
      {!!(isEnabled as boolean) && (
        <ResponseCacheItems gutter="1rem" $disabled={disabled}>
          <ParameterPopover
            name={`${name}.cacheKeys`}
            control={control}
            setValue={setValue}
            dataset={dataset}
          />

          <Duration name={`${name}.duration`} control={control} />
          <Inline>
            <ClearCacheButton
              buttonOptionList={[
                {
                  id: 'cache-staging',
                  label: 'Clear Cache (Staging)',
                  subTitle: 'Staging cache will be cleared on click',
                },
                {
                  id: 'cache-production',
                  label: 'Clear Cache (Production)',
                  subTitle: 'Production cache will be cleared on click',
                },
              ]}
              defaultSelectedOptionId="cache-staging"
              onClick={handleClearClick}
            />
          </Inline>
        </ResponseCacheItems>
      )}
    </div>
  );
}
