import { useAtom } from 'jotai';
import { Control, useWatch } from 'react-hook-form';
import { FiPlusCircle } from 'react-icons/fi';
import { Image, Menu, MenuItem, Typography } from 'ui';

import { useSendEventToGTM } from '../../../../../hooks/useSendEventToGTM';
import { envMap } from '../../../../../utils/constant';
import { isRuleReadOnlyAtom } from '../../../index';
import { handleAddChildByGroup } from '../../../utils/common';
import { simpleRuleNodesAtom } from '../index';
import { SimpleRuleNodesModel } from '../models';
import { AddChildStyled } from './RuleAddMenu.styled';

type RuleAddMenuProps = {
  type: 'group' | 'condition';
  rule: SimpleRuleNodesModel;
  ruleId: string;
  control?: Control<any>;
};

export const RuleAddMenu = ({ type, ruleId, control }: RuleAddMenuProps) => {
  const [rules, setRules] = useAtom(simpleRuleNodesAtom);
  const [isRuleReadOnly] = useAtom(isRuleReadOnlyAtom);

  const { sendEventToGTM } = useSendEventToGTM();

  const ruleName = useWatch({
    control,
    name: 'ruleName',
  });

  const handleMenuOpen = () => {
    sendEventToGTM({
      event: 'rule',
      ruleId,
      ruleName,
      type: 'simpleRule',
      action: 'add',
      element: type,
      nec_source: '',
      action_name: '',
    });
  };

  const launcher = (
    <AddChildStyled
      $disabled={isRuleReadOnly}
      align="center"
      gutter={5}
      onClick={handleMenuOpen}
    >
      {isRuleReadOnly ? (
        <FiPlusCircle color="var(--color-darkGray)" />
      ) : (
        <Image
          src={`${envMap.VITE_ASSETS_URL}website/icons/plus.svg`}
          size="xs"
          alt="plus"
        />
      )}
      <Typography>Add {type === 'group' ? 'Group' : 'Condition'}</Typography>
    </AddChildStyled>
  );

  const onMenuItemClick = (value: string) => {
    if (isRuleReadOnly) {
      return;
    }

    sendEventToGTM({
      event: 'rule',
      ruleId,
      ruleName,
      type: 'simpleRule',
      action: 'selection',
      element: type,
      nec_source: '',
      action_name: value,
    });

    setRules(handleAddChildByGroup(ruleId, type, rules, value));
  };

  return (
    <Menu
      launcher={launcher}
      onMenuItemClick={onMenuItemClick}
      disabled={isRuleReadOnly}
    >
      <MenuItem value="and">
        <Typography>And</Typography>
      </MenuItem>

      <MenuItem value="or">
        <Typography>Or</Typography>
      </MenuItem>
    </Menu>
  );
};
