import { Center, Stack } from '@bedrock-layout/primitives';
import { zodResolver } from '@hookform/resolvers/zod';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Image, Link, TextField, Typography, toasts } from 'ui';
import { z } from 'zod';

import { generateCaptchaToken, getPublicIP } from '../../../utils/common';
import { envMap } from '../../../utils/constant';
import { passwordValidationSchema } from '../../../utils/schema';
import { FormContainer } from './ResetPassword.styled';
import { useResetPassword } from './useResetPassword';

const FormValidationSchema = z.object({
  ...passwordValidationSchema,
});

type ResetFormValues = {
  password: string;
  confirmPassword: string;
};

export const ResetPasswordForm = () => {
  const [togglePassword, setTogglePassword] = useState(false);
  const [toggleConfirmPassword, setToggleConfirmPassword] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { resetPassword, isLoading } = useResetPassword();

  const resetToken = searchParams.get('resetToken');

  useEffect(() => {
    if (_isEmpty(resetToken) && !_isNil(resetToken)) {
      toasts.error(
        'Reset token not found. Please submit for new request.',
        'error'
      );
      navigate('/forgot-password');
    }
    void getPublicIP();
  }, []);

  const { control, handleSubmit } = useForm<ResetFormValues>({
    resolver: zodResolver(FormValidationSchema),
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  });

  const onSubmit = ({ password, confirmPassword }: ResetFormValues) => {
    if (!_isEmpty(resetToken) && !_isNil(resetToken)) {
      generateCaptchaToken(resetPassword, {
        password,
        confirmPassword,
        resetToken,
      });
    }
  };

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <Stack gutter="4rem">
        <Stack gutter="2.4rem">
          <Center centerChildren>
            <Image
              size="self"
              src={`${envMap.VITE_ASSETS_URL}website/icons/lock-key.svg`}
              alt="Reset password lock-key icon"
            />
          </Center>

          <Center centerChildren>
            <Typography name="heading1" element="h1">
              Reset Password
            </Typography>

            <Typography name="secondary" element="h2">
              Please confirm your new password
            </Typography>
          </Center>
        </Stack>

        <Stack gutter="2.4rem">
          <Stack gutter="1.6rem">
            <TextField
              name="password"
              onClickInputIcon={() =>
                setTogglePassword((isShow: boolean) => !isShow)
              }
              icon={
                togglePassword ? (
                  <AiOutlineEye size={20} />
                ) : (
                  <AiOutlineEyeInvisible size={20} />
                )
              }
              control={control}
              label="Password"
              type={togglePassword ? 'text' : 'password'}
            />

            <TextField
              onClickInputIcon={() =>
                setToggleConfirmPassword((isShow: boolean) => !isShow)
              }
              name="confirmPassword"
              icon={
                toggleConfirmPassword ? (
                  <AiOutlineEye size={20} />
                ) : (
                  <AiOutlineEyeInvisible size={20} />
                )
              }
              control={control}
              label="Confirm Password"
              type={toggleConfirmPassword ? 'text' : 'password'}
            />
          </Stack>

          <Stack gutter="1.6rem">
            <Button type="submit" size="large" disabled={isLoading}>
              Reset Password
            </Button>

            <Center centerChildren>
              <Link to="/signin"> Log in</Link>
            </Center>
          </Stack>
        </Stack>
      </Stack>
    </FormContainer>
  );
};
