import { Image, Typography } from 'ui';

import { isValidImageURL } from '../../../../../../utils/common';
import type { ControlListType } from '../../../../models/models';
import {
  ControlBlockItemContainer,
  ControlIconContainer,
} from './ControlPopover.styled';

type ControlBlockItemProps = {
  item: ControlListType;
  onItemClick: (
    type: string,
    entityId?: string,
    meta?: Record<string, any>
  ) => void;
};

export function ControlBlockItem({ item, onItemClick }: ControlBlockItemProps) {
  return (
    <ControlBlockItemContainer
      align="center"
      gutter={8}
      onClick={() => onItemClick(item.type, item.entityId, item.metaData)}
    >
      <ControlIconContainer>
        {typeof item.icon === 'string' ? (
          <Image
            src={isValidImageURL(item.icon)}
            alt="minus"
            size="small"
            round
          />
        ) : (
          item.icon
        )}
      </ControlIconContainer>
      <Typography name="paragraphXs">{item.name}</Typography>
    </ControlBlockItemContainer>
  );
}
