import { Inline, PadBox, Stack } from '@bedrock-layout/primitives';
import type { Control } from 'react-hook-form';
import { useFieldArray } from 'react-hook-form';
import { Image, TextField, TooltipReact, Typography } from 'ui';

import { envMap } from '../../../../utils/constant';
import { AddItemStyled, IconButtonStyled } from './TestAddItems.styled';

type TestAddItemsProps = {
  name: 'headers' | 'queryParams';
  label: string;
  tooltipText: string;
  control?: Control<any>;
};

export function TestAddItems({
  label,
  name,
  control,
  tooltipText,
}: TestAddItemsProps) {
  const { fields, append, remove } = useFieldArray({
    name,
    control,
  });

  const addLabel = name === 'headers' ? 'Headers' : 'Query Parameters';

  return (
    <PadBox>
      <Inline>
        <Typography fontWeight={700}>{label}</Typography>
        <TooltipReact id={`test-${name}`} placement="right-start">
          <Typography>{tooltipText}</Typography>
        </TooltipReact>
      </Inline>
      <Stack gutter={8}>
        {fields.map((field, index) => (
          <Inline gutter={16} key={field.id} align="start">
            <TextField
              name={`${name}.${index}.key`}
              control={control}
              showErrorIcon={false}
            />
            <TextField name={`${name}.${index}.value`} control={control} />
            <IconButtonStyled onClick={() => remove(index)}>
              <Image
                src="/assets/konark/icons/trash.svg"
                alt="trash"
                size="small"
              />
            </IconButtonStyled>
          </Inline>
        ))}

        <Inline>
          <AddItemStyled
            onClick={() => append({ key: '', value: '' })}
            align="center"
            gutter={5}
          >
            <Image
              src={`${envMap.VITE_ASSETS_URL}website/icons/plus.svg`}
              alt="plus"
              size="xs"
            />

            <Typography>Add {addLabel}</Typography>
          </AddItemStyled>
        </Inline>
      </Stack>
    </PadBox>
  );
}
