import { Stack } from '@bedrock-layout/stack';
import { atom, useAtom } from 'jotai';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Typography } from 'ui';

import { Header } from '../../components/Header';
import { PermissionType } from '../../components/PermissionComponent/constant';
import { PagesBodyContainer } from '../../components/layouts/PagesBodyContainer';
import { SITE_META } from '../../utils/common';
import { envMap } from '../../utils/constant';
import { CardContainer, CredentialsContainer } from './Credentials.styled';
import { AuthenticationConfig } from './authenticationConfig/components/AuthenticationConfig';
import { AuthenticationKey } from './authenticationKey/components/AuthenticationKey';

export const credentialLimitsConfigAtom = atom<Record<string, any>>({});

const showAuthConfig: boolean = envMap.VITE_AUTH_CONFIG_ENABLED === 'true';

type AuthType = {
  enabled?: boolean;
};

type CredentialsProps = {
  subModules: { authConfig?: AuthType; authKeys?: AuthType };
  limits: Record<string, any>;
  permissions: Record<PermissionType, boolean>;
};

export function Credentials(props: CredentialsProps) {
  const { subModules, limits } = props;
  const authConfig = subModules?.authConfig ?? {};
  const authkeys = subModules?.authKeys ?? {};

  const enableAuthConfig = authConfig?.enabled ?? false;
  const enableAuthKeys = authkeys?.enabled ?? false;

  const [, setLimitConfig] = useAtom(credentialLimitsConfigAtom);

  useEffect(() => {
    setLimitConfig(limits);
  }, [limits]);

  return (
    <PagesBodyContainer>
      <Helmet>
        <title>
          {SITE_META[window.location.pathname.substring(1)]?.title ?? ''}
        </title>
        <meta
          name="description"
          content={
            SITE_META[window.location.pathname.substring(1)]?.description ?? ''
          }
        />
      </Helmet>
      <Header
        content={
          <Typography name="heading1" fontWeight={700}>
            Credentials
          </Typography>
        }
      />
      <CredentialsContainer gutter="4rem">
        {showAuthConfig && enableAuthConfig && (
          <Stack>
            <CardContainer padding="2.4rem">
              <AuthenticationConfig />
            </CardContainer>
          </Stack>
        )}

        {enableAuthKeys && (
          <Stack>
            <CardContainer padding="2.4rem">
              <AuthenticationKey />
            </CardContainer>
          </Stack>
        )}
      </CredentialsContainer>
    </PagesBodyContainer>
  );
}
