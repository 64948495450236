import { PadBox } from '@bedrock-layout/padbox';
import { Inline } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import { memo, useEffect, useState } from 'react';
import { IoPlayOutline } from 'react-icons/io5';
import { MdOutlineRule } from 'react-icons/md';
import { Handle, NodeProps, Position } from 'reactflow';
import { IconButton, TooltipReact, Typography, useLayer } from 'ui';

import { EntityVersionSelection } from '../../../../../../components/EntityVersionSelection/EntityVersionSelection';
import { useSendEventToGTM } from '../../../../../../hooks/useSendEventToGTM';
import { ENTITY_ID } from '../../../../../../utils/constant';
import {
  isWorkflowReadOnlyAtom,
  isWorkflowTestOnlyAtom,
  versionMappingWfInfoAtom,
  workflowEdgesAtom,
  workflowErrorByNodeAtom,
  workflowNodesAtom,
} from '../../../../atoms/atoms';
import { useTestWorkflowRuleNode } from '../../../../hooks/useTestWorkflowRuleNode';
import { checkNodeDisabled } from '../../../../utils/common';
import { RuleSheet } from '../../../Sheets/RuleSheet/RuleSheet';
import { NodeStatus } from '../../NodeStatus/NodeStatus';
import { RuleAction } from '../RuleAction/RuleAction';
import {
  IconContainer,
  RuleActionsContainer,
  RuleInfoContainer,
  RuleSetContainer,
} from './RuleSetNode.styled';

type ComponentProps = {
  data: any;
  id: string;
  type: string;
  isNodeDisabled: boolean;
  testRuleData: (attr?: any[], isLoop?: Record<string, any>) => Promise<void>;
  nodeAccessRole?: string;
};

export const RuleSetNode = memo(
  ({ data, isConnectable, id, type }: NodeProps) => {
    const [workflowNodes] = useAtom(workflowNodesAtom);
    const [workflowEdges] = useAtom(workflowEdgesAtom);

    // eslint-disable-next-line
    const [isNodeDisabled, setIsNodeDisabled] = useState(false);

    useEffect(() => {
      const nodeDisabled = checkNodeDisabled(
        data.rootId ?? '',
        id,
        workflowNodes,
        workflowEdges
      );

      setIsNodeDisabled(nodeDisabled.isDisabled);
    }, [JSON.stringify(workflowNodes)]);

    const { testRuleData, nodeAccessRole } = useTestWorkflowRuleNode({
      id,
      localData: data,
      type,
    });

    return (
      <>
        <Handle
          type="source"
          position={Position.Bottom}
          style={{
            background: 'var(--color-dodgerBlue)',
            height: 8,
            width: 8,
            border: '1px solid var(--color-dodgerBlue)',
          }}
          isConnectable={isConnectable}
        />
        <Handle
          type="target"
          position={Position.Top}
          style={{
            background: 'var(--color-dodgerBlue)',
            height: 8,
            width: 8,
            border: '1px solid var(--color-dodgerBlue)',
          }}
          isConnectable={isConnectable}
        />

        {isNodeDisabled ? (
          <TooltipReact
            id={id}
            placement="right"
            launcher={
              <span>
                <Component
                  data={data}
                  type={type}
                  id={id}
                  isNodeDisabled={isNodeDisabled}
                  testRuleData={testRuleData}
                  nodeAccessRole={nodeAccessRole}
                />
              </span>
            }
          >
            <Typography>
              This branch is disabled because the rule is running in a loop.
            </Typography>
          </TooltipReact>
        ) : (
          <Component
            data={data}
            type={type}
            id={id}
            isNodeDisabled={isNodeDisabled}
            testRuleData={testRuleData}
            nodeAccessRole={nodeAccessRole}
          />
        )}
      </>
    );
  }
);

const Component = ({
  data,
  type,
  id,
  isNodeDisabled,
  testRuleData,
  nodeAccessRole,
}: ComponentProps) => {
  const [isWorkflowTestOnly] = useAtom(isWorkflowTestOnlyAtom);
  const [versionMappingInfo, setVersionMappingInfo] = useAtom(
    versionMappingWfInfoAtom
  );
  const currNodeVersionMapping = versionMappingInfo?.find(
    (currMapping) => currMapping.nodeId === id
  );

  const [workflowErrorByNode] = useAtom(workflowErrorByNodeAtom);
  const [isWorkflowReadOnly] = useAtom(isWorkflowReadOnlyAtom);

  const { sendEventToGTM } = useSendEventToGTM();

  const { openWithProps } = useLayer(<RuleSheet />);

  const handleDataUpdate = (data: Record<string, any>) => {
    if ('selectedVersion' in data) {
      const versionInfo = data.selectedVersion;

      setVersionMappingInfo(
        (versionMappingList) =>
          versionMappingList?.map((currMapping) => {
            if (currMapping.nodeId === id) {
              return {
                ...currMapping,
                version: versionInfo.version,
              };
            }

            return currMapping;
          }) ?? []
      );
    }
  };

  return (
    <RuleSetContainer
      padding="0.5rem"
      $isError={!(workflowErrorByNode[id] == null)}
    >
      <NodeStatus status={data?.status} />

      <IconContainer
        onClick={() => {
          sendEventToGTM({
            event: 'workflow',
            source: 'listing',
            element: type,
            action: 'node_click',
            type: data?.nodeType,
          });

          if (!isNodeDisabled) {
            openWithProps({
              id,
              data,
            });
          }
        }}
      >
        <MdOutlineRule size={32} color={isNodeDisabled ? '#dcdcdc' : '#111'} />
      </IconContainer>

      <PadBox
        padding={{
          top: '6px',
          bottom: '6px',
        }}
      >
        <RuleInfoContainer>
          <Typography name="paragraphXs" fontWeight={700}>
            {data?.name ?? 'Step 1'}
          </Typography>

          <Typography name="paragraphXs">Rule</Typography>

          {!(workflowErrorByNode[id] == null) && (
            <Typography name="errorXs">
              {workflowErrorByNode[id]?.message}
            </Typography>
          )}

          <EntityVersionSelection
            entityInfo={{
              type: ENTITY_ID.rules,
              id: data?.entityId ?? '',
            }}
            onLoadGetData={false}
            isReadOnly={isWorkflowReadOnly}
            selectedVersion={currNodeVersionMapping?.version}
            updateDataOnParent={handleDataUpdate}
            showRefreshBtn={true}
            entityAccessRole={nodeAccessRole}
          />
        </RuleInfoContainer>

        {!isNodeDisabled && (
          <RuleActionsContainer>
            <Inline
              align="center"
              style={{
                background: 'var(--color-lightGray7)',
                width: '4rem',
              }}
            >
              <IconButton
                disabled={!isWorkflowTestOnly}
                onClick={async () => await testRuleData()}
              >
                <IoPlayOutline
                  color={
                    isWorkflowTestOnly
                      ? 'var(--color-black)'
                      : 'var(--color-darkGray)'
                  }
                />
              </IconButton>

              <RuleAction data={data} id={id} />
            </Inline>
          </RuleActionsContainer>
        )}
      </PadBox>
    </RuleSetContainer>
  );
};

RuleSetNode.displayName = 'RuleSetNode';
