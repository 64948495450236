import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import type { Control, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { MdOutlineRule } from 'react-icons/md';
import { type Dataset, Toast, Typography } from 'ui';

import { RuleListField } from '../../../../../components/RuleListField/RuleListField';
import {
  getRuleNameByNodeType,
  getRuleTypeByNodeType,
} from '../../../../../utils/common';
import { AttributeModel } from '../../../../Rules/models';
import { versionMappingWfInfoAtom } from '../../../atoms/atoms';
import { useGetRuleList } from '../../../hooks/graphql/useGetRuleList';
import { getEntityNameById } from '../../../utils/common';
import { RuleContainer } from './RuleSheet.styled';
import { RunInLoop } from './RunInLoop/RunInLoop';
import { Selector } from './Selector/Selector';

type RuleMappingProps = {
  control?: Control<any>;
  setValue: UseFormSetValue<any>;
  dataSet: Record<string, Dataset>;
  watch: UseFormWatch<any>;
  customInputs: Record<string, AttributeModel>;
  localData: any;
  idsToNotExpand?: string[];
  nodeId?: string;
};

export function RuleMapping({
  control,
  setValue,
  dataSet,
  watch,
  customInputs,
  localData,
  idsToNotExpand = [],
  nodeId,
}: RuleMappingProps) {
  const attributes = watch('attributes');

  const selectedRule = watch('selectedRule');

  const [versionMappingInfo] = useAtom(versionMappingWfInfoAtom);

  const currNodeVersionMapping = versionMappingInfo?.find(
    (currMapping) => currMapping.nodeId === nodeId
  );

  const version = currNodeVersionMapping?.version;

  const [getRuleList, { loading: listLoading }] = useGetRuleList();
  const [ruleList, setRuleList] = useState<Array<Record<string, any>>>([]);

  useEffect(() => {
    if (ruleList.length > 0 && !_isNil(selectedRule)) {
      const currentRule = ruleList.find(
        // eslint-disable-next-line
        (r) => r.value === (selectedRule.value || localData.entityId)
      );

      if (!_isNil(currentRule)) {
        setValue('selectedRule', currentRule);
      }
    }
  }, [JSON.stringify(ruleList)]);

  const handleGetRuleList = async () => {
    try {
      const { data } = await getRuleList({
        variables: {
          page: 1,
          perPage: 500,
          filters: { in: { type: [getEntityNameById(localData.nodeType)] } },
        },
        fetchPolicy: 'no-cache',
      });

      if (!_isNil(data)) {
        setRuleList(
          data.getRule.data.map((item) => {
            return {
              label: item.name,
              value: item.id,
              type: item.type,
            };
          })
        );
      }
    } catch {}
  };

  useEffect(() => {
    void handleGetRuleList();
  }, []);

  return (
    <Stack gutter="1.5rem">
      <RuleContainer justify="start" align="center" gap="0.8rem">
        <MdOutlineRule size={32} />

        <Typography>
          {getRuleNameByNodeType(localData?.nodeType ?? '')}
        </Typography>
      </RuleContainer>

      <RuleListField
        editRule={() => {
          // eslint-disable-next-line
          if (!!selectedRule?.value) {
            let link = `/rules/${
              selectedRule?.value as string
            }?stage=staging&ruleType=${getEntityNameById(
              localData.nodeType
            )}&wsid=${sessionStorage.getItem('workspaceUUID') as string}`;

            if (!_isNil(version) && !_isEmpty(version) && version !== 'draft') {
              link += `&type=view&isLive=true`;

              if (version !== 'live') {
                link += `&version=${version}`;
              }
            } else {
              link += `&type=edit`;
            }

            window.open(link);
          }
        }}
        name="selectedRule"
        refresh={() => {
          void handleGetRuleList();
        }}
        control={control}
        ruleList={ruleList}
        isLoading={listLoading}
        ruleType={getRuleTypeByNodeType(localData?.nodeType ?? '')}
      />

      {attributes?.length === 0 && !_isNil(selectedRule) && (
        <Inline>
          <Toast
            type="infoBlue"
            message='You do not have any input attributes configured. Please go ahead and
            click on "Test" to test the node.'
          />
        </Inline>
      )}

      {!_isNil(selectedRule) && attributes?.length !== 0 && (
        <>
          <RunInLoop
            name="runInLoop"
            control={control}
            setValue={setValue}
            updatedDataSet={dataSet}
            localData={localData}
          />

          {attributes?.map((attribute: any, index: number) => {
            return (
              <Selector
                control={control}
                setValue={setValue}
                keyName={attribute.keyName}
                nodeName={`attributes.${index}`}
                dataType={attribute.dataType ?? 'string'}
                dataSet={dataSet}
                index={index}
                key={`attributes.${index}`}
                customInputs={customInputs}
                previousCustomInputs={localData.input}
                idsToNotExpand={idsToNotExpand}
              />
            );
          })}
        </>
      )}
    </Stack>
  );
}
