import { Stack } from '@bedrock-layout/stack';
import styled from 'styled-components';
import { TextField } from 'ui';

export const WorkSpaceFormFieldContainer = styled(Stack)`
  max-inline-size: 32rem;
`;
export const ButtonContainer = styled(Stack)`
  max-inline-size: 10rem;
`;

export const WSTextField = styled(TextField)`
  padding: 0.8rem;
`;
