import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import styled, { css } from 'styled-components';
import { Typography } from 'ui';

export const CardContainer = styled(Stack)<{ $isSelected: boolean }>`
  border: 1px solid var(--color-lightGray5);
  border-radius: 1px;

  ${({ $isSelected }) =>
    $isSelected &&
    css`
      background-color: var(--color-aliceBlue3);
      border: 1px solid var(--color-dodgerBlue);
    `}
`;

export const StyledInline = styled(Inline)`
  justify-content: space-between;
  align-items: center;
`;

export const ButtonWrapper = styled(Inline)`
  flex-wrap: wrap;
`;

export const VersionStyled = styled(Inline)`
  padding: 0.6rem;
  background-color: var(--color-aliceBlue);
  border-radius: 0.3rem;
  font-size: 10px;
  max-width: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CommentTextContainer = styled.div`
  max-block-size: 10rem;
  min-block-size: 5rem;
  border: 1px solid var(--color-lightGray5);
  border-radius: 1px;
  overflow: auto;
  padding: 0.5rem;
  background-color: var(--color-white);
  font-size: 1.2rem;
`;

export const CreatedInfoContainer = styled(Inline)`
  justify-content: space-between;
  align-items: center;
`;

export const NameIconStyled = styled(Inline)`
  background-color: var(--color-dodgerBlue);
  color: var(--color-white);
  border-radius: 50%;
  inline-size: 2rem;
  block-size: 2rem;
  font-size: 1.2rem;
`;

export const CommentBoxStyled = styled(Stack)`
  border-top: 1px solid var(--color-lightGray5);
`;

export const CommentListContainer = styled(Stack)<{ $hasComments: boolean }>`
  border-top: 1px solid var(--color-lightGray5);
  padding: 1rem;

  ${({ $hasComments = true }) => !$hasComments && 'align-items: center;'}
`;

export const Separator = styled.div`
  border: 1px solid var(--color-silver);
`;

export const MenuItemText = styled(Typography)`
  padding: 1rem 2.8rem 0.8rem 1.4rem;
`;

export const SmallFontText = styled(Typography)`
  font-size: 10px;
`;

export const NoCommentContainer = styled.img`
  inline-size: 20rem;
`;
