import { Inline } from '@bedrock-layout/primitives';
import styled from 'styled-components';

export const SpreadsheetColumn = styled.div`
  flex: 0.5;
  display: flex;
  height: inherit;
`;

export const WorksheetColumn = styled.div`
  flex: 0.5;
  border-left: 1px solid var(--color-lightGray);
  display: flex;
  height: inherit;
`;

export const FileHeader = styled(Inline)`
  justify-content: space-between;
  width: inherit;
  border-bottom: 1px solid var(--color-lightGray);
  padding: 1.6rem;
  block-size: 7rem;
`;

export const FileBodyContent = styled(Inline)`
  height: inherit;
  flex: 1;
  flex-direction: column;
  gap: 1.2rem;
`;

export const FileItem = styled(Inline)<{ hasData?: boolean }>`
  padding: 1.2rem 2.4rem;
  border-radius: 0.4rem;
  background: var(--color-aliceBlue2);
  inline-size: 27.5rem;

  ${({ hasData = false }) =>
    hasData
      ? 'justify-content: space-between; gap: 2.4rem;'
      : 'justify-content: center'}
`;
