import { Center, Cover, Inline, Stack } from '@bedrock-layout/primitives';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { Button } from 'ui';

import { ErrorMessage } from './components/ErrorMessage';

export const Error404 = () => {
  const navigate = useNavigate();

  return (
    <Cover>
      <Helmet>
        <title>404 Not Found | Nected</title>
      </Helmet>
      <Center centerChildren>
        <Stack gutter="2rem">
          <ErrorMessage
            title="Page Not Found"
            subTitle="The page you are looking for does not exists"
          />

          <Inline justify="center">
            <Button onClick={() => navigate('/home')}>Go Home</Button>
          </Inline>
        </Stack>
      </Center>
    </Cover>
  );
};
