import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import styled, { css } from 'styled-components';
import { Typography } from 'ui';

export const EntityUsageContainer = styled(Stack)<{ $openInSheet?: boolean }>`
  ${({ $openInSheet = false }) =>
    $openInSheet &&
    css`
      height: 100vh;
      overflow-y: auto;
    `}
`;

export const FilterContainer = styled(Inline)`
  justify-content: space-between;
  align-items: center;
`;

export const ListContainer = styled(Stack)`
  flex: 1;
  margin-top: 1rem;
`;

export const LauncherContainer = styled(Inline)`
  align-items: center;
  justify-content: center;
  inline-size: 8rem;
  background-color: var(--color-whiteSmoke);
  border-radius: 0.5rem;
  padding: 0.5rem;
  gap: 0.5rem;
  text-transform: capitalize;
  cursor: pointer;
`;

export const FilterContentStyled = styled(Stack)`
  overflow-y: auto;
  max-block-size: 30rem;
  inline-size: 6rem;
`;

export const FilterVersionItem = styled(Inline)`
  align-items: center;
  justify-content: center;
  background-color: var(--color-lightGray2);
  border-bottom: 1px solid var(--color-lightGray);
  padding: 0.5rem;
  font-size: 1rem;
  cursor: pointer;

  &:last-child {
    border-bottom: none;
  }
`;

export const EntNameTextStyled = styled(Typography)`
  max-inline-size: 20rem;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: var(--color-dodgerBlue);
`;

export const EntCategoryTextStyled = styled(Typography)`
  text-transform: capitalize;
`;

export const StyledImg = styled.img`
  margin-top: 1rem;
  max-inline-size: 30rem;
  min-inline-size: 20rem;
`;
