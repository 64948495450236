import { Helmet } from 'react-helmet';
import { Typography } from 'ui';

import { Header } from '../../../components/Header';
import { PagesBodyContainer } from '../../../components/layouts/PagesBodyContainer';
import { SITE_META } from '../../../utils/common';
import { Connectors } from '../components/connector';

export function Integrations() {
  return (
    <PagesBodyContainer>
      <Helmet>
        <title>
          {SITE_META[window.location.pathname.substring(1)]?.title ?? ''}
        </title>
        <meta
          name="description"
          content={
            SITE_META[window.location.pathname.substring(1)]?.description ?? ''
          }
        />
      </Helmet>
      <Header
        content={
          <Typography name="heading1" fontWeight={700}>
            Integrations
          </Typography>
        }
      />

      <Connectors />
    </PagesBodyContainer>
  );
}
