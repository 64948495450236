import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect, useMemo, useState } from 'react';
import { EdgeProps, getSmoothStepPath } from 'reactflow';

import { workflowNodesAtom } from '../../../atoms/atoms';
import { filterWorkflowNodes } from '../../../utils/common';

export const SmoothEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  label,
  source,
  target,
  data,
}: EdgeProps) => {
  // Set a condition for turning in the x-direction based on y distance
  const [workflowNodes] = useAtom(workflowNodesAtom);

  const [isNodeDisabled, setIsNodeDisabled] = useState(false);
  const workflowNodesUpdated = filterWorkflowNodes(workflowNodes);

  useEffect(() => {
    const timeout = setTimeout(() => {
      // if (data.edgeType === 'else') {
      const parentNode = workflowNodes.find((node) => node.id === source);
      // eslint-disable-next-line
      if (parentNode?.data.nodeType === 'srNode') {
        // eslint-disable-next-line
        setIsNodeDisabled(!!parentNode.data.runInLoop?.status as boolean);
      }
    }, 10);

    return () => clearTimeout(timeout);
  }, [JSON.stringify(workflowNodesUpdated)]);

  const pathName = useMemo(() => {
    const parentNode = workflowNodes.find((n) => n.id === source);

    if (
      data.edgeType === 'case' &&
      !_isNil(parentNode) &&
      parentNode.data.nodeType === 'switchNode'
    ) {
      const switcher = parentNode.data.switcher;

      const currentSwitcher = switcher.find(
        (s: Record<string, any>) => s.pathId === data.pathId
      );

      if (!_isNil(currentSwitcher)) {
        return currentSwitcher.name;
      }
    }

    return undefined;
  }, [JSON.stringify(workflowNodes)]);

  // Use getSmoothStepPath with the modified x-turn condition
  const [edgePath] = getSmoothStepPath({
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    borderRadius: 5,
    centerX: sourceY,
    centerY: sourceY + 20, // Adjust this value based on your requirements
    offset: 0.5,
  });

  return (
    <>
      <path
        id={id}
        d={edgePath}
        style={{ fill: 'none', stroke: '#aaa', strokeWidth: 2 }}
      />

      {!_isNil(label) && !_isEmpty(label) && !isNodeDisabled && (
        <>
          <rect
            x={targetX - 25}
            y={sourceY + (targetY - sourceY) / 2 - 16}
            width={50}
            height={24} // Adjust this value based on your requirements
            style={{ fill: '#ddd', stroke: 'transparent' }}
          />

          <text
            x={targetX}
            y={sourceY + (targetY - sourceY) / 2}
            fontSize="12"
            fill="#000"
            textAnchor="middle"
          >
            {label}
          </text>
        </>
      )}

      {!_isNil(pathName) && !isNodeDisabled && (
        <>
          <rect
            x={targetX - (pathName.length * 8) / 2}
            y={sourceY + (targetY - sourceY) / 2 - 16}
            width={pathName.length * 8}
            height={24} // Adjust this value based on your requirements
            style={{ fill: '#ddd', stroke: 'transparent' }}
            textAnchor="middle"
          />

          <text
            x={targetX}
            y={sourceY + (targetY - sourceY) / 2}
            fontSize="12"
            fill="#000"
            textAnchor="middle"
          >
            {pathName}
          </text>
        </>
      )}

      {data.edgeType === 'defaultCase' && !isNodeDisabled && (
        <>
          <rect
            x={targetX - 25}
            y={sourceY + (targetY - sourceY) / 2 - 16}
            width={50}
            height={24} // Adjust this value based on your requirements
            style={{ fill: '#ddd', stroke: 'transparent' }}
          />

          <text
            x={targetX}
            y={sourceY + (targetY - sourceY) / 2}
            fontSize="12"
            fill="#000"
            textAnchor="middle"
          >
            Default
          </text>
        </>
      )}
    </>
  );
};
