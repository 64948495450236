import { Inline } from '@bedrock-layout/primitives';
import styled, { css } from 'styled-components';

export const StyledFileList = styled(Inline)`
  flex: 1;
  flex-direction: column;
  align-items: inherit;
`;

export const FileHeader = styled(Inline)`
  display: flex;
  justify-content: space-between;
  width: inherit;
  border-bottom: 1px solid var(--color-lightGray);
  padding: 1.6rem;
  block-size: 70px;
`;

export const FileBodyContainer = styled.div<{ hasData: boolean }>`
  height: inherit;
  flex: 1;
  overflow: auto;

  ${({ hasData = true }) =>
    !hasData &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
    `}
`;

export const FileItem = styled(Inline)<{ fileSelected: boolean }>`
  padding: 1.4rem 2.4rem;
  cursor: pointer;

  background-color: ${({ fileSelected = false }) =>
    fileSelected ? 'var(--color-aliceBlue2)' : 'var(--color-white'};
`;

export const EmptyListContainer = styled.div`
  flex: 1;
`;
