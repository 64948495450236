import { Helmet } from 'react-helmet';

import { SITE_META } from '../../utils/common';
import { QuestionnaireForm } from './components/QuestionnaireForm';

export const Questionnaire = () => {
  sessionStorage.removeItem('questionnaireData');

  return (
    <>
      <Helmet>
        <title>
          {SITE_META[window.location.pathname.substring(1)]?.title ?? ''}
        </title>
        <meta
          name="description"
          content={
            SITE_META[window.location.pathname.substring(1)]?.description ?? ''
          }
        />
      </Helmet>
      <QuestionnaireForm />
    </>
  );
};
