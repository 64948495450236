import { PadBox } from '@bedrock-layout/padbox';
import { Inline } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import { memo, useEffect } from 'react';
import { IoCodeSharp, IoPlayOutline } from 'react-icons/io5';
import { Handle, NodeProps, Position } from 'reactflow';
import { IconButton, Typography, toasts, useLayer } from 'ui';

import { useSendEventToGTM } from '../../../../../../hooks/useSendEventToGTM';
import {
  changedNodeIdsAtom,
  isWorkflowTestOnlyAtom,
  workflowErrorByNodeAtom,
  workflowNodesAtom,
  workflowStatusAtom,
} from '../../../../atoms/atoms';
import { useTestCodeNode } from '../../../../hooks/useTestCodeNode';
import { getExecutedValueAndStatus } from '../../../../utils/common';
import { statusListForDataUpdate } from '../../../../utils/constant';
import { CodeNodeSheet } from '../../../Sheets/CodeNodeSheet/CodeNodeSheet';
import { NodeStatus } from '../../NodeStatus/NodeStatus';
import { CodeAction } from '../CodeAction/CodeAction';
import {
  CodeActionsContainer,
  CodeContainer,
  CodeInfoContainer,
  IconContainer,
} from './NectedCodeNode.styled';

export const NectedCodeNode = memo(
  ({ data, isConnectable, id, type }: NodeProps) => {
    const { openWithProps } = useLayer(<CodeNodeSheet />);
    const [workflowNodes] = useAtom(workflowNodesAtom);
    const [workflowStatus] = useAtom(workflowStatusAtom);

    const [, setChangedNodeIds] = useAtom(changedNodeIdsAtom);

    const [workflowErrorByNode] = useAtom(workflowErrorByNodeAtom);
    const [isWorkflowTestOnly] = useAtom(isWorkflowTestOnlyAtom);

    const { testData, codeData } = useTestCodeNode({
      localData: data,
      id,
    });
    const workflowNode = workflowNodes.find((wn) => wn.id === id);

    const { sendEventToGTM } = useSendEventToGTM();

    const handleTestData = async () => {
      try {
        await testData(
          data.input?.snippet?.value ?? '',
          data.input?.language?.value ?? '',
          () => toasts.success('Node tested successfully', 'node-test')
        );
      } catch (error) {}
    };

    useEffect(() => {
      if (!_isNil(codeData)) {
        if (
          !_isNil(workflowNode) &&
          statusListForDataUpdate.includes(workflowStatus)
        ) {
          const newWorkflowNode = workflowNode;

          const exec = getExecutedValueAndStatus(codeData);
          newWorkflowNode.data.status = exec.status;

          newWorkflowNode.data.executedValue = exec.executedValue;

          setChangedNodeIds([]);

          setTimeout(() => {
            data.onWorkflowNodeChange(workflowNode);
          }, 100);
        }
      }
    }, [codeData]);

    return (
      <>
        <Handle
          type="source"
          position={Position.Bottom}
          style={{
            background: 'var(--color-dodgerBlue)',
            height: 8,
            width: 8,
            border: '1px solid var(--color-dodgerBlue)',
          }}
          isConnectable={isConnectable}
        />
        <Handle
          type="target"
          position={Position.Top}
          style={{
            background: 'var(--color-dodgerBlue)',
            height: 8,
            width: 8,
            border: '1px solid var(--color-dodgerBlue)',
          }}
          isConnectable={isConnectable}
        />
        <CodeContainer
          padding="0.5rem"
          $isError={!(workflowErrorByNode[id] == null)}
        >
          <NodeStatus status={data.status} />

          <IconContainer
            onClick={() => {
              sendEventToGTM({
                event: 'workflow',
                source: 'listing',
                element: type,
                action: 'node_click',
                type: data.nodeType,
              });

              openWithProps({
                id,
                data,
              });
            }}
          >
            <IoCodeSharp size={32} />
          </IconContainer>

          <PadBox
            padding={{
              top: '6px',
              bottom: '6px',
            }}
          >
            <CodeInfoContainer>
              <Typography name="paragraphXs" fontWeight={700}>
                {data.name ?? 'Step 1'}
              </Typography>

              <Typography name="paragraphXs">Action</Typography>

              {!(workflowErrorByNode[id] == null) && (
                <Typography name="errorXs">
                  {workflowErrorByNode[id]?.message}
                </Typography>
              )}
            </CodeInfoContainer>

            <CodeActionsContainer>
              <Inline
                align="center"
                style={{
                  background: 'var(--color-lightGray7)',
                  width: '4rem',
                }}
              >
                <IconButton
                  disabled={!isWorkflowTestOnly}
                  onClick={handleTestData}
                >
                  <IoPlayOutline
                    color={
                      isWorkflowTestOnly
                        ? 'var(--color-black)'
                        : 'var(--color-darkGray)'
                    }
                  />
                </IconButton>

                <CodeAction data={data} id={id} />
              </Inline>
            </CodeActionsContainer>
          </PadBox>
        </CodeContainer>
      </>
    );
  }
);

NectedCodeNode.displayName = 'NectedCodeNode';
