import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import styled, { css } from 'styled-components';

export const PanelContainerStyled = styled(Stack)`
  padding: 1rem 0.5rem;
`;

export const PanelItem = styled(Inline)<{ $active: boolean }>`
  cursor: pointer;
  border-radius: 0.2rem;
  padding: 1rem;

  ${({ $active = false }) =>
    $active &&
    css`
      background-color: var(--color-aliceBlue);
    `}
`;
