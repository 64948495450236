import { Inline, PadBox } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import type { Control } from 'react-hook-form';
import { FiPlusCircle } from 'react-icons/fi';
import {
  Button,
  Dataset,
  Image,
  TextButton,
  TextField,
  Toast,
  TooltipReact,
  Typography,
  toasts,
} from 'ui';

import { useSendEventToGTM } from '../../../../../hooks/useSendEventToGTM';
import { ruleLimitsConfigAtom } from '../../../../../pages/Rules';
import { RestNodePill } from '../../../../../pages/Rules/components/SimpleRule/Results/RestNodePill';
import type { AttributeModel } from '../../../../../pages/Rules/models';
import { envMap } from '../../../../../utils/constant';
import {
  CustomAttributeTabCommonProps,
  FormHeader,
  customAttributeDefaultValues,
} from '../CustomAttributeSheet';
import {
  FormFooter,
  FormPanel,
  Table,
  TableData,
  TableHeader,
  TableRow,
} from '../CustomAttributeSheet.styled';
import { DataTypeSelection } from '../DataTypeSelection';
import { RestApiDataTypeField } from './RestApiAttributes.styled';

type RestApiAttributesProps = CustomAttributeTabCommonProps & {
  control: Control<any>;
  customAttrArray: AttributeModel[];
  updatedDataset?: Record<string, Dataset>;
};

export function RestApiAttributes({
  fields,
  append,
  remove,
  control,
  setValue,
  onSubmit,
  isReadOnly,
  tokensSet,
  customAttrArray,
  localDataSetFieldsById,
  updatedDataset = {},
}: RestApiAttributesProps) {
  const [limitConfig] = useAtom(ruleLimitsConfigAtom);

  const { sendEventToGTM } = useSendEventToGTM();

  const formHeaders: FormHeader[] = [
    {
      label: 'Name',
    },
    {
      label: 'Rest API',
    },

    {
      label: '',
    },
  ];

  const handleSendGTMEvent = (action: string) => {
    sendEventToGTM({
      event: 'rule',
      type: 'restAPIAttributes',
      action,
      element: '',
      nec_source: '',
      action_name: '',
    });
  };

  const handleAddConfig = () => {
    handleSendGTMEvent('add');

    if (!_isEmpty(limitConfig) && fields.length < limitConfig.maxCi.value) {
      append({
        ...customAttributeDefaultValues,
        sourceType: 'restAPI',
      });
    } else if (
      !_isNil(limitConfig?.maxCi?.message) &&
      !_isEmpty(limitConfig?.maxCi?.message)
    ) {
      toasts.info(limitConfig.maxCi.message, 'success');
    }
  };

  return (
    <Stack as={FormPanel}>
      <Stack as={FormPanel} gutter={'2.4rem'} padding={['1.2rem', '2.4rem']}>
        <Stack gutter={15}>
          <Inline>
            <Toast
              type="infoBlue"
              message="The attribute created here will store data as JSON."
            />
          </Inline>
        </Stack>
        <Stack gutter="1rem">
          <Table>
            <TableRow>
              {formHeaders.map((header) => (
                <TableHeader key={header.label}>
                  <PadBox padding="1.2rem">
                    <Inline align="center" gutter={8}>
                      <Typography>{header.label}</Typography>

                      {!_isNil(header.tooltipContent) && (
                        <TooltipReact id={header.label} placement="top-start">
                          {header.tooltipContent}
                        </TooltipReact>
                      )}
                    </Inline>
                  </PadBox>
                </TableHeader>
              ))}
            </TableRow>

            {fields.map((field: any, index) => {
              if (field.sourceType !== 'restAPI') {
                return null;
              }

              return (
                <TableRow key={field.id}>
                  <TableData>
                    <PadBox padding={'1.2rem'}>
                      <TextField
                        control={control}
                        rules={{ required: 'Required' }}
                        name={`attributes.${index}.name`}
                        placeholder="Property Name"
                        showErrorIcon={false}
                        disabled={isReadOnly}
                      />
                    </PadBox>
                  </TableData>

                  <TableData>
                    <PadBox
                      as={RestApiDataTypeField}
                      align="center"
                      padding={'1.2rem'}
                    >
                      <DataTypeSelection
                        index={index}
                        setValue={setValue}
                        control={control}
                        name={`attributes.${index}.selectedType`}
                        isDisabled={isReadOnly}
                        tokensSet={tokensSet}
                        hideDataSet={true}
                        hideRestApi={false}
                        hideInputAttr={true}
                        customAttrArray={customAttrArray}
                        localDataSetFieldsById={localDataSetFieldsById}
                        updatedDataset={updatedDataset}
                        showRefresh
                      />

                      <RestNodePill
                        control={control}
                        name={`attributes.${index}.sampleValue`}
                        setOriginalValue={setValue}
                        index={index}
                        tokensSet={tokensSet}
                        customAttrArray={customAttrArray}
                        updatedDataset={updatedDataset}
                      />
                    </PadBox>
                  </TableData>

                  <TableData>
                    <PadBox padding="2.3rem">
                      <TextButton
                        onClick={() => {
                          handleSendGTMEvent('delete');
                          remove(index);
                        }}
                        disabled={isReadOnly}
                      >
                        Delete
                      </TextButton>
                    </PadBox>
                  </TableData>
                </TableRow>
              );
            })}
          </Table>

          <Inline align="center">
            {isReadOnly ? (
              <FiPlusCircle color="var(--color-darkGray)" />
            ) : (
              <Image
                src={`${envMap.VITE_ASSETS_URL}website/icons/plus.svg`}
                alt="plus"
                size="xs"
              />
            )}

            <TextButton disabled={isReadOnly} onClick={handleAddConfig}>
              Add Input Attribute
            </TextButton>
          </Inline>
        </Stack>
      </Stack>

      {!isReadOnly && (
        <Inline as={FormFooter} padding="0.8rem" justify="end">
          <Button appearance="filled" onClick={() => onSubmit('prevTab')}>
            Back
          </Button>
          <Button appearance="contained" onClick={() => onSubmit('closeSheet')}>
            Save and Close
          </Button>
        </Inline>
      )}
    </Stack>
  );
}
