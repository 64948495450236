import { Inline } from '@bedrock-layout/primitives';
import styled, { css } from 'styled-components';
import { Typography } from 'ui';

export const NodeContainer = styled(Inline)<{ $hasBeforeClass: boolean }>`
  ${({ $hasBeforeClass }) =>
    $hasBeforeClass &&
    css`
      &::before {
        content: '';
        block-size: 1rem;
        inline-size: 2rem;
        border-bottom: 1px solid var(--color-darkGray);
      }
    `}
`;

export const StyledBlueText = styled(Typography)`
  color: var(--color-dodgerBlue);
`;
