import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import { Control, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { FiPlusCircle } from 'react-icons/fi';
import { Image, Typography, toasts } from 'ui';

import { generateUid } from '../../../../../../../utils/common';
import {
  MAX_NUMBER_OF_CONDITIONS_SWITCHER,
  envMap,
} from '../../../../../../../utils/constant';
import { isWorkflowReadOnlyAtom } from '../../../../../atoms/atoms';
import { getBlankConditions } from '../../../../../utils/common';
import { AddConditionMenu } from './AddConditionMenu';
import { AddChildStyled } from './PathBlock.styled';

type AddConditionProps = {
  control?: Control<any>;
  watch: UseFormWatch<any>;
  setValue: UseFormSetValue<any>;
  groupIndex: number;
  isAlone?: boolean;
  groupKey: string;
  size: number;
  groupName: string;
};

export function AddCondition({
  groupIndex,
  isAlone = false,
  setValue,
  watch,
  control,
  groupKey,
  size,
  groupName,
}: AddConditionProps) {
  const [isWorkflowReadOnly] = useAtom(isWorkflowReadOnlyAtom);

  const handleAddCondition = (operator?: string) => {
    if (size === MAX_NUMBER_OF_CONDITIONS_SWITCHER) {
      return toasts.warning(
        `Cannot add more than ${size} conditions!`,
        'warning-path-size'
      );
    }

    const groupChildren = watch(`${groupName}.children`);

    const conditionId = generateUid('condition_');

    groupChildren.push(conditionId);

    const condition = getBlankConditions(conditionId, groupKey);
    const path = watch(`paths.${groupIndex}`);

    path[groupKey].children = groupChildren;

    if (!_isNil(operator)) {
      path[groupKey].operator = operator;
    }

    setValue(`paths.${groupIndex}`, { ...path, ...condition });
  };

  if (isAlone) {
    return (
      <span>
        <AddConditionMenu append={handleAddCondition} />
      </span>
    );
  }

  return (
    <AddChildStyled
      $disabled={isWorkflowReadOnly}
      onClick={() => handleAddCondition()}
    >
      {isWorkflowReadOnly ? (
        <FiPlusCircle color="var(--color-darkGray)" />
      ) : (
        <Image
          src={`${envMap.VITE_ASSETS_URL}website/icons/plus.svg`}
          alt="plus"
          size="xs"
        />
      )}
      <Typography>Add Condition</Typography>
    </AddChildStyled>
  );
}
