import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import { useIsMounted } from 'hooks';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import { UseControllerProps, UseFormSetValue, useWatch } from 'react-hook-form';
import { Sheet, TextField, Toast, TooltipReact, Typography } from 'ui';

import { siteConstantsAtom, userProfileAtom } from '../../../../../atom';
import { HowToLink } from '../../../../../components/HowToLink/HowToLink';
import { TabSelectionApiHook } from '../../../../../components/TabSelectionApiHook/TabSelectionApiHook';
import { roleJsonAtom } from '../../../../../components/authentication/router/AuthProvider';
import { customAttributesAtom } from '../../../../../components/rules/forms/CustomAttributeSheet/CustomAttributeSheet';
import { useGetPermissionsByEntity } from '../../../../../hooks/restApi/useGetPermissionsByEntity';
import {
  getTooltipText,
  handleChangeApiToWebhookUrl,
} from '../../../../../utils/common';
import { currentWorkspaceDetailAtom } from '../../../../Workspace/atom';
import { isRuleReadOnlyAtom } from '../../../index';
import {
  convertAttributesToJson,
  convertAttributesToJsonWebhook,
  convertAttributesToRuleSetJson,
  convertAttributesToRuleSetJsonWebhook,
  getDataTypeByKey,
} from '../../../utils/common';
import { RuleTypes } from '../../CreateRuleSheet/CreateRuleSheet';
import { customAttributesInFieldsAtom } from '../../RuleSet/RuleSetTable/RuleSetTable';
import { versionInfoRuleAtom } from '../../atom/atom';
import { AuthenticationDropDown } from '../AuthenticationDropDown';
import { ApiTriggerForm } from './ApiTriggerForm';
import {
  ApiSettingsContainer,
  AuthKeyContainer,
  InlineStyled,
  KeyValueStyled,
} from './TriggerSheet.styled';
import { WorkflowTriggerForm } from './WebhookTriggerForm';

export type TriggerSheetProps = Omit<UseControllerProps, 'name'> & {
  type: 'staging' | 'production';
  ruleType: RuleTypes;
  setValue?: UseFormSetValue<any>;
};

export type authTypeProps = {
  label?: string;
  value?: string;
};

export function TriggerSheet({
  type,
  control,
  ruleType,
  setValue,
}: TriggerSheetProps) {
  const nameByType = type === 'staging' ? 'stagingConfig' : 'productionConfig';
  const [roleJson, setRoleJson] = useAtom(roleJsonAtom);
  const [, setLimitConfig] = useState<Record<string, any>>({});
  const [currentWorkspace] = useAtom(currentWorkspaceDetailAtom);

  const [versionInfoRule] = useAtom(versionInfoRuleAtom);
  const currentLiveVersion = versionInfoRule?.currentLiveVersion;

  const [userProfile] = useAtom(userProfileAtom);
  const email = !_isEmpty(userProfile) ? userProfile?.email : '';

  const { getUserPermissionsByEntity } = useGetPermissionsByEntity();

  const handleGetPermissionByEntity = async () => {
    const role = currentWorkspace?.role ?? '';
    const wid = currentWorkspace?.uuid ?? '';

    const user = window.btoa(email ?? '');

    await getUserPermissionsByEntity(
      user,
      role,
      wid,
      'credentials',
      setRoleJson
    );
  };

  const status = useWatch({
    control,
    name: 'status',
  });

  useEffect(() => {
    if (!_isEmpty(email)) {
      void handleGetPermissionByEntity();
    }
  }, []);

  useEffect(() => {
    setLimitConfig(roleJson?.internals?.credentials?.limits ?? {});
  }, [roleJson]);

  const [isRuleReadOnly] = useAtom(isRuleReadOnlyAtom);

  const staticUrl: string = useWatch({
    control,
    name: `${nameByType}.staticUrl`,
  });

  const authType: authTypeProps = useWatch({
    control,
    name: `${nameByType}.authType`,
  });
  const { value } = authType;

  const [attributes] = useAtom(customAttributesAtom);
  const [customAttributesInFields] = useAtom(customAttributesInFieldsAtom);
  const [siteConstants] = useAtom(siteConstantsAtom);

  const [bodyExample, setBodyExample] = useState('');
  const [bodyExampleHook, setBodyExampleHook] = useState('');
  const [triggerUrl, setTriggerUrl] = useState('');
  const [triggerUrlHook, setTriggerUrlHook] = useState('');

  let credentialKey = '';

  if (!_isNil(currentWorkspace)) {
    credentialKey = currentWorkspace.apiKey;
  }

  const [isSaving, setIsSaving] = useState(false);
  const isMounted = useIsMounted();

  const dataTypeByKey = getDataTypeByKey(customAttributesInFields);

  useEffect(() => {
    if (['simpleRule', 'decisionTable', 'ruleSet'].includes(ruleType)) {
      const convertedBody = convertAttributesToJson(
        attributes,
        status === 'published' ? 'production' : 'staging',
        ruleType,
        status === 'published' ? versionInfoRule?.currentLiveVersion : undefined
      );

      setBodyExample(convertedBody);
      setBodyExampleHook(
        convertAttributesToJsonWebhook(
          attributes,
          status === 'published' ? 'production' : 'staging',
          ruleType
        )
      );
    } else {
      setBodyExample(
        convertAttributesToRuleSetJson(
          dataTypeByKey,
          status === 'published' ? 'production' : 'staging',
          ruleType
        )
      );
      setBodyExampleHook(
        convertAttributesToRuleSetJsonWebhook(
          dataTypeByKey,
          status === 'published' ? 'production' : 'staging',
          ruleType
        )
      );
    }
  }, []);

  useEffect(() => {
    if (!_isEmpty(credentialKey) && !_isNil(value) && value === 'private') {
      setTriggerUrl(
        `curl -X "POST" "${staticUrl}"\\
        -H 'Content-Type: application/json'\\
        -H 'nected-api-key: ${credentialKey}'\\
        -d '${bodyExample}'`
      );
    } else {
      setTriggerUrl(
        `curl -X "POST" "${staticUrl}"\\
        -H 'Content-Type: application/json'\\
        -d '${bodyExample}'`
      );
    }
  }, [authType, credentialKey, bodyExample]);

  useEffect(() => {
    if (!_isEmpty(credentialKey) && !_isNil(value) && value === 'private') {
      setTriggerUrlHook(
        `curl -X "POST" "${handleChangeApiToWebhookUrl(
          staticUrl,
          status === 'published' ? 'production' : 'staging',
          currentLiveVersion
        )}"\\
        -H 'Content-Type: application/json'\\
        -H 'nected-api-key: ${credentialKey}'\\
        -d $'${bodyExampleHook}'`
      );
    } else {
      setTriggerUrlHook(
        `curl -X "POST" "${handleChangeApiToWebhookUrl(
          staticUrl,
          status === 'published' ? 'production' : 'staging',
          currentLiveVersion
        )}"\\
        -H 'Content-Type: application/json'\\
        -d $'${bodyExampleHook}'`
      );
    }
  }, [authType, credentialKey, bodyExampleHook, currentLiveVersion]);

  useEffect(() => {
    let interval: ReturnType<typeof setTimeout>;

    if (!_isNil(authType) && isMounted()) {
      setIsSaving(true);

      interval = setTimeout(() => {
        setIsSaving(false);
      }, 2000);
    }

    return () => {
      if (!_isNil(interval)) {
        clearTimeout(interval);
      }
    };
  }, [authType]);

  return (
    <Sheet size="small">
      <ApiSettingsContainer padding="1.6rem">
        <Stack gutter="2rem">
          <Inline align="center" stretch="start">
            <Inline align="center">
              <Typography name="heading1" fontWeight={700}>
                API Settings
              </Typography>
              {isSaving && <Typography>Saving...</Typography>}
            </Inline>

            <HowToLink
              link={getTooltipText(
                siteConstants,
                'rules',
                'apiSettingsHowTo',
                'howToLinks'
              )}
            />
          </Inline>
          <Stack gutter="0.5rem">
            <Inline gutter={8} align="center">
              <Typography fontWeight={700}>Authentication</Typography>
              <TooltipReact id="auth-rule-id">
                {getTooltipText(
                  siteConstants,
                  'rules',
                  'triggerAuthentication'
                )}
              </TooltipReact>
            </Inline>

            <AuthenticationDropDown
              control={control}
              name={`${nameByType}.authType`}
              isDisabled={isRuleReadOnly}
              MenuListFooter={undefined}
            />
          </Stack>

          {authType.label === 'Private' && (
            <>
              <Toast
                type="infoBlue"
                message="You can use header key below to authenticate the api."
              />

              <Stack gutter={8}>
                <Inline align="center" gutter={8}>
                  <Typography fontWeight={700}>Header</Typography>
                  <TooltipReact id="rest-api-header">
                    <Typography>
                      {getTooltipText(
                        siteConstants,
                        'rules',
                        'triggerAuthenticationHeader'
                      )}
                    </Typography>
                  </TooltipReact>
                </Inline>

                {!_isNil(currentWorkspace) && !_isEmpty(currentWorkspace) && (
                  <Inline align="center">
                    <AuthKeyContainer align="top">
                      <TextField
                        size="medium"
                        name="nected-api-key"
                        defaultValue="nected-api-key"
                        showErrorIcon={false}
                        control={control}
                        disabled
                      />

                      <InlineStyled align="center">
                        <KeyValueStyled>
                          {currentWorkspace.apiKey}
                        </KeyValueStyled>
                      </InlineStyled>
                    </AuthKeyContainer>
                  </Inline>
                )}
              </Stack>
            </>
          )}

          <TabSelectionApiHook>
            <ApiTriggerForm
              bodyExample={bodyExample}
              credentialKey={credentialKey}
              nameByType={nameByType}
              triggerUrl={triggerUrl}
              value={value}
              control={control}
              setValue={setValue}
            />

            <WorkflowTriggerForm
              bodyExample={bodyExampleHook}
              credentialKey={credentialKey}
              nameByType={nameByType}
              triggerUrl={triggerUrlHook}
              value={value}
              control={control}
            />
          </TabSelectionApiHook>
        </Stack>
      </ApiSettingsContainer>
    </Sheet>
  );
}
