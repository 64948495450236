import { PadBox } from '@bedrock-layout/padbox';
import { Inline } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import { memo } from 'react';
import { GoHourglass } from 'react-icons/go';
import { Handle, NodeProps, Position } from 'reactflow';
import { Typography, useLayer } from 'ui';

import { useSendEventToGTM } from '../../../../../../hooks/useSendEventToGTM';
import { workflowErrorByNodeAtom } from '../../../../atoms/atoms';
import { DelayNodeSheet } from '../../../Sheets/DelayNodeSheet/DelayNodeSheet';
import { DelayAction } from '../DelayAction/DelayAction';
import {
  DelayActionsContainer,
  DelayContainer,
  DelayInfoContainer,
  IconContainer,
} from './NectedDelayNode.styled';

export const NectedDelayNode = memo(
  ({ data, isConnectable, id, type }: NodeProps) => {
    const { openWithProps } = useLayer(<DelayNodeSheet />);

    const [workflowErrorByNode] = useAtom(workflowErrorByNodeAtom);

    const { sendEventToGTM } = useSendEventToGTM();

    return (
      <>
        <Handle
          type="source"
          position={Position.Bottom}
          style={{
            background: 'var(--color-dodgerBlue)',
            height: 8,
            width: 8,
            border: '1px solid var(--color-dodgerBlue)',
          }}
          isConnectable={isConnectable}
        />
        <Handle
          type="target"
          position={Position.Top}
          style={{
            background: 'var(--color-dodgerBlue)',
            height: 8,
            width: 8,
            border: '1px solid var(--color-dodgerBlue)',
          }}
          isConnectable={isConnectable}
        />
        <DelayContainer
          padding="0.5rem"
          $isError={!(workflowErrorByNode[id] == null)}
        >
          <IconContainer
            onClick={() => {
              sendEventToGTM({
                event: 'workflow',
                source: 'listing',
                element: type,
                action: 'node_click',
                type: data.nodeType,
              });

              openWithProps({
                id,
                data,
              });
            }}
          >
            <GoHourglass size={32} />
          </IconContainer>

          <PadBox
            padding={{
              top: '6px',
              bottom: '6px',
            }}
          >
            <DelayInfoContainer>
              <Typography name="paragraphXs" fontWeight={700}>
                {data.name ?? 'Step 1'}
              </Typography>

              <Typography name="paragraphXs">Action</Typography>

              {!(workflowErrorByNode[id] == null) && (
                <Typography name="errorXs">
                  {workflowErrorByNode[id]?.message}
                </Typography>
              )}
            </DelayInfoContainer>

            <DelayActionsContainer>
              <Inline
                align="center"
                style={{
                  background: 'var(--color-lightGray7)',
                  width: '4rem',
                }}
              >
                <DelayAction data={data} id={id} />
              </Inline>
            </DelayActionsContainer>
          </PadBox>
        </DelayContainer>
      </>
    );
  }
);

NectedDelayNode.displayName = 'NectedDelayNode';
