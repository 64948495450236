import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import styled from 'styled-components';
import { Typography } from 'ui';

export const IntegrationSheetContainer = styled(Stack)`
  height: 100vh;
  overflow: auto;
  position: relative;
`;

export const TabsContainer = styled(Stack)`
  flex-grow: 1;
`;

export const Form = styled.form`
  flex-grow: 1;
`;

export const IntegrationName = styled(Typography)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  height: 4rem;
`;

export const IntegrationUsageContainer = styled(Inline)`
  cursor: pointer;
  border-radius: 0.2rem;
  padding: 1rem;
  background-color: var(--color-aliceBlue);
`;
