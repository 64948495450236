import { Inline } from '@bedrock-layout/primitives';
import _isNil from 'lodash/isNil';
import styled from 'styled-components';

type ProfileIconContainerProps = {
  cursor?: string;
};

export const ProfileIconContainer = styled(Inline)<ProfileIconContainerProps>`
  background-color: var(--color-navyBlue);
  height: 3rem;
  min-width: 3rem;
  color: var(--color-white);
  border-radius: 50%;
  cursor: ${({ cursor }) => (_isNil(cursor) ? 'pointer' : cursor)};
`;
