import { Inline } from '@bedrock-layout/inline';
import { Stack } from '@bedrock-layout/stack';
import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import { BiCopy } from 'react-icons/bi';
import { IconButton, TextButton, Typography, useLayer } from 'ui';

import { PermissionWrapper } from '../../../../components/PermissionComponent';
import { permissionObj } from '../../../../components/PermissionComponent/constant';
import { copyToClipboard } from '../../../../utils/common';
import { ENTITY_ID } from '../../../../utils/constant';
import { currentWorkspaceDetailAtom } from '../../../Workspace/atom';
import { useUpdateCurrentWorkspace } from '../../../Workspace/hooks/restApi/useUpdateCurrentWorkspace';
import { RegenerateKeyModal } from '../../RegenrateKeyModal';
import {
  AuthKeyContainer,
  CopyIconContainer,
  InlineStyled,
  KeyValueStyled,
} from './AuthenticationKey.styled';

export function AuthenticationKey() {
  const [apiKey, setAPIKey] = useState<string>('');
  const { workspaceData, updateCurrentWorkspace } = useUpdateCurrentWorkspace();
  const [currentWorkspace, setCurrWorkspace] = useAtom(
    currentWorkspaceDetailAtom
  );
  const entityList = [ENTITY_ID.credentials];
  const updateCredentials = async () => {
    updateCurrentWorkspace({
      params: {
        refreshApiKey: true,
      },
    });
  };

  const { open: onRegenrateClick } = useLayer(
    <RegenerateKeyModal onRegenrateClick={updateCredentials} />
  );

  useEffect(() => {
    setAPIKey(currentWorkspace?.apiKey ?? '');
  }, []);

  useEffect(() => {
    setAPIKey(currentWorkspace?.apiKey ?? '');
  }, [currentWorkspace]);

  useEffect(() => {
    const updatedCurrentWorkspaceData = currentWorkspace;

    if (!_isNil(workspaceData) && !_isNil(updatedCurrentWorkspaceData)) {
      setAPIKey(workspaceData?.data.data.apiKey);
      updatedCurrentWorkspaceData.apiKey = workspaceData?.data.data.apiKey;
      setCurrWorkspace(updatedCurrentWorkspaceData);
    }
  }, [workspaceData]);

  return (
    <Stack gutter="3rem">
      <Stack gutter="0.8rem">
        <Typography name="heading2">API Keys</Typography>

        <Typography>
          API keys are used for identification and authorization to systems and
          services you might wish to access
        </Typography>
      </Stack>

      <Stack gutter="1.5rem">
        <Inline align="center">
          <AuthKeyContainer align="top">
            <InlineStyled align="center">
              <KeyValueStyled>nected-api-key</KeyValueStyled>
            </InlineStyled>
            <InlineStyled align="center">
              <KeyValueStyled>{apiKey}</KeyValueStyled>
              <Inline
                as={CopyIconContainer}
                align="center"
                padding={['0.7rem', '0rem', '0.7rem', '1.2rem']}
              >
                <IconButton
                  onClick={async () =>
                    await copyToClipboard(apiKey, 'Key copied')
                  }
                >
                  <BiCopy />
                </IconButton>
              </Inline>
            </InlineStyled>
          </AuthKeyContainer>

          <PermissionWrapper
            allowedPermission={[permissionObj.create, permissionObj.edit]}
            entityList={entityList}
          >
            <TextButton onClick={onRegenrateClick}>Regenerate</TextButton>
          </PermissionWrapper>
        </Inline>
      </Stack>
    </Stack>
  );
}
