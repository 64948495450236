import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import { toasts } from 'ui';

import { TESTABLE_NODES } from '../../../utils/constant';
import {
  changedNodeIdsAtom,
  workflowEdgesAtom,
  workflowIdAtom,
  workflowNodesAtom,
} from '../atoms/atoms';
import {
  getAllPredecessorsSkippingType,
  getExecutedValueAndStatus,
  getExecutedValueByName,
} from '../utils/common';
import { useTestWorkflow } from './restApi/useTestWorkflow';
import { useGenerateDataset } from './useGenerateDataset';
import { useGetDataset } from './useGetDataset';
import { WorkflowNodeType } from './useOpenWorkflow';

type UseTestSwitchNodeProps = {
  localData: any;
  id: string;
};

export function useTestSwitchNode({ localData, id }: UseTestSwitchNodeProps) {
  const [workflowId] = useAtom(workflowIdAtom);
  const [workflowNodes] = useAtom(workflowNodesAtom);
  const [workflowEdges] = useAtom(workflowEdgesAtom);
  const [, setChangedNodeIds] = useAtom(changedNodeIdsAtom);

  const [parentNodes, setParentNodes] = useState<WorkflowNodeType[]>([]);

  // eslint-disable-next-line
  const {
    testWorkflow,
    data: switchData,
    error: switchError,
  } = useTestWorkflow();

  const [isTesting, setIsTesting] = useState(false);
  const [currentStatus, setCurrentStatus] = useState<string>(
    localData.status ?? ''
  );

  const workflowNode = workflowNodes.find((wn) => wn.id === id);

  const { updatedDataSet } = useGetDataset({
    parentNodes,
  });

  const { tokens } = useGenerateDataset({
    id,
    updatedDataset: updatedDataSet,
  });

  useEffect(() => {
    if (!_isNil(switchData)) {
      if (!_isNil(workflowNode)) {
        const newWorkflowNode = workflowNode;
        const exec = getExecutedValueAndStatus(switchData);

        newWorkflowNode.data.status = exec.status;
        newWorkflowNode.data.executedValue = exec.executedValue;

        setChangedNodeIds([]);

        setCurrentStatus(exec.status);
        setTimeout(() => {
          localData.onWorkflowNodeChange(newWorkflowNode);
        }, 100);
      }
      setIsTesting(false);
    }
  }, [switchData]);

  useEffect(() => {
    if (!_isNil(switchError)) {
      setIsTesting(false);
    }
  }, [switchError]);

  const testData = async (defaultPath: boolean, onSuccess?: () => void) => {
    if (
      parentNodes.find(
        (node) =>
          TESTABLE_NODES.includes(node.type ?? '') &&
          node.data.status !== 'success'
      ) != null
    ) {
      setIsTesting(false);

      return toasts.error(
        'Previous node(s) are  not tested. Please test the previous node(s) before proceeding',
        'parent-node'
      );
    }

    try {
      testWorkflow({
        id: workflowId ?? '',
        nodeId: id,
        params: {
          defaultPath: defaultPath ?? localData.input?.value,
        },
        additionalParams: {
          executedValue: getExecutedValueByName(updatedDataSet),
        },
      });

      if (typeof onSuccess === 'function') {
        onSuccess();
      }
    } catch (error) {}
  };

  useEffect(() => {
    const directParents = getAllPredecessorsSkippingType(
      id,
      workflowNodes,
      workflowEdges,
      'addNode'
    );

    setParentNodes(directParents);
  }, [JSON.stringify(workflowNodes)]);

  return {
    testData,
    switchData,
    switchError,
    updatedDataSet,
    tokens,
    parentNodes,
    currentStatus,
    isTesting,
    setIsTesting,
    setCurrentStatus,
  };
}
