import { PadBox } from '@bedrock-layout/primitives';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { FaPlay } from 'react-icons/fa6';
import { IoIosDocument } from 'react-icons/io';
import { useParams } from 'react-router-dom';
import { Typography, useLayer } from 'ui';

import { SITE_META } from '../../utils/common';
import { envMap } from '../../utils/constant';
import {
  VideoBlock,
  VideoBlockContainer,
  VideoButtonContainer,
  VideoButtons,
  VideoGuideContainer,
} from './guides.syled';
import { VideoPopup } from './videoPopup';

export type VideoDataProps = {
  name: string;
  videoUrl: string;
  time: string;
  documentUrl: string;
  thumbnail: string;
};

type GuidesCategory = {
  category: string;
  items: VideoDataProps[];
};

export const VideoGuide = () => {
  const { entity } = useParams();
  const [videosData, setVideosData] = useState<GuidesCategory[]>([]);
  const { openWithProps: OpenVideoPopup } = useLayer(<VideoPopup />);

  useEffect(() => {
    fetch(`${envMap.VITE_ASSETS_URL}home/learn_videos.json`)
      .then(async (response) => await response.json())
      .then((data) => {
        if (!_isNil(entity)) {
          setVideosData(
            data.filter(
              (i: GuidesCategory) =>
                i.category.toLowerCase() === entity.toLowerCase()
            )
          );
        } else {
          setVideosData(data);
        }
      })
      .catch(() => setVideosData([]));
  }, []);

  return (
    <VideoGuideContainer>
      <Helmet>
        <title>
          {SITE_META[window.location.pathname.substring(1)]?.title ?? ''}
        </title>
        <meta
          name="description"
          content={
            SITE_META[window.location.pathname.substring(1)]?.description ?? ''
          }
        />
      </Helmet>
      {videosData.map((category: GuidesCategory, categoryIndex: number) => {
        return (
          <PadBox key={categoryIndex}>
            <Typography name="heading2" fontWeight={700}>
              {category.category}
            </Typography>
            <VideoBlockContainer>
              {category.items.map(
                (video: VideoDataProps, videoIndex: number) => {
                  return (
                    <VideoBlock key={videoIndex}>
                      <Typography>{video.name}</Typography>
                      <VideoButtonContainer gutter="1.6rem">
                        {!_isEmpty(video.videoUrl) && (
                          <VideoButtons
                            onClick={() => OpenVideoPopup({ videoObj: video })}
                          >
                            <FaPlay /> <Typography>Watch</Typography>
                          </VideoButtons>
                        )}
                        {!_isEmpty(video.documentUrl) && (
                          <VideoButtons>
                            <IoIosDocument />
                            <Typography>Docs</Typography>
                          </VideoButtons>
                        )}
                        <Typography name="secondarySmall">
                          {video.time}
                        </Typography>
                      </VideoButtonContainer>
                    </VideoBlock>
                  );
                }
              )}
            </VideoBlockContainer>
          </PadBox>
        );
      })}
    </VideoGuideContainer>
  );
};
