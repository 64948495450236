import styled from 'styled-components';
import { Typography } from 'ui';

export const ReviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0.8rem;
  border-radius: 4px;
  inline-size: 30rem;
  max-inline-size: 300px;
  border: 1px solid var(--color-lightGray);
  background: var(--color-white);
`;

export const EntityText = styled(Typography)`
  color: var(--color-dodgerBlue);
`;
