import styled, { css } from 'styled-components';
import theme from 'styled-theming';

export const pendingStyle = {
  color: 'var(--color-tangerine)',
  background: 'var(--color-earlyDawn)',
};

export const testedStyle = {
  background: 'var(--color-aliceBlue)',
  color: 'var(--color-dodgerBlue)',
};

const disabledCssRules = css`
  cursor: not-allowed;
  color: var(--color-darkGray);
  background-color: var(--color-whiteSmoke);
  border-color: var(--color-whiteSmoke);
`;

const buttonAppearance = theme('appearance', {
  new: css`
    color: var(--color-white);
    background: var(--color-darkBlue);

    &.btn-disabled-interceptor {
      cursor: progress;
    }

    &:not(.btn-disabled-interceptor):disabled {
      ${disabledCssRules};
    }
  `,
  connected: css`
    color: var(--color-green);
    background: var(--color-honeydew);

    &.btn-disabled-interceptor {
      cursor: progress;
    }

    &:not(.btn-disabled-interceptor):disabled {
      ${disabledCssRules};
    }
  `,
  pending: css`
    ${pendingStyle}

    &:not(.btn-disabled-interceptor):disabled {
      ${disabledCssRules};
    }
  `,
  tested: css`
    ${testedStyle}

    &.btn-disabled-interceptor {
      cursor: progress;
    }

    &:not(.btn-disabled-interceptor):disabled {
      ${disabledCssRules};
    }
  `,
});

export const ButtonStyled = styled.button`
  border: none;
  width: 100%;
  border-radius: 6px;
  padding: 0.8rem;
  cursor: pointer;
  font-weight: 700;
  ${buttonAppearance}
`;

export const StatusButtonIcon = styled.div`
  font-size: 1.8rem;
`;

export const StatusText = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
`;
